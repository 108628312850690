import { cn } from '@opoint/infomedia-storybook'
import { HTMLAttributes } from 'react'

export type TabType = 'results' | 'added' | 'removed'

const titles = {
  results: 'Results',
  added: 'Added',
  removed: 'Removed',
}

const Wrapper = (props: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-row py-3 border-b border-grey.6 text-[15px]',
      props.className,
    )}
    {...props}
  />
)

type ItemProps = {
  activeType: TabType
  type: TabType
  count: number
  onActiveTypeChange: (type: TabType) => void
  hasUnderline?: boolean
}

const Item = ({
  activeType,
  type,
  count,
  onActiveTypeChange,
  hasUnderline = true,
}: ItemProps) => {
  const title = titles[type]

  const handleClick = () => {
    onActiveTypeChange(type)
  }

  return (
    <div
      className={cn('ms-2 cursor-pointer', {
        'border-b-2 border-sky.1': hasUnderline && activeType === type,
      })}
      onClick={handleClick}
    >
      {title} ({count})
    </div>
  )
}

export { Item, Wrapper }

import { useOpointAuthState } from '@opoint/authjs-react'
import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Sheet,
  SheetContent,
  SheetTrigger,
} from '@opoint/infomedia-storybook'
import { Settings24Regular } from '@fluentui/react-icons'
import { LocalNameOfQueryType, QueryType } from '../../types/profile'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { DEFAULT_QUERY_LANGUAGE } from '../../constants/localStorage'

const Navigation = () => {
  const authState = useOpointAuthState()

  const [queryType, setQueryType] = useLocalStorage<QueryType>(
    DEFAULT_QUERY_LANGUAGE,
    QueryType.OQL,
  )

  const availableOptions = [
    { value: QueryType.IQL, label: LocalNameOfQueryType[QueryType.IQL] },
    { value: QueryType.OQL, label: LocalNameOfQueryType[QueryType.OQL] },
  ]

  return (
    <div className="flex h-[60px] w-full items-center justify-between border-b border-grey.6 bg-grey.8 pl-2 pr-6">
      <h1 className="text-sky.1">Query Builder</h1>
      <div className="flex items-center gap-1">
        <Sheet>
          <SheetTrigger asChild>
            <Button variant="icon">
              <span className="sr-only">Settings</span>
              <Settings24Regular />
            </Button>
          </SheetTrigger>

          <SheetContent className="sm:max-w-xl">
            <h2 className="mb-6 text-2xl">Settings</h2>

            <div className="flex flex-col space-y-2">
              <label>Default query language:</label>

              <Select
                value={queryType}
                onChange={(value) => {
                  setQueryType(value as QueryType)
                }}
              >
                <SelectTrigger
                  displayValue={(value) =>
                    availableOptions.find((option) => option.value === value)
                      ?.label ?? ''
                  }
                />

                <SelectContent>
                  {availableOptions.map(({ value, label }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </SheetContent>
        </Sheet>

        <span className="text-sm">
          {authState?.user.impersonator?.username || authState?.user.username}
        </span>
      </div>
    </div>
  )
}

export default Navigation

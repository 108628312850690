import { useOpointAuthState } from '@opoint/authjs-react'
import { Search20Filled } from '@fluentui/react-icons'

import { useCallback, useEffect, useState } from 'react'

import debounce from 'lodash-es/debounce'
import {
  Input,
  InputGroup,
  InputRightElement,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@opoint/infomedia-storybook'
import useActiveUserIdParam from '../../../hooks/useActiveUserIdParam'
import useGetUsers from '../../../hooks/useGetUsers'
import useImpersonate from '../../../hooks/useImpersonate'
import { useSearchTermContext } from '../../../context/searchTermContext'
import useGetReports from '../../../hooks/useGetReports'
import useFilterByKey from '../../../hooks/useFilterByKey'
import ProfilesList from './ProfilesList'
import UsersList from './UsersList'
import Reports from './Reports'
import { ProfileCount } from './ProfileCount'

enum TabsEnum {
  profiles = 'profiles',
  reports = 'reports',
}

const shouldDisplayReportsTab = false

const MainPage = () => {
  const authState = useOpointAuthState()

  const [userId, setUserId] = useActiveUserIdParam()

  const { user } = authState ?? {}

  const { handleImpersonate, isLoading: isLoadingImpersonation } =
    useImpersonate()

  const { searchTerm, setSearchTerm } = useSearchTermContext()
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm)

  const [hasCheckedUserId, setHasCheckedUserId] = useState(false)

  const [activeTab, setActiveTab] = useState<TabsEnum>(TabsEnum.profiles)

  const { data: reports = [], isLoading: isReportsLoading } = useGetReports({
    userId: userId || user?.user_id || 0,
  })

  const handleActiveUserIdChange = useCallback(
    (id: number | undefined) => {
      handleImpersonate({
        userId: id,
        onSuccess: () => {
          setUserId(id ? id : null)
        },
      })
    },
    [handleImpersonate, setUserId],
  )

  const { data: users } = useGetUsers({
    userSearchTerm: debouncedSearchTerm,
  })

  const [searchTermProfilesReports, setSearchTermProfilesReports] = useState('')

  const filteredReports = useFilterByKey({
    array: reports,
    searchTerm: searchTermProfilesReports,
    key: 'name',
  })

  // Check if the user_id is valid
  useEffect(() => {
    if (!userId || !users || hasCheckedUserId) {
      if (userId === 0) {
        setHasCheckedUserId(true)
      }
      return
    }

    const user = users.find(({ id_user }) => id_user === userId)

    handleActiveUserIdChange(user?.id_user)
    setHasCheckedUserId(true)
  }, [users, userId, handleActiveUserIdChange, hasCheckedUserId])

  useEffect(() => {
    const debouncedUpdate = debounce((searchTerm: string) => {
      setDebouncedSearchTerm(searchTerm)
    }, 250)

    debouncedUpdate(searchTerm)

    return () => {
      debouncedUpdate.cancel()
    }
  }, [searchTerm])

  return (
    <Tabs
      value={activeTab}
      onValueChange={(value) => {
        setActiveTab(value as TabsEnum)
      }}
      className="flex flex-col"
    >
      <div className="flex flex-col md:flex-row">
        <div className="w-full basis-1/3 border-b border-r border-grey.6 p-2">
          <InputGroup className="flex-1">
            <Input
              onChange={(event) => {
                setSearchTerm(event.target.value)
              }}
              placeholder="Search users"
              value={searchTerm}
            />
            <InputRightElement>
              <Search20Filled />
            </InputRightElement>
          </InputGroup>
        </div>

        <div className="flex flex-auto justify-between border-b border-grey.6 px-6 py-2">
          <TabsList className="flex-1">
            <TabsTrigger
              className="border p-2 data-[state=active]:border-b data-[state=active]:border-grey.6 data-[state=inactive]:border-transparent data-[state=active]:bg-grey.8"
              value={TabsEnum.profiles}
            >
              Profiles{' '}
              <ProfileCount
                searchTerm={searchTermProfilesReports}
                userId={userId || user?.user_id || 0}
              />
            </TabsTrigger>
            {shouldDisplayReportsTab && (
              <TabsTrigger
                className="border p-2 data-[state=active]:border-b data-[state=active]:border-grey.6 data-[state=inactive]:border-transparent data-[state=active]:bg-grey.8"
                value={TabsEnum.reports}
                disabled={isReportsLoading || reports.length === 0}
              >
                Reports ({filteredReports.length})
              </TabsTrigger>
            )}
          </TabsList>
          <InputGroup className="flex-1">
            <Input
              onChange={(event) => {
                setSearchTermProfilesReports(event.target.value)
              }}
              placeholder="Search"
              value={searchTermProfilesReports}
            />
            <InputRightElement>
              <Search20Filled />
            </InputRightElement>
          </InputGroup>
        </div>
      </div>
      <div className="flex w-full flex-wrap">
        <div className="w-full md:w-1/3">
          <div className="h-auto overflow-y-auto md:h-[calc(100vh-116px)]">
            <UsersList
              activeUserId={userId}
              onActiveUserIdChange={handleActiveUserIdChange}
              userSearchTerm={searchTerm}
              users={users}
            />
          </div>
        </div>
        <div className="h-auto flex-auto overflow-y-auto md:h-[calc(100vh-116px)]">
          <TabsContent value={TabsEnum.profiles}>
            {hasCheckedUserId && !isLoadingImpersonation && (
              <ProfilesList
                isLoadingImpersonation={isLoadingImpersonation}
                searchTerm={searchTermProfilesReports}
                userId={userId || user?.user_id || 0}
              />
            )}
          </TabsContent>
          <TabsContent value={TabsEnum.reports}>
            <Reports reports={filteredReports} />
          </TabsContent>
        </div>
      </div>
    </Tabs>
  )
}

export default MainPage

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useOpointAuthState } from '@opoint/authjs-react'

import { useCustomAxiosInstance } from '../axios/instance'
import { getGetSettingsQueryKey, Setting } from './useGetSettings'

const useUpdateSettings = () => {
  const apiClient = useCustomAxiosInstance()
  const queryClient = useQueryClient()
  const authState = useOpointAuthState()

  const userId =
    authState?.user.impersonator?.user_id ?? authState?.user?.user_id

  if (!userId) {
    throw new Error('User ID is required')
  }

  const getSettingsQueryKey = getGetSettingsQueryKey(userId)

  return useMutation(
    (settings: Setting[]) => {
      return apiClient({
        url: `/settings/`,
        method: 'POST',
        data: settings,
      })
    },
    {
      onSuccess: () => queryClient.invalidateQueries(getSettingsQueryKey),
    },
  )
}

export default useUpdateSettings

import {
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@opoint/infomedia-storybook'
import CodeMirror, {
  EditorView,
  ReactCodeMirrorProps,
  ReactCodeMirrorRef,
  ViewUpdate,
} from '@uiw/react-codemirror'

import { isEqual } from 'lodash-es'
import { ReactElement, useMemo, useRef, useState } from 'react'

import { MoreHorizontal24Regular } from '@fluentui/react-icons'
import {
  addFilterSource,
  autocompleteExtension,
  bracketMatcherExtension,
  coreExtensions,
  filterGroupingExtension,
  filterInlineExtension,
  filterMappingsExtension,
  hoverTooltipExtension,
  infomediaThemeExtension,
  languages,
  lintingExtension,
  utils,
} from '@opoint/codemirror-extensions'
import { QueryType } from '../../types/profile'
import { ProfileItem } from '../../generated-types/types.schemas'

import { useEditorContext } from '../../context/editorContext'
import useCustomFilters from '../../hooks/useCustomFilters'
import styles from './index.module.scss'
import { getSuggestionIds, getSuggestionsSingleMode } from './services'

interface Props extends Omit<ReactCodeMirrorProps, 'onChange'> {
  queryType: QueryType
  menuItems?: {
    icon: ReactElement
    title: string
    onClick: (args: unknown) => void
  }[]
  filters?: ProfileItem['searchline']['filters'] | undefined
  onChange?: (v: ProfileItem['searchline']) => void
  fieldId: string | number
  isFormatted?: boolean
}

const CodeEditor = ({ menuItems, ...props }: Props) => {
  const { queryType, onChange, fieldId, filters, isFormatted, ...editorProps } =
    props

  const {
    hasLintError,
    suggestServerFilterSource,
    getGroupFilters,
    setGroupFilters,
  } = utils

  const { updateSyntaxState } = useEditorContext()

  const codeEditorRef = useRef<ReactCodeMirrorRef>({})
  const [savedFilters, setSavedFilters] = useState<
    ProfileItem['searchline']['filters'][0][]
  >(filters ?? [])

  const customFilterSource = useCustomFilters()

  const language = languages[queryType]

  function onEditorChange(value: string, update: ViewUpdate) {
    return onChange?.({
      searchterm: value,
      filters: filters ?? getGroupFilters(update.state),
    })
  }

  function onEditorUpdate(update: ViewUpdate) {
    const f = getGroupFilters(update.state)
    const lintErrors = hasLintError(update.state)
    updateSyntaxState({
      isValid: !lintErrors,
      fieldId: fieldId,
    })
    // Compare with filters in internal state to determine changes occurring
    if (!isEqual(f, savedFilters)) {
      // Changes inside the editor
      setSavedFilters(f)
      return onChange?.({
        searchterm: update.state.doc.toString(),
        filters: f,
      })
    } else if (!isEqual(filters, savedFilters)) {
      // Changes outside the editor
      setSavedFilters(filters ?? [])
      setGroupFilters(update.view, f ?? [])
    }
  }

  const haveMenuItems = menuItems && menuItems.length > 0

  const extensions = useMemo(
    () => [
      language,
      addFilterSource.of(customFilterSource),
      addFilterSource.of(
        suggestServerFilterSource(getSuggestionsSingleMode, getSuggestionIds),
      ),
      coreExtensions(),
      filterGroupingExtension(filters ?? []),
      filterInlineExtension(),
      filterMappingsExtension(),
      autocompleteExtension(),
      lintingExtension(),
      infomediaThemeExtension(),
      bracketMatcherExtension(),
      hoverTooltipExtension(),
      EditorView.editorAttributes.of({
        class: haveMenuItems ? 'pe-10' : '',
      }),
    ],
    [
      customFilterSource,
      filters,
      haveMenuItems,
      language,
      suggestServerFilterSource,
    ],
  )

  // Key prop forces complete rerender on language change
  //  this ensures extensions are reloaded properly
  return (
    <div className="relative">
      <div
        className={cn(styles.editorWrapper, {
          [styles.formatted]: isFormatted,
        })}
      >
        <CodeMirror
          basicSetup={false}
          extensions={extensions}
          key={queryType}
          onChange={onEditorChange}
          onUpdate={onEditorUpdate}
          ref={codeEditorRef}
          {...editorProps}
        />
      </div>
      {haveMenuItems && (
        <div
          className={cn(
            'absolute flex justify-center h-full top-0',
            styles.optionsWrapper,
          )}
        >
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
              <Button
                className={cn(
                  'border-0 bg-transparent p-0',
                  { 'h-11': !isFormatted },
                  { 'h-9': isFormatted },
                  styles.optionsButton,
                )}
                type="button"
                variant="outline"
              >
                <MoreHorizontal24Regular />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              {menuItems?.map(({ onClick, icon, title }, index) => (
                <DropdownMenuItem key={index} onClick={onClick}>
                  {icon}
                  {title}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}
    </div>
  )
}

export default CodeEditor

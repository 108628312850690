export const filterTypes = [
  'profile',
  'tag',
  'basket',
  'list',
  'geo',
  'lang',
  'media',
  'content',
  'site',
  'topic',
  'rank',
  'ent',
  'list90',
  'profile91',
] as const

export type FilterType = (typeof filterTypes)[number]
import {
  LinemodeEnum,
  SearchRequestBodyExpressionsSearchlineObjectExpressionsItem,
} from '../generated-types/types.schemas'

export type Filter = {
  id?: string | number
  type: FilterType | 'searchterm' // searchterm is a special case
  name?: string
}

export type SearchLine = {
  filters: Filter[]
  searchterm?: string
  searchmode?: 'IQL' | ''
}

export type SearchItem = {
  id?: number
  searchline: SearchLine
  linemode: LinemodeEnum
}

export type Profile = {
  blessed?: boolean
  category: number
  filtersUsed?: string
  folder: number
  hasTrash: boolean
  id: number
  name: string
  owner: number
  parent: number
  score: number
  statistics: string
  type: number | string
  url: string
  revisions?: number
  last_set?: string
  items?: SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[]
}

export type NewProfile = {
  name: string
  items?: SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[]
  parent?: number
}

export type ProfileWithChildren = Profile & {
  childrenProfiles?: ProfileWithChildren[]
  depth: number
  isFolder?: boolean
}

export type ProfileList = Pick<
  Profile,
  'id' | 'name' | 'parent' | 'category' | 'owner'
> & { isFolder?: boolean }

export type ProfileListWithChildren = ProfileList & {
  childrenProfiles?: ProfileListWithChildren[]
  depth: number
  isFolder?: boolean
}

export type ProfileHistoryResult = {
  id_list: number
  name: string
  items: Array<
    SearchRequestBodyExpressionsSearchlineObjectExpressionsItem & {
      searchline: { parsedSearchTerm?: string }
    }
  >
  timestamp: number
}

export type ProfileHistory = {
  count: number
  results: ProfileHistoryResult[]
}

export type QueryType = (typeof QueryType)[keyof typeof QueryType]

export const QueryType = {
  IQL: 'IQL',
  OQL: 'OQL',
} as const

export const LocalNameOfQueryType = {
  IQL: 'Expert',
  OQL: 'Basic',
} as const

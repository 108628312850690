import { ReactNode } from 'react'

import { useFormContext } from 'react-hook-form'
import {
  FormField,
  FormItem,
  FormControl,
  Switch,
  FormMessage,
} from '@opoint/infomedia-storybook'
import RevisionsModal from '../../../components/RevisionsModal'
import { FormState } from '../../../components/ProfilePage/types'
import { formatDate } from './utilities'

const ProfileInformationItem = ({
  label,
  value,
}: {
  label: string
  value?: ReactNode | string | number
}) => {
  return (
    <tr>
      <td className="pr-3 text-[15px] font-medium">{label}</td>
      <td>{value || ''}</td>
    </tr>
  )
}

const ProfileInformation = ({
  lastUpdatedAt: lastUpdatedAtProp,
  onResetFormValue,
  revisions,
  compareOptionsModal,
  status,
  profileHistoryModal,
}: {
  lastUpdatedAt?: string | null
  onResetFormValue: (newValue: FormState['queries']) => void
  revisions?: number
  compareOptionsModal?: ReactNode
  status?: string
  profileHistoryModal: ReactNode
}) => {
  const { control, watch, setValue } = useFormContext<FormState>()

  const persistentProfile = watch('persistent_profile')

  const lastUpdatedAt = formatDate(lastUpdatedAtProp)

  return (
    <div className="rounded-sm border border-grey.6 p-4 text-left text-[15px]">
      <table>
        <tbody>
          {status && <ProfileInformationItem label="Status" value={status} />}

          {!!profileHistoryModal && (
            <ProfileInformationItem
              label="Profile history"
              value={profileHistoryModal}
            />
          )}

          {!!persistentProfile && (
            <ProfileInformationItem
              label="Grouping"
              value={
                <FormField
                  control={control}
                  name={'persistent_grouping'}
                  render={({ field }) => {
                    const isChecked = field.value

                    return (
                      <FormItem className="flex items-center gap-2">
                        <FormControl>
                          <Switch
                            checked={isChecked}
                            onCheckedChange={(checked) => {
                              field.onChange(checked)
                              setValue('make_persistent', true)
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
              }
            />
          )}

          {!!compareOptionsModal && (
            <ProfileInformationItem
              label="Comparing with"
              value={compareOptionsModal}
            />
          )}

          {!!lastUpdatedAt && (
            <ProfileInformationItem
              label="Latest update"
              value={lastUpdatedAt}
            />
          )}

          {!!revisions && (
            <ProfileInformationItem
              label="Revisions"
              value={
                <RevisionsModal
                  onResetFormValue={onResetFormValue}
                  revisions={revisions}
                />
              }
            />
          )}
        </tbody>
      </table>
    </div>
  )
}

export default ProfileInformation

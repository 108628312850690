import { useOpointAuthState } from '@opoint/authjs-react'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useCustomAxiosInstance } from '../axios/instance'

type Basket = {
  name: string
  id: number
}

type Alert = {
  id: number
  url: string
  subject: string
  type: number
  active: number
  baskets: Basket[]
  isCreatedFromApp: boolean
  editable: number
  max_age: number
  class_field: number
  has_push_subscription: boolean
}

export const getGetAlertsQueryKey = (userId: number) => [`${userId}-alerts`]

const useGetAlerts = (options?: UseQueryOptions<Alert[]>) => {
  const apiClient = useCustomAxiosInstance<Alert[]>()
  const authState = useOpointAuthState()

  const userId =
    authState?.user.impersonator?.user_id ?? authState?.user?.user_id

  if (!userId) {
    throw new Error('User ID is required')
  }

  const queryKey = getGetAlertsQueryKey(userId)

  return useQuery<Alert[]>({
    queryKey,
    queryFn: () => apiClient({ url: '/alerts/', method: 'get' }),
    refetchOnWindowFocus: false,
    ...options,
  })
}

export default useGetAlerts

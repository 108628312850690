import {
  Button,
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  RadioInput,
  AlertDialogCancel,
  AlertDialogAction,
  Form,
  FormField,
} from '@opoint/infomedia-storybook'
import { format, fromUnixTime, getUnixTime } from 'date-fns'
import { useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import { Calendar24Regular } from '@fluentui/react-icons'
import Calendar from '../../Calendar'
import { FormState } from '../types'

import useUnpersistProfile from '../../../hooks/useUnpersistProfile'
import useProfileId from '../../../hooks/useProfileId'
import UnpersistConfirmationModal from './UnpersistConfirmationModal'
import RerunQueryModal from './RerunQueryModal'

type DialogFormState = {
  date: Date | undefined
  isPersistent: PersistentOptions
}

type Props = {
  label: string
  submit: () => void
}

enum PersistentOptions {
  Yes = 'Yes',
  No = 'No',
}

const PersistProfileHistoryModal = ({ label, submit }: Props) => {
  const [isShown, setIsShown] = useState(false)
  const [showUnpersistConfirmationModal, setShowUnpersistConfirmationModal] =
    useState<boolean>(false)
  const [showRerunQueryModal, setShowRerunQueryModal] = useState<boolean>(false)

  const profileId = useProfileId()

  const profileForm = useFormContext<FormState>()

  const persistentProfile = profileForm.watch('persistent_profile')
  const oldestArticle = profileForm.watch('oldest_article')

  const isPersistentDefault =
    persistentProfile !== undefined && persistentProfile !== 0

  const isPersistentDefaultOption = isPersistentDefault
    ? PersistentOptions.Yes
    : PersistentOptions.No

  const dateDefaultOption =
    isPersistentDefault && oldestArticle
      ? fromUnixTime(oldestArticle)
      : new Date()

  const defaultValues = {
    date: dateDefaultOption,
    isPersistent: isPersistentDefaultOption,
  }

  const dialogForm = useForm<DialogFormState>({
    defaultValues,
  })

  const hideModal = () => {
    setIsShown(false)

    dialogForm.reset(defaultValues)
  }

  const hideUnpersistConfirmationModal = () => {
    setShowUnpersistConfirmationModal(false)
  }

  const hideRerunQueryModal = () => {
    setShowRerunQueryModal(false)
  }

  const { mutate: handleUnpersist } = useUnpersistProfile(profileId, () => {
    profileForm.setValue('persistent_profile', 0)
    setIsShown(false)
  })

  const handlePersist = ({ date }: DialogFormState) => {
    profileForm.setValue('make_persistent', true)
    profileForm.setValue(
      'persistent_backfill_start',
      date ? getUnixTime(date) : undefined,
    )
    submit()
    setIsShown(false)
  }

  const handleOnDone = (data: DialogFormState) => {
    // About to unpersist
    if (isPersistentDefault && isPersistent === PersistentOptions.No) {
      setShowUnpersistConfirmationModal(true)
    } else if (isPersistent === PersistentOptions.Yes) {
      handlePersist(data)
    }
  }

  // Since we have nested forms, we need to stop the event from bubbling up
  // The forms are not nested in html, because of portal, but react bubbles the events up in its tree, regardless of portals
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation()
    return dialogForm.handleSubmit(handleOnDone)(event)
  }

  const isPersistent = dialogForm.watch('isPersistent')
  const date = dialogForm.watch('date')

  return (
    <AlertDialog open={isShown}>
      <Button
        className="p-0 text-left"
        type="button"
        variant="text"
        onClick={() => {
          setIsShown(true)
        }}
      >
        {label}
      </Button>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Restrict profile history</AlertDialogTitle>
        </AlertDialogHeader>
        <Form {...dialogForm}>
          <form
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={onSubmit}
            className="flex w-full flex-col"
          >
            <FormField
              control={dialogForm.control}
              name="isPersistent"
              render={({ field }) => {
                return (
                  <RadioGroup
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <RadioInput
                      id="1"
                      label={PersistentOptions.No}
                      value={PersistentOptions.No}
                    />
                    <RadioInput
                      id="2"
                      label={PersistentOptions.Yes}
                      value={PersistentOptions.Yes}
                    />
                  </RadioGroup>
                )
              }}
            />
            {isPersistent === PersistentOptions.Yes && (
              <>
                {isPersistentDefault && oldestArticle ? (
                  <span className="mt-4">
                    Restricted from{' '}
                    {format(fromUnixTime(oldestArticle), 'MMM d, yyyy')}
                  </span>
                ) : (
                  <FormField
                    control={dialogForm.control}
                    name="date"
                    render={({ field: { value, onChange } }) => (
                      <Popover modal={true}>
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            className="mt-4 justify-start"
                          >
                            <Calendar24Regular />
                            {value
                              ? format(value, 'MMM d, yyyy')
                              : 'Pick a date'}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="pointer-events-auto max-w-full">
                          <Calendar
                            mode="single"
                            onSelect={onChange}
                            selected={value}
                            toDate={new Date()}
                          />
                        </PopoverContent>
                      </Popover>
                    )}
                  />
                )}
                <div>
                  <Button
                    variant="link"
                    className="mt-2 ps-0 text-sky.4 underline disabled:border-0 disabled:bg-transparent disabled:text-sky.cloudy"
                    disabled={!isPersistentDefault}
                    onClick={() => {
                      setShowRerunQueryModal(true)
                    }}
                  >
                    Re-run query
                  </Button>
                </div>
              </>
            )}
            <AlertDialogFooter>
              <AlertDialogCancel type="button" onClick={hideModal}>
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                disabled={isPersistent === PersistentOptions.Yes && !date}
                type="submit"
              >
                Done
              </AlertDialogAction>
            </AlertDialogFooter>
          </form>
        </Form>
        <UnpersistConfirmationModal
          isShown={showUnpersistConfirmationModal}
          onConfirm={handleUnpersist}
          onHide={hideUnpersistConfirmationModal}
        />
        <RerunQueryModal
          isShown={showRerunQueryModal}
          onHide={hideRerunQueryModal}
          oldestArticleTimestamp={oldestArticle}
        />
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default PersistProfileHistoryModal

import { Button } from '@opoint/infomedia-storybook'

const profileErrorMessages = {
  notFound: 'Profile with provided id has not been found.',
  other: 'Something went wrong. Please try again.',
}

const ErrorMessage = ({ errorStatus }: { errorStatus: number | undefined }) => {
  if (!errorStatus) {
    return null
  }

  const profileErrorType = errorStatus === 404 ? 'notFound' : 'other'

  return (
    <div className="flex h-1/2 flex-col items-center justify-center text-center">
      <h2>{profileErrorMessages[profileErrorType]}</h2>
      {profileErrorType === 'other' && (
        <Button
          className="mt-4"
          onClick={() => {
            window.location.reload()
          }}
          type="button"
          variant="filled"
        >
          Reload page
        </Button>
      )}
    </div>
  )
}

export default ErrorMessage

import { ReactElement, useEffect } from 'react'
import { OpointAuthProvider, useOpointAuth } from '@opoint/authjs-react'
import { AxiosError } from 'axios'
import { AXIOS_INSTANCE } from '../../axios/instance'
import { getAppUrl, getAuthUrl } from '../../configuration/auth'

type AuthWrapperProps = {
  children: ReactElement
}

export const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const authUrl = getAuthUrl()
  const appUrl = getAppUrl()
  const auth = useOpointAuth()

  useEffect(() => {
    // Add an Axios interceptor to handle expired tokens
    const expiredTokenInterceptor = AXIOS_INSTANCE.interceptors.response.use(
      (response) => {
        return response
      },
      async (error: AxiosError) => {
        if (error.response && error.response.status === 401) {
          await auth?.logout()
        }

        return Promise.reject(error)
      },
    )

    return () => {
      AXIOS_INSTANCE.interceptors.response.eject(expiredTokenInterceptor)
    }
  }, [auth])

  return (
    <OpointAuthProvider
      API_URL={authUrl}
      callback={appUrl}
      persistentImpersonation
    >
      {children}
    </OpointAuthProvider>
  )
}

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@opoint/infomedia-storybook'
import { useFormContext } from 'react-hook-form'
import { FormState } from '../types'

const SoMeRatesModal = () => {
  const { control, watch } = useFormContext<FormState>()
  const sm_rate = watch('sm_rate')

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <div>
          <Button className="ps-0" type="button" variant="text">
            Social media rates
          </Button>
        </div>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Social media rates</AlertDialogTitle>
        </AlertDialogHeader>
        {sm_rate.map((rate, index) => (
          <FormField
            key={rate.feed_id}
            control={control}
            name={`sm_rate.${index}.maxrate`}
            rules={{
              required: `${rate.feed_name} can not be empty`,
            }}
            render={({ field }) => {
              return (
                <FormItem className="my-2">
                  <FormLabel>{rate.feed_name}</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} value={rate.maxrate} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
        ))}
        <AlertDialogFooter>
          <AlertDialogCancel>Close</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default SoMeRatesModal

import {
  ArticleIconType,
  ArticleItemExcerpt,
  ArticleItemRoot,
  ArticleItemSourceDate,
  ArticleItemTitle,
  SourcesExpandIconToggle,
} from '@opoint/infomedia-storybook'
import { Document } from '../../generated-types/types.schemas'
import { getSocialMediaType } from '../ActiveArticle/utils'
import getArticleId from '../../utilities/getArticleId'
import parseTextWithMatches from '../../utilities/parseTextWithMatches'
import useIdenticalArticles from '../../hooks/useIdenticalArticles'
import { keepTextAroundMatches } from './utilities'
import CompareStateBadge from './CompareStateBadge'
import { TabType } from './Tabs'

type ListItemProps = {
  article: Document
  onClick: (article: Document) => void
  badgeType: TabType
  isActive: boolean
}

const ListItem = ({ article, onClick, badgeType, isActive }: ListItemProps) => {
  const {
    hasIdenticalArticles,
    identicalArticles,
    activeArticleIndex,
    activeArticle,
    setActiveArticleIndex,
  } = useIdenticalArticles(article)

  const { summary, articlemedia, caption, body } = activeArticle

  // Show text around matches in the excerpt
  const excerpt = keepTextAroundMatches({
    text: `${summary?.text} ${caption?.text} ${body?.text} ${articlemedia?.[0]?.text}`,
  })

  return (
    <div
      className="relative border-b border-b-grey.6"
      key={getArticleId(article)}
      onClick={() => {
        onClick(article)
      }}
    >
      <ArticleItemRoot isSelected={isActive}>
        <div className="px-3">
          <CompareStateBadge type={badgeType} />
          <>
            {identicalArticles.map((doc: Document, index) => (
              <ArticleItemSourceDate
                mediaType={doc.mediatype?.text as ArticleIconType}
                socialMediaType={getSocialMediaType(doc.first_source.sitename)}
                sourceName={doc.first_source.name}
                unixTimestamp={doc.unix_timestamp}
                key={getArticleId(doc)}
                onSourceClick={(e) => {
                  e.stopPropagation()
                  setActiveArticleIndex(index)
                }}
                active={activeArticleIndex === index}
              />
            ))}
          </>
          <ArticleItemTitle>
            {parseTextWithMatches(activeArticle.header?.text)}
          </ArticleItemTitle>
          {excerpt && (
            <ArticleItemExcerpt className="mb-2 pt-2">
              {parseTextWithMatches(excerpt)}
            </ArticleItemExcerpt>
          )}
          {hasIdenticalArticles && (
            <SourcesExpandIconToggle
              className="mt-2"
              sourcesCount={
                identicalArticles.length - 1 // Exclude the original article
              }
            />
          )}
        </div>
      </ArticleItemRoot>
    </div>
  )
}

export default ListItem

import { useMemo } from 'react'

import removeDuplicateNumbers from '../utilities/removeDuplicateNumbers'

import useGetAlerts from './useGetAlerts'
import useGetTags from './useGetTags'
import useGetTrashes from './useGetTrashes'

const useBaskets = () => {
  const { data: alerts, isLoading: isLoadingAlerts } = useGetAlerts()
  const { data: tags, isLoading: isLoadingTags } = useGetTags()
  const { data: trashes, isLoading: isLoadingTrashes } = useGetTrashes()

  return useMemo(() => {
    if (isLoadingAlerts || isLoadingTags || isLoadingTrashes) {
      return null
    }

    if (!alerts || !tags || !trashes) {
      return null
    }

    const alertTagIds = alerts
      .filter((alert) => !!alert.editable)
      .map(({ id }) => id)

    const tagIds = tags.map(({ id }) => id)

    const trashIds = trashes.map(({ id }) => Math.abs(id))

    const basketIds = removeDuplicateNumbers([
      ...alertTagIds,
      ...tagIds,
      ...trashIds,
    ])

    return basketIds
  }, [alerts, tags, trashes, isLoadingAlerts, isLoadingTags, isLoadingTrashes])
}

export default useBaskets

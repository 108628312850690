import { MoreHorizontal24Regular, Stack20Filled } from '@fluentui/react-icons'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@opoint/infomedia-storybook'
import useSettingValue from '../../hooks/useSettingValue'
import useUpdateSettings from '../../hooks/useUpdateSettings'
import {
  enableGroupingSettingName,
  appUISettingName,
} from '../../constants/settings'

const SettingsMenu = () => {
  const { value: enableGroupingValue } = useSettingValue<boolean>(
    enableGroupingSettingName,
    true,
  )

  const { mutate: updateSettings } = useUpdateSettings()

  const handleToggleGroupingChange = () => {
    updateSettings([
      {
        name: appUISettingName,
        value: {
          [enableGroupingSettingName]: !enableGroupingValue,
        },
      },
    ])
  }

  return (
    <DropdownMenu onOpenChange={() => {}}>
      <DropdownMenuTrigger>
        <Button size="icon" variant="outline" type="button">
          <MoreHorizontal24Regular />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuItem onClick={handleToggleGroupingChange}>
          <Stack20Filled />
          {enableGroupingValue ? 'Disable' : 'Enable'} grouping
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default SettingsMenu

import { QueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Profile } from '../types'
import { useCustomAxiosInstance } from '../axios/instance'

export const getGetProfileQueryKey = (
  profileId: number | null,
  withQuery: boolean,
) => [`profileData-${profileId}-${withQuery ? 'withQuery' : 'withoutQuery'}}`]

type CustomQueryOptions = QueryOptions<Profile, AxiosError> & {
  onSuccess?: (data: Profile) => void
}

const useGetProfile = ({
  profileId,
  options = {},
  withQuery = false,
}: {
  profileId: number | null
  options?: CustomQueryOptions
  withQuery?: boolean
}) => {
  const apiClient = useCustomAxiosInstance<Profile>()

  const queryKey = getGetProfileQueryKey(profileId, withQuery)

  return useQuery<Profile, AxiosError>({
    queryKey,
    queryFn: () =>
      apiClient({
        url: `/profiles/${profileId}/`,
        method: 'get',
        params: {
          with_history: true,
          with_query: withQuery,
        },
      }).catch((error: AxiosError) => Promise.reject(error.response)),
    ...options,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!profileId,
  })
}

export default useGetProfile

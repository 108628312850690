import { useMemo } from 'react'
const useFilterByKey: <T>(payload: {
  array: Array<T>
  key: string
  searchTerm: string
}) => Array<T> = ({ array, key, searchTerm }) => {
  const memoizedArray = useMemo(() => {
    if (!searchTerm) {
      return array
    }
    const re = new RegExp(searchTerm, 'i')
    const filteredArray = (array ?? [])?.filter((entity) =>
      re.test(entity[key]),
    )
    return filteredArray
  }, [array, key, searchTerm])
  return memoizedArray
}
export default useFilterByKey

import { ArticlePreviewImage } from '@opoint/infomedia-storybook'
import { DocumentArticleimagesArticleimageItem } from '../../generated-types/types.schemas'
import parseTextWithMatches from '../../utilities/parseTextWithMatches'

const ImageWithCaption = ({
  image,
}: {
  image: DocumentArticleimagesArticleimageItem
}) => (
  <>
    <ArticlePreviewImage imageUrl={image.url || ''} />
    <div className="my-5 text-sky.cloudy">
      {parseTextWithMatches(image.caption?.text)}
    </div>
  </>
)

export default ImageWithCaption

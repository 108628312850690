import { useToast } from '@opoint/infomedia-storybook'
import { useState } from 'react'

type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean> // Return success

export function useCopyToClipboard(toastLabel?: string): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)
  const { toast } = useToast()

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      toast({
        title: 'Copy to clipboard is not supported in your browser',
        variant: 'destructive',
      })

      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      toast({
        title: `${toastLabel || 'Value'} was copied to clipboard`,
        variant: 'success',
      })

      return true
    } catch (error) {
      setCopiedText(null)
      toast({
        title: 'Failed to copy to clipboard',
        variant: 'destructive',
      })

      return false
    }
  }

  return [copiedText, copy]
}

import { useOpointAuthState } from '@opoint/authjs-react'
import { isEmpty } from 'lodash-es'
import React, { useEffect, useRef } from 'react'

import { cn } from '@opoint/infomedia-storybook'
import { User } from '../../../types'

type UsersListProps = {
  activeUserId: number | null
  onActiveUserIdChange: (id: number | undefined) => void
  users: User[] | undefined
  userSearchTerm: string
}

type UserListItemProps = {
  isActive: boolean
} & React.HTMLAttributes<HTMLLIElement>

const UserListItem = ({ isActive, ...props }: UserListItemProps) => {
  const itemRef = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (isActive && itemRef.current) {
      itemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [isActive])

  return (
    <li
      className={cn(
        'p-5 cursor-pointer	border-b border-r border-grey.6 hover:bg-sky.8',
        {
          'bg-sky.8': isActive,
        },
      )}
      ref={itemRef}
      {...props}
    />
  )
}

const UsersList = ({
  activeUserId,
  onActiveUserIdChange,
  users = [],
  userSearchTerm,
}: UsersListProps) => {
  const authState = useOpointAuthState()

  const username =
    authState?.user?.impersonator?.username || authState?.user?.username
  const showLoggedInUser = username?.includes(userSearchTerm) || !userSearchTerm
  const isEmptyUsers = isEmpty(users) && !showLoggedInUser

  return (
    <ul className="h-full">
      {isEmptyUsers ? (
        <div className="p-5">No users found</div>
      ) : (
        <>
          {showLoggedInUser && (
            <UserListItem
              isActive={!activeUserId}
              onClick={() => {
                onActiveUserIdChange(undefined)
              }}
            >
              {username}
            </UserListItem>
          )}
          {users.map((user) => (
            <UserListItem
              isActive={user.id_user === activeUserId}
              key={user.id_user}
              onClick={() => {
                onActiveUserIdChange(user.id_user)
              }}
            >
              {user.username}
            </UserListItem>
          ))}
        </>
      )}
    </ul>
  )
}

export default UsersList

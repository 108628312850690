import { SearchRequestBodyExpressionsSearchlineObjectExpressionsItemSearchlineFiltersItem } from '../generated-types/types.schemas'

const parseFiltersToString = (
  filters: SearchRequestBodyExpressionsSearchlineObjectExpressionsItemSearchlineFiltersItem[],
): string =>
  filters
    .map((filter) => {
      const { type, id, name } = filter

      if (type === 'searchterm') {
        return name
      }

      return `${type}:${id}`
    })
    .join(' ')

export default parseFiltersToString

import { EditorState } from '@codemirror/state'
import { ReactCodeMirrorProps } from '@uiw/react-codemirror'
import { EditorView } from 'codemirror'
import CodeMirrorMerge from 'react-codemirror-merge'

import { cn } from '@opoint/infomedia-storybook'
import { intersectionWith } from 'lodash-es'
import { useMemo } from 'react'
import {
  languages,
  addFilterSource,
  filterInlineExtension,
  filterMappingsExtension,
  infomediaThemeExtension,
  filterGroupingExtension,
  utils,
  coreExtensions,
} from '@opoint/codemirror-extensions'
import { QueryType } from '../../types/profile'
import { ProfileItem } from '../../generated-types/types.schemas'
import useCustomFilters from '../../hooks/useCustomFilters'
import {
  getSuggestionsSingleMode,
  getSuggestionIds,
} from '../CodeEditor/services'
import styles from './index.module.scss'

const Original = CodeMirrorMerge.Original
const Modified = CodeMirrorMerge.Modified

type Props = {
  originalValue?: string
  originalQueryType?: QueryType
  modifiedValue?: string
  modifiedQueryType?: QueryType
  originalFilters?: ProfileItem['searchline']['filters']
  modifiedFilters?: ProfileItem['searchline']['filters']
} & ReactCodeMirrorProps

const CompareCodeEditor = ({
  originalValue,
  modifiedValue,
  originalQueryType = QueryType.OQL,
  modifiedQueryType = QueryType.OQL,
  originalFilters = [],
  modifiedFilters = [],
  ...props
}: Props) => {
  const originalLanguage = languages[originalQueryType]
  const modifiedLanguage = languages[modifiedQueryType]

  const { suggestServerFilterSource } = utils

  const customFilterSource = useCustomFilters()

  const sharedFilters = useMemo(
    () =>
      intersectionWith(
        originalFilters,
        modifiedFilters,
        (a, b) => a.id === b.id && a.type === b.type,
      ),
    [originalFilters, modifiedFilters],
  )

  const commonExtensions = useMemo(
    () => [
      addFilterSource.of(customFilterSource),
      addFilterSource.of(
        suggestServerFilterSource(getSuggestionsSingleMode, getSuggestionIds),
      ),
      coreExtensions(),
      filterInlineExtension(),
      filterMappingsExtension(),
      EditorView.editable.of(false),
      EditorState.readOnly.of(true),
      infomediaThemeExtension(),
    ],
    [customFilterSource, suggestServerFilterSource],
  )

  // When value is undefined, we use the other value so that there are no errors highlighted
  const finalOriginalValue =
    originalValue === undefined ? modifiedValue : originalValue
  const finalModifiedValue =
    modifiedValue === undefined ? originalValue : modifiedValue

  return (
    <CodeMirrorMerge orientation="a-b">
      <Original
        extensions={[
          EditorView.editorAttributes.of({
            class: cn('mr-1', {
              [styles.placeholder]: originalValue === undefined,
            }),
          }),
          originalLanguage,
          filterGroupingExtension(originalFilters, sharedFilters, 'original'),
          ...commonExtensions,
        ]}
        value={finalOriginalValue}
        {...props}
      />
      <Modified
        extensions={[
          EditorView.editorAttributes.of({
            class: cn({
              [styles.placeholder]: modifiedValue === undefined,
            }),
          }),
          modifiedLanguage,
          filterGroupingExtension(modifiedFilters, sharedFilters, 'modified'),
          ...commonExtensions,
        ]}
        value={finalModifiedValue}
        {...props}
      />
    </CodeMirrorMerge>
  )
}

export default CompareCodeEditor

import { Button, useToast } from '@opoint/infomedia-storybook'
import { useNavigate } from 'react-router-dom'

import { Save20Regular } from '@fluentui/react-icons'
import * as ProfilePage from '../../../components/ProfilePage'
import { PREVIEW_BUTTON_PORTAL_ID } from '../../../components/ProfilePage/Form'
import { FormState } from '../../../components/ProfilePage/types'
import { useProfilesCreate } from '../../../generated-types/profiles/profiles'
import { formatQueries } from '../UpdateProfilePage/utilities'

const defaultValues: FormState = {
  profileName: '',
  parent: 0,
  hasParent: false,
  folder: 0,
  queries: {
    required: [],
    optional: [],
    exclude: [],
  },
  make_persistent: null,
  persistent_backfill_start: undefined,
  blessed: false,
  persistent_grouping: true,
  sm_rate: [],
}

const formId = 'create-profile-form'

const CreateProfilePage = () => {
  const navigate = useNavigate()
  const { toast } = useToast()

  const { mutate: createProfile, isLoading: isCreatingProfile } =
    useProfilesCreate()

  const handleCreateProfile = (data: FormState) => {
    const newProfileData = {
      name: data.profileName,
      parent: data.hasParent ? data.parent : 0,
      items: formatQueries([
        ...data.queries.required,
        ...data.queries.exclude,
        ...data.queries.optional,
      ]),
      make_persistent: data.make_persistent,
      persistent_backfill_start: data.persistent_backfill_start,
      persistent_grouping: data.persistent_grouping,
      blessed: data.blessed,
    }

    createProfile(
      { data: newProfileData },
      {
        onSuccess: (data) => {
          toast({
            title: 'Profile was successfully created.',
            variant: 'success',
          })
          navigate(`/profiles/${data.id}`)
        },
        onError: () =>
          toast({
            title: 'Something went wrong. Please try again.',
            variant: 'destructive',
          }),
      },
    )
  }

  return (
    <ProfilePage.Wrapper
      buttons={
        <>
          <div id={PREVIEW_BUTTON_PORTAL_ID} />
          <Button
            disabled={isCreatingProfile}
            form={formId}
            type="submit"
            variant="outline"
          >
            <Save20Regular />
            {isCreatingProfile ? 'Creating...' : 'Create'}
          </Button>
        </>
      }
    >
      <ProfilePage.Form
        defaultValues={defaultValues}
        formId={formId}
        onSubmit={handleCreateProfile}
        isNewProfile
      ></ProfilePage.Form>
    </ProfilePage.Wrapper>
  )
}

export default CreateProfilePage

import { useOpointAuth } from '@opoint/authjs-react'
import { useCallback, useState } from 'react'

type ImpersonateHandler = (payload: {
  userId?: number | undefined
  onSuccess?: () => void
  onError?: () => void
}) => void

const useImpersonate = (): {
  handleImpersonate: ImpersonateHandler
  isLoading: boolean
} => {
  const auth = useOpointAuth()
  const [isLoading, setIsLoading] = useState(false)

  const handleImpersonate = useCallback(
    ({ userId, onSuccess, onError }) => {
      setIsLoading(true)

      auth
        ?.impersonate(userId)
        .then(() => {
          setIsLoading(false)
          if (onSuccess) {
            onSuccess()
          }
        })
        .catch((error) => {
          console.error(error)
          setIsLoading(false)
          if (onError) {
            onError()
          }
        })
    },
    [auth],
  ) as ImpersonateHandler

  return { handleImpersonate, isLoading }
}

export default useImpersonate

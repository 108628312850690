import { createContext, useContext, useRef } from 'react'

// Define the shape of the context
interface EditorContextProps {
  updateSyntaxState: (newState: SyntaxState) => void
  checkSyntaxValidity: () => boolean
}

interface EditorProps {
  children: React.ReactNode
}

export interface SyntaxState {
  isValid: boolean
  fieldId: string | number
}

// Create the context with default values
const EditorContext = createContext<EditorContextProps>({
  updateSyntaxState: () => {},
  checkSyntaxValidity: () => false,
})

// Create a provider component
export const EditorContextProvider = ({ children }: EditorProps) => {
  const editorSyntaxStateRef = useRef<SyntaxState[]>([])

  const checkSyntaxValidity = () => {
    return editorSyntaxStateRef.current.every((state) => state.isValid)
  }

  const updateSyntaxState = (newState: SyntaxState) => {
    const index = editorSyntaxStateRef.current.findIndex(
      (state) => state.fieldId === newState.fieldId,
    )

    if (index === -1) {
      editorSyntaxStateRef.current.push(newState)
    } else {
      editorSyntaxStateRef.current[index] = newState
    }
  }

  return (
    <EditorContext.Provider value={{ checkSyntaxValidity, updateSyntaxState }}>
      {children}
    </EditorContext.Provider>
  )
}

// Create a custom hook to use the search context
export const useEditorContext = () => useContext(EditorContext)

import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from '@opoint/infomedia-storybook'
import { format, fromUnixTime } from 'date-fns'
import { useMemo } from 'react'

import { useProfilesHistoryRetrieve } from '../../generated-types/profiles/profiles'
import { ProfileHistoryItemsResponse } from '../../generated-types/types.schemas'
import useProfileId from '../../hooks/useProfileId'

const DEFAULT_OPTION = { label: 'Select option', value: -1 }

type Props = {
  value?: ProfileHistoryItemsResponse | number
  onChange: (newValue: ProfileHistoryItemsResponse | number) => void
  placeholder?: string
  extraOptions?: { label: string; value: number }[]
  isLoading?: boolean
}

const ProfileHistorySelect = ({
  onChange,
  value,
  extraOptions,
  isLoading: isLoadingProp,
}: Props) => {
  const profileId = useProfileId()

  const {
    data: profileHistoryData,
    isLoading: isLoadingProfileHistoryData,
    isFetching,
  } = useProfilesHistoryRetrieve(profileId || 0)

  const isLoading =
    isLoadingProp || isFetching || (isLoadingProfileHistoryData && !!profileId)

  const selectedOption = useMemo(() => {
    if (typeof value === 'number') {
      const selectedOption = extraOptions?.find(
        ({ value: itemValue }) => itemValue === value,
      )

      if (selectedOption) {
        return selectedOption
      }

      return DEFAULT_OPTION
    }

    const selectedOption: ProfileHistoryItemsResponse | undefined = (
      profileHistoryData?.results || []
    ).find(({ timestamp }) => timestamp === value?.timestamp)

    if (!selectedOption) {
      return DEFAULT_OPTION
    }

    return {
      label: format(fromUnixTime(selectedOption.timestamp), 'do LLL HH:mm'),
      value: selectedOption.timestamp,
    }
  }, [profileHistoryData?.results, value, extraOptions])

  const options = useMemo(() => {
    const optionsList =
      profileHistoryData?.results
        .sort((a, b) => b.timestamp - a.timestamp)
        .map((result) => ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          label: format(fromUnixTime(result.timestamp), 'do LLL HH:mm'),
          value: result.timestamp,
        })) ?? []

    if (extraOptions) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return [...extraOptions, ...optionsList]
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return optionsList
  }, [profileHistoryData, extraOptions])

  const handleOnChange = (newValue: string) => {
    const newValueAsInt = parseInt(newValue)

    const newSelectedHistory = (profileHistoryData?.results || []).find(
      ({ timestamp }) => timestamp === newValueAsInt,
    )

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    onChange(newSelectedHistory || newValueAsInt)
  }

  return (
    <div className="relative z-10">
      <Select
        onChange={handleOnChange}
        disabled={isLoading}
        value={`${selectedOption.value}`}
      >
        <SelectTrigger
          placeholder="Profile history"
          displayValue={() => selectedOption.label}
        />
        <SelectContent>
          {options.map((option) => (
            <SelectItem key={option.value} value={`${option.value}`}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default ProfileHistorySelect

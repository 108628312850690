/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Button } from '@opoint/infomedia-storybook'
import {
  formatParser as IQLformatParser,
  parser as IQLParser,
} from '@opoint/lezer-iql'
import {
  formatParser as OQLformatParser,
  parser as OQLParser,
} from '@opoint/lezer-oql'
import { ComponentProps, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import {
  Add20Regular,
  ArrowSort20Regular,
  ChevronDown20Regular,
  ChevronUp20Regular,
  Code20Regular,
  Copy20Regular,
  Delete20Regular,
  Highlight20Regular,
} from '@fluentui/react-icons'
import { lineModeName } from '../../../constants'
import {
  LinemodeEnum,
  ProfileItem,
} from '../../../generated-types/types.schemas'
import { LocalNameOfQueryType, QueryType } from '../../../types/profile'
import CodeEditor from '../../CodeEditor'
import { FormState, QueryObject } from '../types'

import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { DEFAULT_QUERY_LANGUAGE } from '../../../constants/localStorage'
import ChangeLineModeModal from './ChangeLineModeModal'
import DeleteConfirmationModal from './DeleteConfirmationModal'

const indentSize = 2

const getFormattedQuery = (query: string, queryType: QueryType) => {
  return {
    IQL: IQLformatParser(query, IQLParser, true, indentSize),
    OQL: OQLformatParser(query, OQLParser, true, indentSize),
  }[queryType]
}

type SearchLineFieldName =
  | 'queries.required'
  | 'queries.optional'
  | 'queries.exclude'

const SearchLines = ({ lineMode }: { lineMode: LinemodeEnum }) => {
  const { control, watch, getValues, setValue } = useFormContext<FormState>()
  const [queryType] = useLocalStorage<QueryType>(
    DEFAULT_QUERY_LANGUAGE,
    QueryType.OQL,
  )

  const [changeLineModeModalId, setChangeLineModeModalId] = useState<string>()
  const [deleteConfirmationModalId, setDeleteConfirmationModalId] =
    useState<string>()

  let fieldsName: SearchLineFieldName = 'queries.required'
  if (lineMode === LinemodeEnum.O) {
    fieldsName = 'queries.optional'
  } else if (lineMode === LinemodeEnum.E) {
    fieldsName = 'queries.exclude'
  }

  const { fields, append, remove, swap } = useFieldArray<FormState>({
    control,
    name: fieldsName,
  })

  const watchQueriesFields = watch(fieldsName) // Using watch in order to register and display updates, when fields are changed.
  const controlledQueriesFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchQueriesFields[index],
    }
  })

  const handleAddQuery = () => {
    append({
      searchline: { searchterm: '', filters: [] },
      type: queryType,
      linemode: lineMode,
    })
  }

  const handleFormatQueryByIndex = (
    index: number,
    searchterm: string,
    queryType: QueryType,
  ) => {
    const formattedQuery = getFormattedQuery(searchterm, queryType)
    setValue(`${fieldsName}.${index}.searchline.searchterm`, formattedQuery)
  }

  const handleMoveQuery = (index: number, newLineMode: LinemodeEnum) => {
    const currentQuery = getValues(`${fieldsName}.${index}`)

    const moveToFieldsName =
      newLineMode === LinemodeEnum.R
        ? 'queries.required'
        : newLineMode === LinemodeEnum.O
        ? 'queries.optional'
        : 'queries.exclude'

    remove(index)
    setValue(moveToFieldsName, [
      { ...currentQuery, linemode: newLineMode },
      ...getValues(moveToFieldsName),
    ])
  }

  const hasMoreThanOneQuery = controlledQueriesFields.length > 1

  const copy = useCopyToClipboard('Query')[1]

  const copyQueryToClipboard = async (
    searchterm: string,
    filters: ProfileItem['searchline']['filters'],
  ) => {
    let query = ''

    if (filters.length > 0) {
      query = filters.map(({ id, type }) => `${type}:${id}`).join(' ')
    }

    if (searchterm) {
      if (query === '') {
        query = searchterm
      } else {
        query = `${query} ${searchterm}`
      }
    }

    await copy(query)
  }

  return (
    <div className="mt-5">
      {controlledQueriesFields.length > 0 && (
        <p className="m-0">{lineModeName[lineMode]}</p>
      )}
      {controlledQueriesFields.map((field, index) => {
        const changeQueryLanguageTo =
          field.type === QueryType.IQL ? QueryType.OQL : QueryType.IQL

        const { searchterm, filters } = field.searchline

        const menuItems: ComponentProps<typeof CodeEditor>['menuItems'] = [
          {
            icon: <Highlight20Regular />,
            title: 'Format query',
            onClick: () => {
              if (!searchterm) {
                return
              }

              handleFormatQueryByIndex(index, searchterm || '', field.type)
              setValue(`${fieldsName}.${index}.isFormatted`, true)
            },
          },
          {
            icon: <Copy20Regular />,
            title: 'Copy query',
            onClick: () => {
              void copyQueryToClipboard(searchterm || '', filters)
            },
          },
          {
            icon: <Delete20Regular />,
            title: 'Delete query',
            onClick: () => {
              setDeleteConfirmationModalId(`${field.id}`)
            },
          },
          {
            icon: <Code20Regular />,
            title: `Change to ${LocalNameOfQueryType[changeQueryLanguageTo]}`,
            onClick: () => {
              setValue(`${fieldsName}.${index}.type`, changeQueryLanguageTo)
            },
          },
          {
            icon: <ArrowSort20Regular />,
            title: 'Move query',
            onClick: () => {
              setChangeLineModeModalId(`${field.id}`)
            },
          },
        ]

        if (hasMoreThanOneQuery && index !== 0) {
          menuItems.unshift({
            icon: <ChevronUp20Regular />,
            title: 'Move query up',
            onClick: () => {
              swap(index, index - 1)
            },
          })
        }

        if (
          hasMoreThanOneQuery &&
          index !== controlledQueriesFields.length - 1
        ) {
          menuItems.push({
            icon: <ChevronDown20Regular />,
            title: 'Move query down',
            onClick: () => {
              swap(index, index + 1)
            },
          })
        }

        return (
          <div className="mt-1" key={field.id}>
            <CodeEditor
              filters={filters}
              menuItems={menuItems}
              onChange={(searchline: QueryObject['searchline']) => {
                setValue(`${fieldsName}.${index}.searchline`, searchline)
              }}
              queryType={field.type}
              value={searchterm}
              isFormatted={field.isFormatted}
              fieldId={field.id}
            />
            <ChangeLineModeModal
              defaultValue={field.linemode}
              isShown={changeLineModeModalId === `${field.id}`}
              onChange={(linemode) => {
                handleMoveQuery(index, linemode)
              }}
              onHide={() => {
                setChangeLineModeModalId(undefined)
              }}
            />
            <DeleteConfirmationModal
              isShown={deleteConfirmationModalId === `${field.id}`}
              onConfirm={() => {
                remove(index)
              }}
              onHide={() => {
                setDeleteConfirmationModalId(undefined)
              }}
            />
          </div>
        )
      })}
      {controlledQueriesFields.length === 0 ? (
        <div className="mt-5 flex justify-center rounded border border-dashed border-grey.6 p-3">
          <Button onClick={handleAddQuery} type="button" variant="text">
            {`Add ${lineModeName[lineMode]} query`}
          </Button>
        </div>
      ) : (
        <div className="mt-2 flex justify-center">
          <Button onClick={handleAddQuery} type="button" variant="outline">
            <Add20Regular />
            Add query
          </Button>
        </div>
      )}
    </div>
  )
}

export default SearchLines

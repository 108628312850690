import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@opoint/infomedia-storybook'
import { useState } from 'react'
import { lineModeName } from '../../../constants'
import { LinemodeEnum } from '../../../generated-types/types.schemas'

type Props = {
  onHide: () => void
  isShown: boolean
  defaultValue: LinemodeEnum
  onChange: (queryType: LinemodeEnum) => void
}

const ChangeLineModeModal = ({
  onHide,
  isShown,
  defaultValue,
  onChange,
}: Props) => {
  const [lineMode, setLineMode] = useState<LinemodeEnum>(defaultValue)

  return (
    <AlertDialog
      open={isShown}
      onOpenChange={() => {
        onHide()
        setLineMode(defaultValue)
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader className="justify-center">
          <AlertDialogTitle>Move query to section</AlertDialogTitle>
        </AlertDialogHeader>
        <Select
          onChange={(value: string) => {
            setLineMode(value as LinemodeEnum)
          }}
          value={lineMode}
        >
          <SelectTrigger
            placeholder="Select query language"
            displayValue={() => lineModeName[lineMode]}
          />
          <SelectContent>
            {[
              { value: LinemodeEnum.R, label: lineModeName.R },
              { value: LinemodeEnum.O, label: lineModeName.O },
              { value: LinemodeEnum.E, label: lineModeName.E },
            ].map((option) => (
              <SelectItem key={option.value} value={`${option.value}`}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button type="button" variant="outline">
              Cancel
            </Button>
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              onClick={() => {
                onChange(lineMode)
              }}
              type="button"
            >
              Update
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default ChangeLineModeModal

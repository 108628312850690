import {
  Code24Regular,
  DocumentText24Regular,
  ShareIos24Regular,
} from '@fluentui/react-icons'
import { useOpointAuth } from '@opoint/authjs-react'
import { Button } from '@opoint/infomedia-storybook'
import Image from '../../images/infomediaLogo.svg'

const SideMenu = () => {
  const auth = useOpointAuth()

  const handleLogOut = () => {
    void auth?.logout()
  }

  return (
    <div className="flex h-full w-16 shrink-0 flex-col items-center justify-start gap-8 bg-white pt-8">
      <img
        src={Image}
        height="28px"
        alt="Infomedia logo"
        className="pb-[0.625rem]"
      />

      <div className="flex w-full flex-col">
        <a href="https://qb.infomedia.dk">
          <div className="flex w-full justify-center bg-grey.7 py-[0.625rem]">
            <Code24Regular className="text-sky.4" />
          </div>
        </a>

        <a href="https://mm.infomedia.dk">
          <div className="flex w-full justify-center py-[0.625rem]">
            <DocumentText24Regular className="text-spring.1" />
          </div>
        </a>
      </div>

      <Button onClick={handleLogOut} variant="icon" className="mb-4 mt-auto">
        <span className="sr-only">Logout</span>
        <ShareIos24Regular className="rotate-90" />
      </Button>
    </div>
  )
}

export default SideMenu

import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@opoint/infomedia-storybook'
import { Fragment, useMemo, useState } from 'react'
import { lineModeName } from '../../../constants'
import { useProfilesRetrieve } from '../../../generated-types/profiles/profiles'
import {
  LinemodeEnum,
  ProfileHistoryItemsResponse,
  ProfileItem,
} from '../../../generated-types/types.schemas'
import useProfileId from '../../../hooks/useProfileId'
import {
  getQueries,
  getQueryTypeFromSearchMode,
} from '../../../pages/AuthPages/UpdateProfilePage/utilities'
import { QueryType } from '../../../types/profile'
import CompareCodeEditor from '../../CompareCodeEditor'
import ProfileHistorySelect from '../../ProfileHistorySelect'
import { FormState, QueryObject } from '../types'

import { parseFiltersIntoSearchtermInProfileItems } from './utils'

type Comparison = ({
  value: string
  type: QueryType
  filters: ProfileItem['searchline']['filters']
} | null)[][]

const getComparisons = (
  sourceQueries: Array<QueryObject | ProfileItem>,
  targetQueries: ProfileItem[],
  lineMode: LinemodeEnum,
): Comparison => {
  const finalSourceQueries = sourceQueries.filter(
    (item) => item.linemode === lineMode,
  )
  const finalTargetQueries = targetQueries.filter(
    (item) => item.linemode === lineMode,
  )

  const comparisons: Comparison = []
  const isSourceDraft = finalSourceQueries[0] && 'type' in finalSourceQueries[0]
  const isTargetDraft = finalTargetQueries[0] && 'type' in finalTargetQueries[0]

  for (
    let i = 0;
    i < Math.max(finalSourceQueries.length, finalTargetQueries.length);
    i++
  ) {
    const source = finalSourceQueries[i]
    const target = finalTargetQueries[i]
    comparisons.push([
      source
        ? {
            value: source.searchline.searchterm || '',
            type: isSourceDraft
              ? (source as QueryObject).type
              : getQueryTypeFromSearchMode(source.searchline.searchmode || ''),
            filters: source.searchline.filters,
          }
        : null,
      target
        ? {
            value: target.searchline.searchterm || '',
            type: isTargetDraft
              ? (target as QueryObject).type
              : getQueryTypeFromSearchMode(target.searchline.searchmode || ''),
            filters: target.searchline.filters,
          }
        : null,
    ])
  }

  return comparisons
}

// ProfileHistorySelect uses as an option value -> ProfileHistoryItemsResponse or number
// As a default option value, ProfileHistorySelect uses number -1
// All the other numbers can be used as a value for extraOptions
export const DRAFT_VALUE = -2
export const PUBLISHED_VALUE = -3

type Props = {
  isShown: boolean
  onHide: () => void
  draftQuery: QueryObject[]
  setDraftQuery: (newValue: FormState['queries']) => void
}

const CompareQueriesModal = ({
  isShown,
  onHide,
  draftQuery,
  setDraftQuery,
}: Props) => {
  const [sourceQueries, setSourceQueries] = useState<
    ProfileHistoryItemsResponse | number
  >(DRAFT_VALUE)
  const [targetQueries, setTargetQueries] = useState<
    ProfileHistoryItemsResponse | number
  >(PUBLISHED_VALUE)

  const profileId = useProfileId()

  const { data: profileData } = useProfilesRetrieve(profileId || 0, {
    with_query: true,
  })

  const finalSourceQueries = useMemo(() => {
    if (typeof sourceQueries === 'number') {
      if (sourceQueries === DRAFT_VALUE) {
        return draftQuery
      } else if (sourceQueries === PUBLISHED_VALUE) {
        return parseFiltersIntoSearchtermInProfileItems(
          profileData?.items || [],
        )
      }
    } else {
      return parseFiltersIntoSearchtermInProfileItems(sourceQueries.items || [])
    }

    return []
  }, [sourceQueries, draftQuery, profileData])

  const finalTargetQueries = useMemo(() => {
    if (typeof targetQueries === 'number') {
      if (targetQueries === PUBLISHED_VALUE) {
        return parseFiltersIntoSearchtermInProfileItems(
          profileData?.items || [],
        )
      }
    } else {
      return parseFiltersIntoSearchtermInProfileItems(targetQueries.items || [])
    }

    return []
  }, [targetQueries, profileData])

  const requiredComparisons = useMemo(
    () =>
      getComparisons(finalSourceQueries, finalTargetQueries, LinemodeEnum.R),
    [finalSourceQueries, finalTargetQueries],
  )

  const optionalComparisons = useMemo(
    () =>
      getComparisons(finalSourceQueries, finalTargetQueries, LinemodeEnum.O),
    [finalSourceQueries, finalTargetQueries],
  )

  const excludedComparisons = useMemo(
    () =>
      getComparisons(finalSourceQueries, finalTargetQueries, LinemodeEnum.E),
    [finalSourceQueries, finalTargetQueries],
  )

  const comparisons: Array<[Comparison, LinemodeEnum]> = [
    [requiredComparisons, LinemodeEnum.R],
    [optionalComparisons, LinemodeEnum.O],
    [excludedComparisons, LinemodeEnum.E],
  ]

  return (
    <Dialog onOpenChange={onHide} open={isShown}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Compare</DialogTitle>
        </DialogHeader>
        <DialogBody className="h-[85vh]">
          <div className="relative flex p-1 ps-0">
            <div className="w-1/2">
              <div className="w-1/2">
                <ProfileHistorySelect
                  extraOptions={[
                    { label: 'Draft', value: DRAFT_VALUE },
                    { label: 'Published', value: PUBLISHED_VALUE },
                  ]}
                  onChange={(newValue) => {
                    setSourceQueries(newValue)
                  }}
                  value={sourceQueries}
                />
              </div>
            </div>
            <div className="flex w-1/2 justify-between">
              <div className="w-1/2 ps-1">
                <ProfileHistorySelect
                  extraOptions={[
                    { label: 'Published', value: PUBLISHED_VALUE },
                  ]}
                  onChange={(newValue) => {
                    setTargetQueries(newValue)
                  }}
                  value={targetQueries}
                />
              </div>
              <Button
                className="text-sky.1 no-underline"
                onClick={() => {
                  setDraftQuery(
                    getQueries({
                      items: finalTargetQueries,
                      profileId: profileId || 0,
                    }),
                  )
                  onHide()
                }}
                variant="text"
              >
                Edit from this version
              </Button>
            </div>
          </div>
          <div>
            {comparisons.map(([comparisons, linemode], index) => (
              <Fragment key={`comparison-${index}`}>
                {comparisons.length > 0 && (
                  <div className="flex py-2 font-bold">
                    <div className="w-1/2">{lineModeName[linemode]}</div>
                    <div className="w-1/2">{lineModeName[linemode]}</div>
                  </div>
                )}
                {comparisons.map(([original, modified], index) => (
                  <div className="mb-2" key={index}>
                    <div className="flex">
                      <div className="w-1/2 font-bold ">{original?.type}</div>
                      <div className="w-1/2 font-bold">{modified?.type}</div>
                    </div>
                    <CompareCodeEditor
                      modifiedQueryType={modified?.type}
                      modifiedValue={modified?.value}
                      originalQueryType={original?.type}
                      originalValue={original?.value}
                      originalFilters={original?.filters}
                      modifiedFilters={modified?.filters}
                    />
                  </div>
                ))}
              </Fragment>
            ))}
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default CompareQueriesModal

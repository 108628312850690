import { format, isValid, parseISO } from 'date-fns'

import { transformSearchlineForCodeEditor } from '../../../components/ProfilePage/Form/utils'
import { FormState, QueryObject } from '../../../components/ProfilePage/types'
import { getQueryWithPrefix } from '../../../components/ProfilePage/utilities'
import {
  LinemodeEnum,
  ProfileDetail,
  ProfileItem,
} from '../../../generated-types/types.schemas'
import { QueryType } from '../../../types/profile'
import parseFiltersToString from '../../../utilities/parseFiltersToString'

/**
 * Returns query type from searchmode parameter.
 * @param searchmode - The searchmode parameter.
 * @returns The query type.
 */
export const getQueryTypeFromSearchMode = (searchmode: string): QueryType => {
  if (searchmode === QueryType.IQL) {
    return QueryType.IQL
  }

  return QueryType.OQL
}

export const formatDate = (
  lastUpdatedAt: string | undefined | null,
  formatString = 'MMM d, yyyy HH:mm',
) => {
  if (!lastUpdatedAt) {
    return null
  }

  const parsedDate = parseISO(lastUpdatedAt)

  if (!isValid(parsedDate)) {
    throw new Error('Invalid date. You must pass a valid ISO date string.')
  }

  return format(parsedDate, formatString)
}

/**
 * Retrieves queries from the given profile data.
 *
 * @param {Array<SearchItem>} options.items - An array of SearchItem objects containing searchline, linemode, and id properties.
 * @return {Array<QueryObject>} An array of query objects, each containing type, linemode, id, and modified searchline properties.
 */
export const getQueries = ({
  items,
  profileId,
}: {
  items: Array<ProfileItem>
  profileId: number
}): {
  required: Array<QueryObject>
  exclude: Array<QueryObject>
  optional: Array<QueryObject>
} => {
  const queries = items?.map(({ searchline, linemode, id }, index) => {
    const queryId = index === 0 ? profileId : id

    return {
      type: getQueryTypeFromSearchMode(searchline?.searchmode || ''),
      linemode,
      id: queryId,
      searchline: transformSearchlineForCodeEditor(searchline || {}),
    }
  })

  const required =
    queries?.filter(({ linemode }) => linemode === LinemodeEnum.R) || []
  const exclude =
    queries?.filter(({ linemode }) => linemode === LinemodeEnum.E) || []
  const optional =
    queries?.filter(({ linemode }) => linemode === LinemodeEnum.O) || []

  return {
    required,
    exclude,
    optional,
  }
}

/**
 * Gets the default options for a ProfileForm based on the isNewProfile flag and profileData.
 * @param isNewProfile - Indicates whether it's a new profile.
 * @param profileData - The profile data.
 * @returns The default options object with profileName, parent, and query values.
 */
export const getDefaultValues = (
  profileData?: ProfileDetail,
): FormState | undefined => {
  if (!profileData) {
    return undefined
  }

  const queries = getQueries({
    items: profileData.items || [],
    profileId: profileData.id || 0,
  })

  const defaultValues: FormState = {
    profileName: profileData.name,
    parent: profileData.parent || 0,
    hasParent: profileData.parent !== 0,
    folder: profileData.folder || 0,
    oldest_article: profileData.oldest_article,
    persistent_profile: profileData.persistent_profile,
    persistent_grouping: profileData.persistent_grouping,
    blessed: profileData.blessed || false,
    queries,
    stored_search: profileData.stored_search,
    sm_rate: profileData.sm_rate || [],
  }

  return defaultValues
}

/**
 * Format the array of query objects into search items by modifying their structure.
 *
 * @param {Array<QueryObject>} queries - An array of query objects to be formatted.
 * @returns {Array<SearchItem>} A new array of restructured search items where the `type` property
 * is combined with the `searchterm` property within the `searchline` object.
 */
export const formatQueries = (
  queries: Array<QueryObject>,
): Array<ProfileItem> =>
  queries.map(({ type, linemode, searchline }) => {
    if (!searchline) {
      return {
        linemode,
        searchline: {
          searchterm: '',
          filters: [],
        },
      }
    }
    const { searchterm, filters } = searchline

    // Group filters into a single string and prepend it to the searchterm
    const groupedFiltersAsString = parseFiltersToString(filters)
    const finalSearchterm = `${groupedFiltersAsString} ${searchterm}`

    // If the query type is IQL, prepend the searchterm with 'IQL: '
    const newSearchterm = getQueryWithPrefix(finalSearchterm, type)

    return {
      linemode,
      searchline: {
        filters: [],
        searchterm: newSearchterm,
        searchmode: type === 'IQL' ? 'IQL' : undefined,
      },
    }
  })

import { useParams } from 'react-router-dom'

import parseProfileId from '../utilities/parseProfileId'

/**
Custom hook to extract the profile ID from the URL params using react-router-dom's useParams hook.
@returns Parsed profile ID as a number.
*/
const useProfileId = () => {
  const { profileId: profileIdString } = useParams()

  const profileId = parseProfileId(profileIdString)

  return profileId
}

export default useProfileId

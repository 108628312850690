import { useIdenticalArticlesContext } from '../context/identicalArticlesContext'
import { Document } from '../generated-types/types.schemas'
import getArticleId from '../utilities/getArticleId'

const useIdenticalArticles = (
  article: Document | undefined,
): {
  hasIdenticalArticles: boolean
  identicalArticles: Document[]
  activeArticle: Document
  activeArticleIndex: number
  setActiveArticleIndex: (index: number) => void
  setNextActiveArticle: () => void
  setPrevActiveArticle: () => void
} => {
  const { identicalArticlesActiveIndexes, setActiveIdenticalArticleIndex } =
    useIdenticalArticlesContext()

  if (!article) {
    return {
      hasIdenticalArticles: false,
      identicalArticles: [],
      activeArticle: {} as Document,
      activeArticleIndex: 0,
      setActiveArticleIndex: () => {},
      setNextActiveArticle: () => {},
      setPrevActiveArticle: () => {},
    }
  }

  const { identical_documents } = article

  const hasIdenticalArticles =
    !!identical_documents?.cnt && identical_documents.cnt > 0

  const identicalArticles = [
    article,
    ...(hasIdenticalArticles ? identical_documents.document : []),
  ]

  const activeArticleIndex =
    identicalArticlesActiveIndexes[getArticleId(article)] || 0

  const activeArticle = identicalArticles[activeArticleIndex]

  const setActiveArticleIndex = (index: number) => {
    setActiveIdenticalArticleIndex(getArticleId(article), index)
  }

  const setNextActiveArticle = () => {
    setActiveArticleIndex((activeArticleIndex + 1) % identicalArticles.length)
  }

  const setPrevActiveArticle = () => {
    setActiveArticleIndex(
      (activeArticleIndex - 1 + identicalArticles.length) %
        identicalArticles.length,
    )
  }

  return {
    hasIdenticalArticles,
    identicalArticles,
    activeArticleIndex,
    activeArticle,
    setActiveArticleIndex,
    setNextActiveArticle,
    setPrevActiveArticle,
  }
}

export default useIdenticalArticles

import { useOpointAuthState } from '@opoint/authjs-react'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useCustomAxiosInstance } from '../axios/instance'

type Tag = {
  id: number
  url: string
  combo: string
  name: string
  type: number
  visibility: number
  last_set: string
  color: string
  statistics: string
  score: number
  lastSort: string
  folder: number
  category: number
  owner: number
  access: {
    edit: boolean
    tag_articles: boolean
  }
}

export const getGetTagsQueryKey = (userId: number) => [`${userId}-tags`]

const useGetTags = (options?: UseQueryOptions<Tag[]>) => {
  const apiClient = useCustomAxiosInstance<Tag[]>()
  const authState = useOpointAuthState()

  const userId =
    authState?.user.impersonator?.user_id ?? authState?.user?.user_id

  if (!userId) {
    throw new Error('User ID is required')
  }

  const queryKey = getGetTagsQueryKey(userId)

  return useQuery<Tag[]>({
    queryKey,
    queryFn: () => apiClient({ url: '/tags/', method: 'get' }),
    refetchOnWindowFocus: false,
    ...options,
  })
}

export default useGetTags

import { useToast } from '@opoint/infomedia-storybook'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useCustomAxiosInstance } from '../axios/instance'
import { ProfileHistory } from '../types'
import useGetProfile, { getGetProfileQueryKey } from './useGetProfile'
import { getProfileHistoryQueryKey } from './useGetProfileHistory'
import useProfileId from './useProfileId'

const useDeleteProfileHistory = () => {
  const queryClient = useQueryClient()
  const apiClient = useCustomAxiosInstance<ProfileHistory>()
  const profileId = useProfileId()
  const { toast } = useToast()

  if (!profileId) {
    throw new Error('profileId is required')
  }

  const profile = useGetProfile({ profileId })
  const profileHistoryQueryKey = getProfileHistoryQueryKey(profileId, '')
  const profileQueryKeyWithQuery = getGetProfileQueryKey(profileId, true)
  const profileQueryKeyWithoutQuery = getGetProfileQueryKey(profileId, false)

  return useMutation(
    (timestamp: number | undefined) =>
      apiClient({
        method: 'delete',
        url: `/profiles/${profileId}/${timestamp}/`,
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Revision was successfully deleted.',
          variant: 'success',
        })

        void queryClient.invalidateQueries(profile)
        void queryClient.invalidateQueries(profileHistoryQueryKey)
        void queryClient.invalidateQueries(profileQueryKeyWithQuery)
        void queryClient.invalidateQueries(profileQueryKeyWithoutQuery)
      },
      onError: () =>
        toast({
          title: 'Something went wrong. Please try again.',
          variant: 'destructive',
        }),
    },
  )
}

export default useDeleteProfileHistory

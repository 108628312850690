import {
  LinemodeEnum,
  SearchRequestBodyExpressionsSearchlineObjectExpressionsItem,
} from '../generated-types/types.schemas'
import { FilterType } from '../types/profile'

import useProfileId from './useProfileId'

const useProfileIdSearchItem = ():
  | SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[]
  | undefined => {
  const profileId = useProfileId()

  if (!profileId) {
    return undefined
  }

  return [
    {
      linemode: LinemodeEnum.R,
      searchline: {
        filters: [
          {
            id: profileId.toString(),
            type: 'profile' as FilterType,
          },
        ],
      },
    },
  ]
}

export default useProfileIdSearchItem

import { isEmpty } from 'lodash-es'
import React, { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Add20Regular, ChevronRight20Regular } from '@fluentui/react-icons'
import { Button, Loader, cn } from '@opoint/infomedia-storybook'
import useGetFolders from '../../../hooks/useGetFolders'
import useGetProfiles from '../../../hooks/useGetProfiles'
import useImpersonate from '../../../hooks/useImpersonate'
import { ProfileListWithChildren } from '../../../types/profile'
import useFilterByKey from '../../../hooks/useFilterByKey'
import ManageProfilesModal from '../../../components/ManageProfilesModal'
import { getProfilesWithChildren, getTotalProfilesCount } from './utilities'

type ProfilesListProps = {
  searchTerm: string
  userId: number
  isLoadingImpersonation?: boolean
}

const initialPadding = 3.5

const SubProfileList = ({
  profiles,
  userId,
}: {
  profiles: ProfileListWithChildren[]
  userId: number
}) => {
  const { handleImpersonate } = useImpersonate()
  const navigate = useNavigate()

  const maybeImpersonateProfileOwner = (
    e: React.MouseEvent<HTMLAnchorElement>,
    id: number,
  ) => {
    const profile = profiles.find((profile) => profile.id === id)
    if (profile?.owner !== userId) {
      e.preventDefault()
      handleImpersonate({
        userId: profile?.owner,
        onSuccess: () => {
          navigate(`/profiles/${id}/?user_id=${userId}`)
        },
      })
    }
  }

  return profiles.map(({ id, name, childrenProfiles, depth }) => (
    <div key={id}>
      <Link
        className="block border-b border-grey.6 py-4 hover:bg-sky.8"
        onClick={(e) => {
          maybeImpersonateProfileOwner(e, id)
        }}
        style={{
          paddingLeft: `${initialPadding + depth - 1}rem`,
        }}
        to={`/profiles/${id}/?user_id=${userId}`}
      >
        {name}
      </Link>
      <div>
        {childrenProfiles && !isEmpty(childrenProfiles) && (
          <SubProfileList profiles={childrenProfiles} userId={userId} />
        )}
      </div>
    </div>
  ))
}

const ProfilesList = ({
  searchTerm,
  userId,
  isLoadingImpersonation,
}: ProfilesListProps) => {
  const { data: profiles = [], isLoading: isProfilesLoading } = useGetProfiles({
    userId,
  })

  const { data: folders = [], isLoading: isFoldersLoading } = useGetFolders({
    userId,
  })

  const customFolders = folders.filter((folder) => folder.type === 0)

  const filteredProfiles = useFilterByKey({
    array: profiles,
    searchTerm,
    key: 'name',
  })

  const customFoldersWithProfiles = useMemo(
    () =>
      customFolders.map((folder) => {
        const profiles = filteredProfiles.filter(
          (profile) =>
            profile.category === folder.category &&
            profile.type === folder.type,
        )
        return {
          ...folder,
          profiles: getProfilesWithChildren(profiles),
        }
      }),
    [filteredProfiles, customFolders],
  )

  const isLoading =
    isLoadingImpersonation || isProfilesLoading || isFoldersLoading

  const emptyProfiles = isEmpty(profiles)

  const navigate = useNavigate()

  const handleCreateProfile = () => {
    navigate('/profiles/new')
  }

  const [collapsedIds, setCollapsedIds] = useState<Record<number, boolean>>({})

  const toggleCollapse = (id: number) => {
    setCollapsedIds((prevCollapsedIds) => ({
      ...prevCollapsedIds,
      [id]: !prevCollapsedIds[id],
    }))
  }

  if (isLoading) {
    return (
      <div className="flex size-full items-center justify-center">
        <Loader />
      </div>
    )
  }

  return (
    <div>
      <div className="flex justify-end pe-6 pt-4">
        <Button onClick={handleCreateProfile} type="button" variant="outline">
          <Add20Regular />
          Create profile
        </Button>
      </div>
      {emptyProfiles ? (
        <div className="my-6 flex size-full items-center justify-center">
          No profiles found
        </div>
      ) : (
        <div className="m-6 mt-2 rounded-md border border-b-0 border-grey.6">
          {customFoldersWithProfiles.map((folder) => (
            <div key={folder.id}>
              <div
                className="flex cursor-pointer items-center justify-between border-b border-grey.6 py-4 hover:bg-sky.8"
                onClick={() => {
                  toggleCollapse(folder.id)
                }}
              >
                <span className="flex ps-4 font-bold">
                  <ChevronRight20Regular
                    className={cn('w-6 mr-4 transition-transform', {
                      'transform rotate-90': !collapsedIds[folder.id],
                    })}
                  />

                  <span>
                    {folder.name} ({getTotalProfilesCount(folder.profiles)})
                  </span>
                </span>
                <div
                  onClick={(e) => {
                    // Prevent opening/closing the folder
                    e.stopPropagation()
                  }}
                >
                  {searchTerm === '' && !isEmpty(folder.profiles) && (
                    <ManageProfilesModal
                      profiles={folder.profiles}
                      userId={userId}
                      title={folder.name}
                    />
                  )}
                </div>
              </div>
              <div
                className={cn('overflow-hidden	', {
                  'max-h-0': collapsedIds[folder.id],
                })}
              >
                {isEmpty(folder.profiles) ? (
                  <div className="border-b border-grey.6 p-4 text-center">
                    No profiles found
                  </div>
                ) : (
                  <SubProfileList profiles={folder.profiles} userId={userId} />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ProfilesList

/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useOpointAuthState } from '@opoint/authjs-react'
import {
  Button,
  Checkbox,
  Input,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Combobox,
  ComboboxContent,
  ComboboxItem,
  ComboboxTrigger,
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from '@opoint/infomedia-storybook'
import { format, fromUnixTime } from 'date-fns'
import { ArrowSync20Regular, Eye20Regular } from '@fluentui/react-icons'
import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useForm, FormProvider } from 'react-hook-form'

import {
  ProfileDetail,
  ProfileItem,
  SearchRequestBodyExpressionsSearchlineObjectExpressionsItem,
} from '../../../generated-types/types.schemas'
import useGetFolders from '../../../hooks/useGetFolders'
import useGetProfiles from '../../../hooks/useGetProfiles'
import useProfileId from '../../../hooks/useProfileId'
import useProfileIdSearchItem from '../../../hooks/useProfileIdSearchItem'
import ProfileInformation from '../../../pages/AuthPages/UpdateProfilePage/ProfileInformation'
import { formatQueries } from '../../../pages/AuthPages/UpdateProfilePage/utilities'
import ArticlesPreview from '../../ArticlesPreview'
import { FormState } from '../types'

import useProfileSearchImpact from '../../../hooks/useProfileSearchImpact'
import CompareOptionsModal, { CompareTarget } from './CompareOptionsModal'
import CompareQueriesModal from './CompareQueriesModal'
import SearchLines from './SearchLines'
import { filterProfileDescendants, getListIdExpressions } from './utils'
import PersistProfileHistoryModal from './PersistProfileHistoryModal'
import SoMeRatesModal from './SoMeRatesModal'

export const PREVIEW_BUTTON_PORTAL_ID = 'preview-button-portal'
export const COMPARE_QUERIES_BUTTON_PORTAL_ID = 'compare-queries-button-portal'

type Props = {
  isNewProfile?: boolean
  onSubmit: (data: FormState) => void
  defaultValues: FormState
  formId?: string
  profileData?: ProfileDetail
}

const ProfilePage = ({
  isNewProfile,
  onSubmit,
  formId = '',
  defaultValues,
  profileData,
}: Props) => {
  const form = useForm<FormState>({
    defaultValues,
  })

  const { handleSubmit, reset, getValues, watch, setValue } = form

  const [isCompareQueriesModalOpen, setIsCompareQueriesModalOpen] =
    useState(false)
  const [compareTargetLabel, setCompareTargetLabel] =
    useState('Published version')

  const authState = useOpointAuthState()

  const hasParent = watch('hasParent')
  const folderIdSelected = watch('folder')
  const hasStoredSearch = watch('stored_search')

  const { data: profiles = [] } = useGetProfiles({
    userId: authState?.user.impersonator?.user_id ?? authState?.user.user_id,
  })

  const { data: folders = [] } = useGetFolders({
    userId: authState?.user.impersonator?.user_id ?? authState?.user.user_id,
  })

  const customFolders = folders
    .filter((folder) => folder.type === 0)
    .map((folder) => ({
      label: folder.name,
      value: folder.id,
    }))

  // set default Profile folder for new profile
  useEffect(() => {
    if (!folderIdSelected && folders.length > 0) {
      setValue(
        'folder',
        folders.filter(
          (folder) => folder.type === 0 && folder.category === 0,
        )[0].id,
      )
    }
  }, [folders, folderIdSelected, setValue])

  const profileId = useProfileId()
  const [parentProfileSearchterm, setParentProfileSearchterm] = useState('')

  // Get all possible parents for the current profile
  const possibleParents =
    profileId === null || isNewProfile
      ? profiles.filter((profile) => profile.id !== profileId)
      : filterProfileDescendants(profiles, profileId)

  // Transform the possible parents to select options
  const parentSelectOptions = possibleParents
    .filter((profile) => profile.folder === folderIdSelected)
    .map((profile) => ({
      label: profile.name,
      value: profile.id,
    }))

  const filteredParentOptions = useMemo(() => {
    if (!parentProfileSearchterm) {
      return parentSelectOptions
    }

    return parentSelectOptions.filter(({ label }) =>
      label.toLowerCase().includes(parentProfileSearchterm.toLowerCase()),
    )
  }, [parentSelectOptions, parentProfileSearchterm])

  const { required, optional, exclude } = getValues('queries')

  const [sourceExpressions, setSourceExpressions] = useState<
    | ProfileItem[]
    | SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[]
  >(formatQueries([...required, ...optional, ...exclude]))

  const isAdmin = authState?.user.privileges_index === 2

  // Expression with profile id
  const profileIdSearchItem = useProfileIdSearchItem()
  // Default target is the published version
  const [targetExpressions, setTargetExpressions] = useState<
    SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[] | undefined
  >(profileIdSearchItem)

  const handleSetSourceExpressions = (useProfileId: boolean) => {
    const expressions =
      useProfileId && profileIdSearchItem
        ? profileIdSearchItem
        : formatQueries([...required, ...optional, ...exclude])

    setSourceExpressions(expressions)
  }

  const handleTargetExpressions = (compareTarget: CompareTarget) => {
    if (compareTarget.mode === 'profileHistory') {
      if (compareTarget.published) {
        setCompareTargetLabel('Published version')
        setTargetExpressions(profileIdSearchItem)
      } else {
        setCompareTargetLabel(
          compareTarget.history?.timestamp
            ? `Previous version (${format(
                fromUnixTime(compareTarget.history.timestamp),
                'MMM d, H:mm',
              )})`
            : 'Previous version',
        )

        //@ts-ignore
        setTargetExpressions(compareTarget.history?.items)
      }
      handleSetSourceExpressions(false)

      return
    }

    if (compareTarget.mode === 'topicBuilder') {
      setCompareTargetLabel(
        `Topic builder list id: ${compareTarget.topicBuilderListId}`,
      )
      setTargetExpressions(
        getListIdExpressions(compareTarget.topicBuilderListId),
      )
      handleSetSourceExpressions(false)

      return
    }

    if (compareTarget.mode === 'persistedProfile' && profileData?.ifm_profile) {
      setCompareTargetLabel('Persistent Profile vs. Mirrored TB list')
      setTargetExpressions(getListIdExpressions(profileData.ifm_profile))
      handleSetSourceExpressions(true)

      return
    }

    if (compareTarget.mode === 'mirroredList' && profileData?.ifm_profile) {
      setCompareTargetLabel('Mirrored TB list')
      setTargetExpressions(getListIdExpressions(profileData.ifm_profile))
      handleSetSourceExpressions(false)

      return
    }

    setTargetExpressions(profileIdSearchItem)
  }

  const oldestArticle = watch('oldest_article')
  const persistentProfile = watch('persistent_profile')

  const profileHistoryLabel =
    persistentProfile !== 0 && oldestArticle
      ? `From ${format(fromUnixTime(oldestArticle), 'd.M.yyyy')}`
      : 'Full'

  const { shouldBlockSearch } = useProfileSearchImpact(profileData)

  const previewButtonPortalTargetElement = document.getElementById(
    PREVIEW_BUTTON_PORTAL_ID,
  )
  const previewButtonPortal = previewButtonPortalTargetElement
    ? createPortal(
        <Button
          className="me-2"
          onClick={() => {
            handleSetSourceExpressions(
              compareTargetLabel === 'Persisted profile',
            )
          }}
          type="button"
          variant="outline"
          disabled={shouldBlockSearch}
        >
          <Eye20Regular />
          Update preview
        </Button>,
        previewButtonPortalTargetElement,
      )
    : null

  const compareQueriesButtonPortalTargetElement = document.getElementById(
    COMPARE_QUERIES_BUTTON_PORTAL_ID,
  )
  const compareQueriesButtonPortal = compareQueriesButtonPortalTargetElement
    ? createPortal(
        <Button
          className="me-2"
          onClick={() => {
            setIsCompareQueriesModalOpen(true)
          }}
          type="button"
          variant="outline"
        >
          <ArrowSync20Regular />
          Compare queries
        </Button>,
        compareQueriesButtonPortalTargetElement,
      )
    : null

  const handleResetValuesAndRefetchArticles = (
    queries: FormState['queries'],
  ) => {
    // Reset form values
    reset({ queries })
    // Refetch articles
    setSourceExpressions(
      formatQueries([
        ...queries.required,
        ...queries.optional,
        ...queries.exclude,
      ]),
    )
  }

  const sm_rate = watch('sm_rate')
  const hasSmRate = sm_rate !== undefined && sm_rate.length > 0

  return (
    <Form {...form}>
      <form
        className="h-full"
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="block h-auto grid-cols-2 md:grid md:h-[calc(100vh-117px)] 2xl:grid-cols-35-65">
          <div className="h-auto border-r border-grey.6 p-3 md:overflow-y-auto">
            <div className="flex flex-col justify-between gap-3 lg:flex-row">
              <div className="flex min-w-[40%] flex-col gap-2">
                <FormField
                  control={form.control}
                  name="profileName"
                  rules={{
                    required: 'Profile name can not be empty',
                  }}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Profile name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
                <FormField
                  control={form.control}
                  name="folder"
                  render={({ field: { onChange, value } }) => (
                    // Select gets rendered under the editor,
                    // so we need to set the z-index to 1 and position to relative
                    <FormItem className="relative z-10">
                      <FormLabel>Folder</FormLabel>
                      <FormControl>
                        <Select
                          onChange={(value) => {
                            onChange(value)
                            setValue('parent', 0)
                          }}
                          value={`${value}`}
                        >
                          <SelectTrigger
                            placeholder="Select Folder"
                            displayValue={(value) =>
                              customFolders.find(
                                (item) => `${item.value}` === value,
                              )?.label ?? ''
                            }
                          />
                          <SelectContent>
                            {customFolders.map((folder) => (
                              <SelectItem
                                key={folder.value}
                                value={`${folder.value}`}
                              >
                                {folder.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="hasParent"
                  render={({ field }) => {
                    return (
                      <FormItem className="flex items-center gap-2">
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel className="!mt-0">
                          Add as sub profile
                        </FormLabel>
                        <FormMessage />
                      </FormItem>
                    )
                  }}
                />
                {hasParent && (
                  <FormField
                    control={form.control}
                    name="parent"
                    render={({ field: { onChange, value } }) => (
                      // Select gets rendered under the editor,
                      // so we need to set the z-index to 1 and position to relative
                      <FormItem style={{ zIndex: 1, position: 'relative' }}>
                        <FormControl>
                          <Combobox onChange={onChange} value={`${value}`}>
                            <ComboboxTrigger
                              placeholder="Select parent profile"
                              onQueryChange={setParentProfileSearchterm}
                              displayValue={(value) =>
                                parentSelectOptions.find(
                                  (item) => `${item.value}` === value,
                                )?.label ?? ''
                              }
                            />
                            <ComboboxContent>
                              {filteredParentOptions.map((option) => (
                                <ComboboxItem
                                  key={option.value}
                                  value={`${option.value}`}
                                >
                                  {option.label}
                                </ComboboxItem>
                              ))}
                            </ComboboxContent>
                          </Combobox>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                )}
                {isAdmin && (
                  <FormField
                    control={form.control}
                    name="blessed"
                    render={({ field }) => {
                      return (
                        <FormItem className="flex items-center gap-2">
                          <FormControl>
                            <Checkbox
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                          <FormLabel className="!mt-0">
                            Enable Blessed content
                          </FormLabel>
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />
                )}
                {hasStoredSearch && (
                  <FormField
                    control={form.control}
                    name="stored_search"
                    render={({ field }) => {
                      const isChecked = field.value?.active === 1

                      return (
                        <FormItem className="flex items-center gap-2">
                          <FormControl>
                            <Checkbox
                              checked={isChecked}
                              onCheckedChange={() => {
                                field.onChange({
                                  ...field.value,
                                  active: isChecked ? 0 : 1,
                                })
                              }}
                            />
                          </FormControl>
                          <FormLabel className="!mt-0">
                            Stored search: {field.value?.id}
                          </FormLabel>
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />
                )}

                {hasSmRate && (
                  <FormProvider {...form}>
                    <SoMeRatesModal />
                  </FormProvider>
                )}
              </div>

              <FormProvider {...form}>
                {/* on Create profile page */}
                {isNewProfile && (
                  <div className="w-full min-w-[60%] ">
                    <ProfileInformation
                      status="Draft"
                      onResetFormValue={handleResetValuesAndRefetchArticles}
                      profileHistoryModal={
                        <PersistProfileHistoryModal
                          label={profileHistoryLabel}
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          submit={handleSubmit(onSubmit)}
                        />
                      }
                    />
                  </div>
                )}
                {/* on Update profile page */}
                {!isNewProfile && (
                  <div className="w-full">
                    <ProfileInformation
                      status="Published"
                      lastUpdatedAt={profileData?.last_set}
                      revisions={profileData?.revisions}
                      onResetFormValue={handleResetValuesAndRefetchArticles}
                      compareOptionsModal={
                        <CompareOptionsModal
                          onChange={handleTargetExpressions}
                          targetLabel={compareTargetLabel}
                          isMirroredListIdAvailable={!!profileData?.ifm_profile}
                        />
                      }
                      profileHistoryModal={
                        <PersistProfileHistoryModal
                          label={profileHistoryLabel}
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          submit={handleSubmit(onSubmit)}
                        />
                      }
                    />
                  </div>
                )}
              </FormProvider>
            </div>
            <FormProvider {...form}>
              <SearchLines lineMode="R" />
              <SearchLines lineMode="O" />
              <SearchLines lineMode="E" />
            </FormProvider>
          </div>
          <div className="flex-auto">
            {previewButtonPortal}
            <ArticlesPreview
              sourceExpressions={sourceExpressions}
              targetExpressions={targetExpressions}
              profileData={profileData}
            />
            {compareQueriesButtonPortal}
            <CompareQueriesModal
              draftQuery={[...required, ...optional, ...exclude]}
              isShown={isCompareQueriesModalOpen}
              onHide={() => {
                setIsCompareQueriesModalOpen(false)
              }}
              setDraftQuery={handleResetValuesAndRefetchArticles}
            />
          </div>
        </div>
      </form>
    </Form>
  )
}

export default ProfilePage

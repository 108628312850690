import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
} from '@opoint/infomedia-storybook'

type Props = {
  isShown: boolean
  onHide: () => void
  onConfirm: () => void
}

const UnpersistConfirmationModal = ({ isShown, onHide, onConfirm }: Props) => {
  return (
    <AlertDialog
      open={isShown}
      onOpenChange={() => {
        onHide()
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you wish to unpersist the profile?
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button type="button" variant="outline">
              Cancel
            </Button>
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              onClick={() => {
                onConfirm()
              }}
              type="button"
            >
              Unpersist
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default UnpersistConfirmationModal

import axios, { AxiosRequestConfig } from 'axios'
import { getRequestHeaders } from '../configuration/auth'
const isVercel = import.meta.env.VITE_VERCEL as boolean
const API_BASE_URL = isVercel
  ? (import.meta.env.VITE_VERCEL_API_URL as string)
  : (import.meta.env.VITE_API_URL as string)
export const AXIOS_INSTANCE = axios.create({ baseURL: API_BASE_URL })
export const instance = async <T>(
  axiosConfig: AxiosRequestConfig,
): Promise<T> => {
  const headers = await getRequestHeaders().then((data) => data.headers)

  return AXIOS_INSTANCE<T>({
    ...axiosConfig,
    headers: {
      ...axiosConfig.headers,
      ...headers,
    },
  }).then(({ data }) => data)
}

/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Opoint API
 * OpenAPI spec version: 1.0.0
 */
export type UsersImpersonationTargetsListParams = {
  /**
   * Exclude self targets from results.
   */
  exclude_self?: boolean
  /**
   * Only return results with user ids above it. Used for pagination.
   */
  last_id_user?: number
  /**
   * Only return results that are self targets.
   */
  only_self?: boolean
  /**
   * Number of results to return
   */
  page_size?: number
  /**
   * Filter results based on search term.
   */
  search?: string
}

export type TagsReportsListParams = {
  /**
   * Limit.
   */
  limit?: number
  /**
   * Offset.
   */
  offset?: number
}

export type TagsListParams = {
  /**
   * Which field to use when ordering the results.
   */
  ordering?: string
}

export type ProfilesDestroyParams = {
  /**
   * Force delete (1 - force delete, anything else - don't force delete)
   */
  force?: number
}

export type ProfilesRetrieveParams = {
  /**
   * Add history data to the response
   */
  with_history?: boolean
  /**
   * Convert searchline string to filters
   */
  with_query?: boolean
}

export type NotificationsListParams = {
  /**
   * Notifications with id greater than from_id
   */
  from_id?: number
  /**
   * Page number
   */
  page?: number
  /**
   * Page size
   */
  page_size?: number
}

export type FoldersSharesTargetsSearchListParams = {
  /**
   * Maximum number of results to return
   */
  page_size?: number
  /**
   * Search term
   */
  q: string
}

export type CustomersSearchListParams = {
  /**
   * Maximum number of results to return
   */
  page_size?: number
  /**
   * Search term
   */
  q: string
}

export type AnalyticsReportsStatisticsRetrieveParams = {
  endDate: string
  startDate: string
}

export type SearchBody =
  | SearchRequestBodySearchtermOnly
  | SearchRequestBodyExpressionsSearchlineString
  | SearchRequestBodyExpressionsSearchlineObject

export type Updated = {
  updated: number
}

export type UserTypeField = {
  value: number
  name: string
}

export type UUIDCommaSeparatedList = {
  id: string
  name: string
}

export type TagsArticle = {
  id_site: number
  id_article: number
  stimestamp: number
  matchinfo?: string
}

export type TaggedArticleDetectionResponseArticles = {
  id_site: number
  id_article: number
  weight: number
}

export type TaggedArticleDetectionResponse = {
  id_basket: number
  articles: TaggedArticleDetectionResponseArticles[]
}

export type TaggedArticleDetection = {
  baskets: number[]
  articles: ArticleVisited[]
}

export type TaggedArticle = {
  has_articles: boolean
}

export type TagSort = {
  tagId: number
  position: number
}

/**
 * * `tagged` - tagged
 * `used` - used
 */
export type TagReportSelectionTypeEnum =
  (typeof TagReportSelectionTypeEnum)[keyof typeof TagReportSelectionTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TagReportSelectionTypeEnum = {
  tagged: 'tagged',
  used: 'used',
} as const

/**
 * Ways of selecting content from tags for reports.

Represented by a tagged union choosing between ways of selecting content,
either from an old report or all the unused content, or only some of the
unused articles.
 */
export type TagReportSelection = {
  type: TagReportSelectionTypeEnum
  timestamp?: number
  from_timestamp?: number
  to_timestamp?: number
}

export type TagReportTimestamp = number | string

export type TagReport = {
  selection: TagReportSelection
  count: number
  readonly timestamp: TagReportTimestamp
  stimestampUsed: number
  readonly timestampLastArt: number
}

export type TagDetailStoredSearch = StoredSearchField | null

export type TagDetailAccess = {
  readonly edit: boolean
  readonly tag_articles: boolean
}

export type TagDetail = {
  readonly id: number
  readonly url: string
  combo?: string | null
  /** Name of tag */
  name: string
  type?: OneTwoEnum
  visibility?: OneTwoEnum
  readonly last_set: string
  color: string | null
  statistics?: string | null
  readonly feeds: UUIDCommaSeparatedList
  readonly score: number
  readonly lastSort: string
  folder?: number
  readonly category: number
  readonly owner: number
  readonly access: TagDetailAccess
  stored_search?: TagDetailStoredSearch
  readonly new_position: number
}

export type SupportIssue = {
  id_site: number
  id_article: number
  description: string
  translated_headline: string
  original_headline: string
  publication_date: number
  source: string
  country: string
  context_type: string
  context_name: string
  context_id: number
  email?: string
  issue_type: IssueTypeEnum[]
}

export type StoredSearchField = {
  id: number
  art_limit: number
  active: number
}

/**
 * * `priority` - priority
 * `date` - date
 */
export type SortTypeEnum = (typeof SortTypeEnum)[keyof typeof SortTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortTypeEnum = {
  priority: 'priority',
  date: 'date',
} as const

/**
 * * `desc` - desc
 * `asc` - asc
 */
export type SortOrderEnum = (typeof SortOrderEnum)[keyof typeof SortOrderEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortOrderEnum = {
  desc: 'desc',
  asc: 'asc',
} as const

export type SortTagContent = {
  stimestampUsed?: number[]
  fromTimestamp?: number
  toTimestamp?: number
  sortType?: SortTypeEnum
  sortOrder?: SortOrderEnum
}

export type Response = {
  token: string
}

export type ReportStatistics = {
  id: number
  name: string
  category: string
  unprocessed: number
  uncalculated: number
}

export type Report = {
  id: number
  readonly download: string
  title: string
  readonly status: number
  timeFinished: number
  readonly type: number
  readonly articles: number
  readonly timestampFrom: number
  readonly timestampTo: number
  pdfPages: number
  pdfBytes: number
}

export type ProfileWatchResponse = {
  username: string
}

export type ProfileWatchRequest = {
  day?: number
  from_day?: number
  readership?: boolean
}

export type ProfileUnpersistResponse = {
  message: string
}

export type ProfileSort = {
  parent: number
  profileId: number
  position?: number
}

export type ProfileItem = {
  id?: number
  searchline: {
    searchmode?: string
    searchterm?: string
    filters: {
      id?: string
      type?: string
      name?: string
    }[]
  }
  linemode: LinemodeEnum
}

export type ProfileHistoryItemsResponse = {
  id_list: number
  name: string
  make_persistent: boolean | null
  items: ProfileItem[]
  timestamp: number
}

export type ProfileHistoryResponse = {
  count: number
  results: ProfileHistoryItemsResponse[]
}

export type ProfileDetailStoredSearch = StoredSearchField | null

export type ProfileDetail = {
  readonly url: string
  id?: number
  name: string
  parent?: number
  items?: ProfileItem[]
  statistics?: string | null
  blessed?: boolean
  sm_rate?: MifluzRate[]
  readonly sm_phrases: string
  stored_search?: ProfileDetailStoredSearch
  folder?: number
  readonly category: number
  readonly type: number
  readonly owner: number
  make_persistent?: boolean | null
  readonly persistent_profile: number | null
  revisions?: number
  last_set?: string
  // These 2 Need to be added to schema - will link a story to this comment
  persistent_backfill_start?: number
  persistent_grouping: boolean
  oldest_article?: number
  ifm_profile?: number
  est_search_impact?: number
}

export type ProfileDependenciesResponse = {
  profiles: number[]
  alerts: AlertProfileDependencies[]
  feeds: FeedProfileDependencies[]
  parent: boolean
}

export type Profile = {
  readonly url: string
  id?: number
  name: string
  parent?: number
  readonly filtersUsed: string
  statistics?: string | null
  readonly feeds: UUIDCommaSeparatedList
  readonly score: number
  readonly hasTrash: boolean
  folder?: number
  readonly category: number
  readonly type: number
  readonly owner: number
  blessed?: string
}

export type PatchedTagDetailStoredSearch = StoredSearchField | null

export type PatchedTagDetailAccess = {
  readonly edit: boolean
  readonly tag_articles: boolean
}

export type PatchedTagDetail = {
  readonly id?: number
  readonly url?: string
  combo?: string | null
  /** Name of tag */
  name?: string
  type?: OneTwoEnum
  visibility?: OneTwoEnum
  readonly last_set?: string
  color?: string | null
  statistics?: string | null
  readonly feeds?: UUIDCommaSeparatedList
  readonly score?: number
  readonly lastSort?: string
  folder?: number
  readonly category?: number
  readonly owner?: number
  readonly access?: PatchedTagDetailAccess
  stored_search?: PatchedTagDetailStoredSearch
  readonly new_position?: number
}

export type PatchedNotificationObject = NotificationFieldValue | null

export type PatchedNotification = {
  readonly id?: number
  title?: string
  read?: boolean
  object?: PatchedNotificationObject
}

export type PatchedCustomerDetail = {
  readonly id?: number
  /** Customers name */
  name?: string
  partner?: number
  /** Customers active status. When the customer is inactive, users can no longer login. Currently not enforced */
  is_active?: boolean
  /** Whether customer is an educational institution */
  is_educational?: boolean
  max_num_users?: number | null
  /** Customers salesforce id */
  navision_id?: string
  /** Number of users the customer has signed up */
  readonly num_users?: number
}

export type OpointUser = {
  readonly id_user: number
  /** Id from external Identity Provider */
  external_id?: string
  username: string
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean
  owner?: number
  customer: string
  user_type?: UserTypeField
  display_name?: string | null
}

export type OpointCustomer = {
  readonly id_customer: number
  /** Customers name */
  name: string
  partner: number
}

/**
 * * `1` - 1
 * `2` - 2
 */
export type OneTwoEnum = (typeof OneTwoEnum)[keyof typeof OneTwoEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OneTwoEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const

export type OTBRule = {
  id_rule: number
  startvalue: number
  header?: string
  description?: string
}

export type OTBList = {
  id_rule: number
  startvalue: number
  header?: string
  description?: string
}

export type NotificationFieldReportValue = Report | null

/**
 * Single purpose serializer: Servers only to correctly describe a NotificationRelatedField field
where the value is ReportSerializer.
 */
export type NotificationFieldReport = {
  type: string
  value: NotificationFieldReportValue
}

export type NotificationFieldValue =
  | NotificationFieldExport
  | NotificationFieldReport

export type NotificationFieldExportValue = Export | null

/**
 * Single purpose serializer: Servers only to correctly describe a NotificationRelatedField field
where the value is ExportSerializer.
 */
export type NotificationFieldExport = {
  type: string
  value: NotificationFieldExportValue
}

export type NotificationObject = NotificationFieldValue | null

export type Notification = {
  readonly id: number
  title: string
  read?: boolean
  object?: NotificationObject
}

export type MifluzRate = {
  feed_id: string
  readonly feed_name: string
  maxrate: number
  readonly actual_rate: string
  readonly rate_used: string
}

export type MapIFMProfilesResponse = {
  IFMProfileName: number
}

/**
 * Serves for describing the /notifications response. forced_singular_serializer can't work with inline_serializer
 */
export type ListNotifications = {
  count: number
  next: string | null
  previous: string | null
  results: Notification[]
}

/**
 * * `R` - Required
 * `O` - Optional
 * `E` - Exclude
 */
export type LinemodeEnum = (typeof LinemodeEnum)[keyof typeof LinemodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinemodeEnum = {
  R: 'R',
  O: 'O',
  E: 'E',
} as const

export type LastSortedResponse = {
  unixTimestamp: number | null
  timestamp: string
}

/**
 * * `1` - Factual mistake
 * `2` - Translation error
 * `3` - Metadata error
 * `4` - Other issue
 */
export type IssueTypeEnum = (typeof IssueTypeEnum)[keyof typeof IssueTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IssueTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const

export type ImpersonationTarget = {
  /** User id of impersonation target. */
  id_user: number
  /** Username of impersonation target. */
  username: string
  /** If the target is of self type, meaning it represents the logged in user in another context. */
  self: boolean
  customer: OpointCustomer
}

export type IFMOrganizationProfileNumbers = {
  profiles: number[]
}

export type IFMLoginUser = {
  uuid: string
  login: string
  name?: string
  create_date: number
  archive_length: number
  profiles: number[]
}

export type IFMLoginRights = {
  is_organization_admin: boolean
  media_report: boolean
  alerts: boolean
  multimedia: boolean
  statistics: boolean
  print: boolean
  share: boolean
}

export type IFMLoginOrganization = {
  uuid: string
  debitor_id: number
  name: string
}

export type IFMLogin = {
  user: IFMLoginUser
  organization: IFMLoginOrganization
  rights: IFMLoginRights
  remember_me?: boolean
}

export type FolderSortRequestBody = {
  /** Id of folder to change sort position of. */
  id: number
  /** Desired position of folder, 0-based. */
  position: number
}

export type FeedProfileDependencies = {
  id: number
  name: string
}

/**
 * Serializer for returning statistics export data from notifications
 */
export type Export = {
  id: number
  readonly download: string
  title: string
  readonly status: number
  timeFinished: number
  readonly type: number
  readonly articles: number
  readonly timestampFrom: number
  readonly timestampTo: number
}

export type DeletedProfilesItemsResponse = {
  id_list: number
  name: string
  make_persistent: boolean | null
  items: ProfileItem
  timestamp: number
}

export type DeletedProfilesResponse = {
  count: number
  results: DeletedProfilesItemsResponse
}

export type DeleteTagResponse = {
  data: string
  message: string
}

export type DeleteProfileResponse400 = {
  data: string
  message: string
}

export type CustomerDetail = {
  readonly id: number
  /** Customers name */
  name: string
  partner: number
  /** Customers active status. When the customer is inactive, users can no longer login. Currently not enforced */
  is_active?: boolean
  /** Whether customer is an educational institution */
  is_educational?: boolean
  max_num_users: number | null
  /** Customers salesforce id */
  navision_id?: string
  /** Number of users the customer has signed up */
  readonly num_users: number
}

export type Customer = {
  readonly id: number
  /** Customers name */
  name: string
  partner: number
}

export type BaseArticle = {
  id_site: number
  id_article: number
}

export type AsyncReportCreateResponse = {
  report_id: number
  new_timestamp?: number
}

export type Articles = {
  articles: Article[]
}

export type ArticleVisited = {
  id_site: number
  id_article: number
  action?: ActionEnum[]
}

export type ArticleBasketDelete = {
  articles: BaseArticle[]
}

export type ArticleBasket = {
  weight: number
  articles: TagsArticle[]
}

export type Article = {
  id_site: number
  id_article: number
  xmltext?: string
  stimestamp?: number
  matchinfo?: string
}

export type AlertProfileDependencies = {
  id: number
  name: string
}

export type AlertCountResponse = {
  count: number
}

/**
 * * `2` - Article consumed
 * `3` - Article viewed for 3 sec
 * `4` - Article viewed for 10 sec
 * `5` - Article clicked
 * `6` - PDF viewed
 * `7` - Media clip played
 * `8` - Image clicked
 * `9` - Article added to tag
 * `10` - Entry fetched from Go log
 */
export type ActionEnum = (typeof ActionEnum)[keyof typeof ActionEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionEnum = {
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_9: 9,
  NUMBER_10: 10,
} as const

export type ParamsColorbaropt = {
  pixels?: number
  max_color?: number
  min_width?: number
  min_quote_width?: number
  min_sep?: number
  p?: number
  a?: number
}

export type ParamsMatchesopt = {
  width?: number
  lines?: number
  columns?: number
  wordindent?: number
  withatt?: boolean
}

export type ParamsCategoriesItemItem = {
  profile?: number
}

/**
 * Group articles in search results based on either profile, tag, tb list or subject list ids.

Information about the groups themselves are included in the @res/sort_groups field, with each document contains a reference to the group it belongs to in @doc/sort_group_ref.

Mainly used to feed results into report generation.
 */
export type ParamsCategories = {
  /** What to group by:
  1. Profile ids
  2. Topic builder lists
  3. Subject ids
  4. Tag ids */
  grouping?: number
  /** Id determining the articles membership in the group. Ids relate to @param/categories.grouping, e.g. if the grouping should be based on subject id, these ids are interpreted as subject id that form the basis of each group. */
  item?: ParamsCategoriesItemItem[]
}

export type ParamsTextLimits = {
  minwordlength?: number
  maxdist?: number
  maxtimediff?: number
}

export type ParamsSummaryLimits = {
  minwordlength?: number
  maxdist?: number
  maxtimediff?: number
}

export type ParamsHeaderLimits = {
  minwordlength?: number
  maxdist?: number
  maxtimediff?: number
}

/**
 * @deprecated
 */
export type ParamsRelated = { [key: string]: any }

export type ParamsIdenticalSortingItem = {
  /** Field to sort on.

  0. Paywall
  1. Global rank */
  field?: number
  /** How to order the results. Defaults to ascending order.

  0. Ascending order
  1. Descending order */
  order?: number
}

/**
 * Content to include for the identical documents. Adds an @doc/identical_documents field to the response with identical documents. The format is the same as for @param/main, but with an additional @param/identical.inherit option, which copies all of the settings, meaning the same fields are returned for the identical articles as those for the main article.
 */
export type ParamsIdentical = {
  /** Use the same settings as @param/main. */
  inherit?: boolean
}

export type ParamsSortCustomItem = {
  id_site?: number
  id_article?: number
}

export type ParamsExcludearticlesItem = {
  id_site?: number
  id_article?: number
}

export type ParamsArticlesItem = {
  id_site?: number
  id_article?: number
}

export type Params = {
  /** Settings related to the text and matches of each article.

In order return text from the articles, either @param/main.header, @param/main.summary or @param/main.body must be set to a non-zero value. All are off by default. */
  main?: ParamsMain
  /** Number of articles to return. */
  requestedarticles?: number
  /** Unix timestamp bounding the oldest article to return.

When grouping articles, unless the @param/strict_time_interval is set, the included articles can be outside of the time interval. */
  oldest?: number
  /** Unix timestamp bounding the newest article to return.

When grouping articles, unless the @param/strict_time_interval is set, the included articles can be outside of the time interval. */
  newest?: number
  oldest_oldest?: number
  newest_newest?: number
  /** Pagination settings. Leave empty or out on the first search. Subsequent searches can use the value returned in the responses @res/context field. If the returned value is non-empty, pass the value unchanged, along with the same search parameters, in order to fetch the next batch of results. */
  context?: string
  /** Include @doc/similarweb.readership and @doc/similarweb.article_readership fields in the response, with readership estimates. */
  readership?: boolean
  /** Add a @doc/topics field to the response, with information about the articles subjects. Passing 0 returns all of the associated subjects. Passing a string with comma separated ids will only return subjects that is also in the list. */
  allsubject?: string
  /** Add data about topics and entities to the response. The parameter is interpreted as a four digit binary number, each digit toggling the inclusion or exclusion of data.

Setting any of the first three digits adds a @doc/topics_and_entities field to each document in the response. Each bit sets what data to include:

  1. Topics
  2. Entities
  3. Entities without a wikidata id

Setting the fourth bit adds entity ids to match tags, using ent attributes, `<match ent="1,23...">`. Since these tags are activated by @param/main.matches, you should set that as well. It also adds two dictionaries, @res/wikinames and @res/wikidescriptions, with human readable names and descriptions of the entities.

Some useful values:

  - Setting it to 7 will include all the available data, but no highlighting information.
  - Setting it 8 will include only highlighting information.
  - Setting it to 15 will include everything. */
  textrazor?: number
  /** Add a @doc/meta_data field to the response. */
  allmeta?: boolean
  /** Only return matching articles with particular ids. The number of results is upper bounded by the @param/requestedarticles parameter, not the length of this list. */
  articles?: ParamsArticlesItem[]
  /** Discard the search string and fetch articles based only on the ids listed in @param/articles. This parameter improves efficiency when getting specific articles based **only** on ids, as the search string is of no interest. */
  select_by_ids?: boolean
  /** List of articles to explicitly exclude from the results. */
  excludearticles?: ParamsExcludearticlesItem[]
  /** Instead of returning articles sorted from newest to oldest, return the oldest articles first. */
  sort_oldest_first?: boolean
  /** List of ids specifying the order of the resulting documents. The first item in the list will also be the first document in the results. Articles not mentioned in the list will be added to the end of the results. */
  sort_custom?: ParamsSortCustomItem[]
  list_change_sort?: boolean
  natural_order_by?: number
  /** Consider the articles group. */
  groupidentical?: boolean
  /** Content to include for the identical documents. Adds an @doc/identical_documents field to the response with identical documents. The format is the same as for @param/main, but with an additional @param/identical.inherit option, which copies all of the settings, meaning the same fields are returned for the identical articles as those for the main article. */
  identical?: ParamsIdentical
  /** Sort articles in group. It is specified as a list of object with field to sort by and in which order. Articles are first sorted by the first item in the list, then the second item, etc. */
  identical_sorting?: ParamsIdenticalSortingItem[]
  /** Include the articles OGAE group id in an @doc/equalgroup field in the response. */
  include_equalgroup_id?: number
  /** Also include articles identical to the match from TB lists. */
  includeidentical?: boolean
  include_ogae_identical?: string
  /** @deprecated */
  related?: ParamsRelated
  /** When including identical articles, remove any articles not published within the time interval given by the @param/oldest and @param/newest parameters. */
  strict_time_interval?: boolean
  /** Maximum number of similar articles to return. If the number of similar articles exceeds the limit, the resulting list is capped. */
  max_similar_articles?: number
  header_limits?: ParamsHeaderLimits
  summary_limits?: ParamsSummaryLimits
  text_limits?: ParamsTextLimits
  /** Group articles in search results based on either profile, tag, tb list or subject list ids.

Information about the groups themselves are included in the @res/sort_groups field, with each document contains a reference to the group it belongs to in @doc/sort_group_ref.

Mainly used to feed results into report generation. */
  categories?: ParamsCategories
  /** Include @doc/html_code and @doc/html_len fields in the response, with the articles HTML content. */
  include_html?: number
  /** Include a @doc/linkobs field for every article, containing information about the sources the article was observed on. */
  getlinkobs?: boolean
  /** Include a @doc/crawlinfo field for every article, but only if also @param/getlinkobs is set. */
  getlinks?: boolean
  add_content_topics?: boolean
  /** Add an @doc/otblists field to the response. */
  getotblists?: string
  baskets?: number[]
  /** Timezone to display @doc/local_time and @doc/local_rcf822_time fields in. Only applies for xml return format. Also adds @doc/date_report_date and @doc/date_report_time fields to the response. */
  return_timezone?: string
  use_image_proxy?: number
  /** Comma separated list of picture ids, default 2,4. Types of screenshots to include in @doc/screenshots field.

  2. Preview */
  picture_types?: string
  include_mentioned_names?: boolean
  mark_matches_url?: number
  /** Use a shortened URL format in the responses @doc/url field. */
  use_short_links?: boolean
  matchesopt?: ParamsMatchesopt
  colorbaropt?: ParamsColorbaropt
  /** Compare the articles header to a string. Add a @doc/header.compare field to the response with the edit distance. */
  compare_header?: string
  /** Compare the articles combined summary and body text to a string. Add a @doc/summary.compare field to the response with the edit distance. */
  compare_text?: string
  /** Compare the articles url to a string. Add a @doc/compare field to the response with the edit distance. */
  compare_url?: string
  search_encoding?: string
  result_encoding?: string
  article_mode?: boolean
  statistics_mode?: boolean
  wap_mode?: number
  translate_to?: string
  translate_cfg?: string
  translate_type?: number
  /** The fields that should be translated. The parameter is intepreted as a binary number, each digit toggling translation of the corresponding field.

Each bit sets the fields to translate:
  1. @doc/header
  2. @doc/short_header
  3. @doc/summary
  4. @doc/short_summary
  5. @doc/body
  6. @doc/short_body
  7. @doc/caption
  8. @doc/articlemedia.text
  9. @doc/quotes
  10. @doc/colorbar
  11. @doc/matches
  12. Translate headline even if a manually translated header has been written and is provided in the @doc/translated_header field.
  13. Translate the summary even if a manually written summary has been written and is provided in the @doc/manual_summary field.
  14. @doc/manual_summary

The original fields can also be included if the @param/keep_orig_fields parameter is set. */
  translate_fields?: number
  /** The translated fields that should also return the original text. The original text is included in fields with a `_orig` suffix. The value is interpreted the same way as @param/translate_fields. */
  keep_orig_fields?: number
  /** Maximum number of characters to translate from each article. */
  max_gt_article_length?: number
  quotewishlength?: number
  quoteslack?: number
  /** Number of characters to truncate the summary and text fields in the response to. The limit is on the sum of the two fields. If the summary field exists, the truncation is first applied to it. If the length of the summary field is less than @param/max_article_length, the remaining characters are fetched from the body text. */
  max_article_length?: number
  max_search_len?: number
  max_copyright_age?: number
  output_media?: number
  commentgroups?: number[]
  referrer?: string
  specialtblist?: number[]
  update_search?: boolean
  emailsmssearch?: number
  emailsmssearchmaxage?: number
  smslength?: number
  extralink_level?: number
  social_media?: boolean
  id_user?: number
  id_application?: number
  id_partner?: number
  /** Include providers organization specific ids in the search results. */
  external_article_provider?: number
  /** Get a tracking articles matching articles. Used in conjunction with @param/track_id_article. */
  track_id_site?: number
  track_id_article?: number
  user_options?: number
  acceptedcacheage?: number
  lastseenarticle?: number
  forceuniquefyontblist?: number
  compute_aspects?: number
  exclude_check?: boolean
  mark_old_sent?: boolean
  locale?: string
  rawformat?: boolean
  /** Pass -1 to start a process watching for new articles matching the search. The watch id process is added to the search results in the @res/watch_id field. */
  watch_id?: number
  icon_resolution?: number
  aspect_info_limit?: number
  aspect_sep_limit?: number
  aspect_requested?: number
  edited_article_user?: string
  different_colors?: number
  aspect_combo?: number
  exclude_profile_tag?: boolean
  parse_analysis?: boolean
  use_recurring_search?: boolean
  access_info?: number
  access_groups?: number
  source_access?: number[]
  site_access?: number[]
  pay_source_access?: number[]
  pay_site_access?: number[]
  access_groups_teaser?: number
  licence_filter?: number
  licence_filter_user?: number
  licence_enforce_cond?: number
  licence_enforce?: number
  licence_filter_login?: number
  licence_filter_user_login?: number
  licence_enforce_cond_login?: number
  licence_enforce_login?: number
  licence_filter_teaser?: number
  licence_filter_user_teaser?: number
  licence_enforce_cond_teaser?: number
  licence_enforce_teaser?: number
}

/**
 * Include a @doc/body field in the response with the articles body text if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags.
 */
export type ParamsMainText =
  (typeof ParamsMainText)[keyof typeof ParamsMainText]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParamsMainText = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const

/**
 * Include a @doc/summary field in the response with the articles summaries if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags.
 */
export type ParamsMainSummary =
  (typeof ParamsMainSummary)[keyof typeof ParamsMainSummary]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParamsMainSummary = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const

/**
 * Include a @doc/header field in the response with the articles headers if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags.
 */
export type ParamsMainHeader =
  (typeof ParamsMainHeader)[keyof typeof ParamsMainHeader]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParamsMainHeader = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const

/**
 * Settings related to the text and matches of each article.

In order return text from the articles, either @param/main.header, @param/main.summary or @param/main.body must be set to a non-zero value. All are off by default.
 */
export type ParamsMain = {
  /** Include a @doc/header field in the response with the articles headers if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags. */
  header?: ParamsMainHeader
  /** Include a @doc/summary field in the response with the articles summaries if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags. */
  summary?: ParamsMainSummary
  /** Include a @doc/body field in the response with the articles body text if the value is non-zero. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags. */
  text?: ParamsMainText
  /** Include @doc/matches and @doc/matches_table fields in the response, with information about phrases matching the search string. */
  matches?: boolean
  /** Include a @doc/quotes field in the response, containing text surrounding each of the mathcing phrases. If @param/main.matches is set, and the value is 2, phrases matching the search string will be enclosed in `<match>` tags. */
  quotes?: number
  /** The sections to include quotes from. */
  quotesfrom?: number
  /** Include a @doc/short_header field in the response, with a shortened version of the header, of at most @param/main.shortheaderlength characters. */
  shortheaderlength?: number
  /** Include a @doc/short_summary field in the response, with a shortened version of the summary, of at most @param/main.shortsummarylength characters. */
  shortsummarylength?: number
  /** Include a @doc/short_body field in the response, with a shortened version of the body text, of at most @param/main.shortbodylength characters. */
  shortbodylength?: number
  shortfirstsrcname?: number
  /** Include a @doc/sources field in the response with information about all the sources the article was observed on. */
  othersources?: boolean
  shortothersrcname?: number
  colorbar?: boolean
  all?: boolean
  inherit?: boolean
}

/**
 * If the document is a tracking match, details about the upload it matched and additional data regarding the relationship between the upload and the document.
 */
export type DocumentTracking = {
  /** Partner specific id of the upload the document matched. */
  partner_article_id: string
  /** Tracking score, a measure of how likely it is that the document is linked to the uploaded article. */
  score: number
  /** A percentage estimate of how much of the uploaded article that were referenced in the matching article. */
  percent_used: number
  /** A percentage estimate of how much of the matching article that stems from the uploaded article. */
  percent_of_match: number
}

export type DocumentUsercommentsUsercommentItem = {
  /** User who made the comment. */
  id_user: number
  username: string
  first_name: string
  last_name: string
  signature: string
  email: string
  /** Comment group comment belongs to. */
  id_group: number
  id_site: number
  id_article: number
  /** When the comment was made. */
  timestamp: number
  /** Comment text. */
  comment: string
}

/**
 * Articles comments.
 */
export type DocumentUsercomments = {
  /** Number of comments. */
  count: number
  usercomment: DocumentUsercommentsUsercommentItem[]
}

export type DocumentInternalSearchReply = {
  id_site: number
  id_article: number
  text: string
}

export type DocumentLinkobsSourceObsImages = { [key: string]: any }

/**
 * Details about each observation.
 */
export type DocumentLinkobsSourceObs = {
  /** Unix timestamp */
  from?: number
  /** Unix timestamp */
  to?: number
  level?: string
  images?: DocumentLinkobsSourceObsImages
}

export type DocumentLinkobsSource = {
  /** Id of the source. */
  id?: number
  depth?: number
  /** Name of the source. */
  name?: string
  /** Details about each observation. */
  obs?: DocumentLinkobsSourceObs
}

export type DocumentLinkobs = {
  cnt: number
  source: DocumentLinkobsSource
}

export type DocumentOtblists = { [key: string]: any }

export type DocumentTagsItem = {
  id: number
  is_owner: number
  sent: number
  set: number
  equal_sent: number
}

/**
 * Description of the origin of the article.
 */
export type DocumentMediatype = {
  /** Whether timemap is present. */
  timemap: boolean
  /** Length of timemap, if present, in seconds. */
  duration?: number
  clip: boolean
  hastext: boolean
  haslogo: boolean
  /** Whether article was fetched from behind a paywall. */
  paywall: boolean
  fulltext: boolean
  text: string
}

/**
 * Similarity information about the almost identical documents. The documents themselves are added to the @doc/identical_documents field.
 */
export type DocumentAlmostIdentical = {
  id_site: number
  id_article: number
  /** Similarity score. Articles having a high score are more similar than those with a lower score. */
  sim: number
}

export type DocumentIdenticalDocumentsDocumentItem = { [key: string]: any }

/**
 * Collection of identical and almost identical documents.
 */
export type DocumentIdenticalDocuments = {
  /** Number of identical documents, that is the length of `identical_documents.document`. */
  cnt: number
  /** Identical documents. */
  document: Document[]
}

export type DocumentSourcesSource = {
  /** Id of the source. */
  id: number
  linktype_max?: number
  linktype_now?: number
  /** Name of the source. */
  name: string
  /** Name of the site. */
  sitename: string
  /** Url of the source. */
  url: string
  /** Url of the site. */
  siteurl: string
}

/**
 * Information about sources the article was observed on.
 */
export type DocumentSources = {
  source?: DocumentSourcesSource
}

/**
 * Information about the source the article was first observed on.
 */
export type DocumentFirstSource = {
  /** Id of the source. */
  id: number
  /** Name of the source. */
  name: string
  /** Name of the site. */
  sitename: string
  /** Url of the source. */
  url: string
  /** Url of the site. */
  siteurl: string
}

export type Document = {
  /** The articles header. Does not contain HTML. */
  header?: DocumentHeader
  /** The articles lede. Does not contain HTML. */
  summary?: DocumentSummary
  /** The articles body text. Typically contains HTML. */
  body?: DocumentBody
  /** The articles authors. */
  author?: string
  word_count: number
  short_header?: DocumentShortHeader
  short_summary?: DocumentShortSummary
  short_body?: DocumentShortBody
  /** The articles header manually translated. */
  translated_header?: DocumentTranslatedHeader
  /** A manually written summary of the article. */
  manual_summary?: DocumentManualSummary
  /** Whether text is an extract or not. */
  extract?: boolean
  /** List of search matches. */
  matches?: DocumentMatchesItem[]
  /** Text surrounding search matches. */
  quotes?: DocumentQuotes
  matches_table?: DocumentMatchesTableItem[]
  /** Traffic data for the domain and readership estimates.

**Note**: Will only be present if there is traffic data to return for the article. */
  similarweb?: DocumentSimilarweb
  site_rank?: DocumentSiteRank
  /** Information about the articles subjects, such as language, country and the medium it was published in. Articles are assigned subjects based on the site and source it was found. */
  topics?: DocumentTopicsItem[]
  /** Topics and entities found in the article.

**Note**: Will only be present if there are topics and entities data to return for the article. */
  topics_and_entities?: DocumentTopicsAndEntities
  /** Unix timestamp of when the article was published. */
  unix_timestamp: number
  /** Local time the article was published. */
  local_time: DocumentLocalTime
  /** Local time the article was published. */
  local_rcf822_time: DocumentLocalRcf822Time
  countryname?: string
  countrycode?: string
  language: DocumentLanguage
  /** Url where the article was found. */
  orig_url?: string
  /** Domain name of the site where the article was found. */
  url_common?: string
  /** Link to tool displaying the crawled article, with options for highlighting matches etc. */
  url?: string
  compare?: number
  /** The articles images. */
  articleimages?: DocumentArticleimages
  /** All the images' captions concatenated. */
  caption?: DocumentCaption
  screenshots?: DocumentScreenshotsItem[]
  /** The articles multimedia content. */
  articlemedia?: DocumentArticlemediaItem[]
  /** Length of timemap, if present, in seconds. */
  playlength?: number
  mediafile?: number
  /** Data about print content. */
  pdf_file?: DocumentPdfFile
  /** Various meta data about the article, like social media engagement. */
  meta_data?: DocumentMetaData
  /** Id of the site the article was published. */
  id_site: number
  /** Site specific article id. Unique under each site. */
  id_article: number
  /** Information about the source the article was first observed on. */
  first_source: DocumentFirstSource
  /** Information about sources the article was observed on. */
  sources?: DocumentSources
  /** Collection of identical and almost identical documents. */
  identical_documents?: DocumentIdenticalDocuments
  /** Similarity information about the almost identical documents. The documents themselves are added to the @doc/identical_documents field. */
  almost_identical?: DocumentAlmostIdentical
  /** OGAE group id. */
  equalgroup?: number
  /** Reference to categorization group. */
  sort_group_ref?: number
  /** Description of the origin of the article. */
  mediatype?: DocumentMediatype
  distribute_conditions?: string
  circulation?: number
  adprice?: number
  reach?: number
  content_protected?: number
  /** Index of the document in the list. One-based. */
  position: number
  hidden?: boolean
  existinbasket?: string
  tags?: DocumentTagsItem[]
  otblists?: DocumentOtblists
  linkobs?: DocumentLinkobs
  internal_search_reply: DocumentInternalSearchReply
  /** Timestamp used internally. */
  stimestamp: number
  /** Timestamp used internally. */
  stimestamp_index: number
  /** Id of the stored search the article matched, if requested through the StoredSearch feed. */
  stored_search_id?: number
  profile_id?: number
  profile_name?: string
  /** Articles comments. */
  usercomments?: DocumentUsercomments
  /** If the document is a tracking match, details about the upload it matched and additional data regarding the relationship between the upload and the document. */
  tracking?: DocumentTracking
  social_meta?: {
    author?: {
      display_name?: string
      profile_image?: string
      username?: string
    }
    attachments?: {
      links?: {
        description?: string
        image?: string
        title?: string
        url?: string
      }[]
    }
  }
}

/**
 * Last time Instagram meta data was updated.
 */
export type DocumentMetaDataSmIgStimestamp = { [key: string]: any }

/**
 * Number of comments on Instagram. Only for Instagram posts.
 */
export type DocumentMetaDataSmIgCommentCount = { [key: string]: any }

/**
 * Number of likes on Instagram. Only for Instagram posts.
 */
export type DocumentMetaDataSmIgLikeCount = { [key: string]: any }

/**
 * Instagram engagement count, the sum of likes and comments. Only for Instagram posts.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions.
 */
export type DocumentMetaDataSmIgEngagementCount = { [key: string]: any }

/**
 * Last time Twitter meta data was updated.
 */
export type DocumentMetaDataSmTwStimestamp = { [key: string]: any }

/**
 * Number of favorites.
 */
export type DocumentMetaDataSmTwFavoriteCount = { [key: string]: any }

/**
 * Number of retweets.
 */
export type DocumentMetaDataSmTwRetweetCount = { [key: string]: any }

/**
 * Number of shares on Twitter. Only for regular articles.
 */
export type DocumentMetaDataSmTwShareCount = { [key: string]: any }

/**
 * Twitter engagement count, the sum of retweets, favorites and shares.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions.
 */
export type DocumentMetaDataSmTwEngagementCount = { [key: string]: any }

/**
 * Last time Facebook meta data was updated.
 */
export type DocumentMetaDataSmFbStimestamp = { [key: string]: any }

/**
 * Number of shares on Facebook. Only for Facebook posts.
 */
export type DocumentMetaDataSmFbShareCount = { [key: string]: any }

/**
 * Number of comments on Facebook. Only for Facebook posts.
 */
export type DocumentMetaDataSmFbCommentCount = { [key: string]: any }

/**
 * Number of reactions on Facebook. Only for Facebook posts.
 */
export type DocumentMetaDataSmFbReactionCount = { [key: string]: any }

/**
 * Facebook engagement count, the sum of reactions, shares and comments.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions.
 */
export type DocumentMetaDataSmFbEngagementCount = { [key: string]: any }

/**
 * Various meta data about the article, like social media engagement.
 */
export type DocumentMetaData = {
  /** Facebook engagement count, the sum of reactions, shares and comments.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions. */
  sm_fb_engagement_count?: DocumentMetaDataSmFbEngagementCount
  /** Number of reactions on Facebook. Only for Facebook posts. */
  sm_fb_reaction_count?: DocumentMetaDataSmFbReactionCount
  /** Number of comments on Facebook. Only for Facebook posts. */
  sm_fb_comment_count?: DocumentMetaDataSmFbCommentCount
  /** Number of shares on Facebook. Only for Facebook posts. */
  sm_fb_share_count?: DocumentMetaDataSmFbShareCount
  /** Last time Facebook meta data was updated. */
  sm_fb_stimestamp?: DocumentMetaDataSmFbStimestamp
  /** Twitter engagement count, the sum of retweets, favorites and shares.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions. */
  sm_tw_engagement_count?: DocumentMetaDataSmTwEngagementCount
  /** Number of shares on Twitter. Only for regular articles. */
  sm_tw_share_count?: DocumentMetaDataSmTwShareCount
  /** Number of retweets. */
  sm_tw_retweet_count?: DocumentMetaDataSmTwRetweetCount
  /** Number of favorites. */
  sm_tw_favorite_count?: DocumentMetaDataSmTwFavoriteCount
  /** Last time Twitter meta data was updated. */
  sm_tw_stimestamp?: DocumentMetaDataSmTwStimestamp
  /** Instagram engagement count, the sum of likes and comments. Only for Instagram posts.

If the value is -1, the article doesn't have any engagement data, e.g. due to access restrictions. */
  sm_ig_engagement_count?: DocumentMetaDataSmIgEngagementCount
  /** Number of likes on Instagram. Only for Instagram posts. */
  sm_ig_like_count?: DocumentMetaDataSmIgLikeCount
  /** Number of comments on Instagram. Only for Instagram posts. */
  sm_ig_comment_count?: DocumentMetaDataSmIgCommentCount
  /** Last time Instagram meta data was updated. */
  sm_ig_stimestamp?: DocumentMetaDataSmIgStimestamp
}

export type DocumentPdfFilePdfPartsItem = {
  pages: number
  args: string
  text: string
}

/**
 * Data about print content.
 */
export type DocumentPdfFile = {
  all: string
  args: string
  pdf_parts: DocumentPdfFilePdfPartsItem[]
}

export type DocumentArticlemediaItem = {
  url?: string
  localurl?: string
  thumbnail?: string
  text?: string
}

export type DocumentScreenshotsItem = {
  id_type: number
  width: number
  height: number
  /** Url */
  text: string
}

/**
 * All the images' captions concatenated.
 */
export type DocumentCaption = {
  text?: string
}

export type DocumentArticleimagesArticleimageItemCaption = {
  matches?: boolean
  text?: string
}

export type DocumentArticleimagesArticleimageItem = {
  /** Url of the image. */
  url?: string
  caption?: DocumentArticleimagesArticleimageItemCaption
}

/**
 * The articles images.
 */
export type DocumentArticleimages = {
  /** Number of images, that is the length of @param/articleimage. */
  count: number
  /** Data about each image. Note that it could be that there is a caption, but for some reason there were no url. */
  articleimage: DocumentArticleimagesArticleimageItem[]
}

export type DocumentLanguage = {
  encoding: string
  text: string
}

/**
 * Local time the article was published.
 */
export type DocumentLocalRcf822Time = {
  /** RFC 822 formatted time */
  text: string
}

/**
 * Local time the article was published.
 */
export type DocumentLocalTime = {
  /** ISO 8601 formatted time. */
  text: string
  GMT: number
}

/**
 * This shows the overall sentiment of an article with respect to the article’s main point/topic or subject matter, taking into account its heading, summary, and body text.

 */
export type DocumentTopicsAndEntitiesSentiment =
  (typeof DocumentTopicsAndEntitiesSentiment)[keyof typeof DocumentTopicsAndEntitiesSentiment]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentTopicsAndEntitiesSentiment = {
  Positive: 'Positive',
  Negative: 'Negative',
  Neutral: 'Neutral',
} as const

/**
 * Topics and entities found in the article.

**Note**: Will only be present if there are topics and entities data to return for the article.
 */
export type DocumentTopicsAndEntities = {
  /** IPTC Media Topics found in the article. */
  topics?: DocumentTopicsAndEntitiesTopics
  /** Entities found in the article. There are three kinds of entities registered: locations, organizations and persons. */
  entities?: DocumentTopicsAndEntitiesEntities
  /** This shows the overall sentiment of an article with respect to the article’s main point/topic or subject matter, taking into account its heading, summary, and body text.
   */
  sentiment?: DocumentTopicsAndEntitiesSentiment
  /** This is computed at sentence level to then aggregate those values into a final output.
   */
  sentiment_score?: number
}

export type DocumentTopicsAndEntitiesEntitiesPerson = {
  /** Name of the person. */
  entity?: string
  /** Id in the Wikidata knowledge graph. */
  wikidata_id?: string
}

export type DocumentTopicsAndEntitiesEntitiesOrganization = {
  /** Name of the organization. */
  entity?: string
  /** Id in the Wikidata knowledge graph. */
  wikidata_id?: string
}

export type DocumentTopicsAndEntitiesEntitiesLocation = {
  /** Name of the location. */
  entity?: string
  /** Id in the Wikidata knowledge graph. */
  wikidata_id?: string
}

/**
 * Entities found in the article. There are three kinds of entities registered: locations, organizations and persons.
 */
export type DocumentTopicsAndEntitiesEntities = {
  location?: DocumentTopicsAndEntitiesEntitiesLocation
  organization?: DocumentTopicsAndEntitiesEntitiesOrganization
  person?: DocumentTopicsAndEntitiesEntitiesPerson
}

/**
 * IPTC Media Topics found in the article.
 */
export type DocumentTopicsAndEntitiesTopics = {
  /** IPTC Media Topic name. Topic names are hierarchically structured, each level separated by a >, and with the levels ordered from the most general to the most specific. */
  label?: string
  /** IPTC Media Code. */
  mediatopic_id?: string
  /** Confidence in the article revolving around the topic. */
  score?: number
}

export type DocumentTopicsItem = {
  /** Subject id. */
  id: number
  /** Subject name. */
  text: string
  linktype_max?: number
  linktype_now?: number
  /** Meta category, an integer representing the type of the subject. */
  metacat?: number
  /** Meta category abstraction level, ordered from 0 and up. */
  metacat_level?: number
}

export type DocumentSiteRank = {
  rank_global?: number
  rank_country?: number
}

/**
 * Article level readership estimates.
 */
export type DocumentSimilarwebArticleReadership = {
  /** Estimated number of readers of the article so far. */
  value?: number
  /** Predicted number of additional readers in the next hour, assuming its visibility does not change. */
  DeltaPValue?: number
  /** Predicted number of people that will eventually read the article. */
  ExpectedPValue?: number
  /** Standard deviation of `ExpectedPValue`. Higher values indicate lower confidence in the final value.

For recently published articles, this value can be high, since little is know about how long it will stay visible etc. */
  StdExpectedPValue?: number
  /** Estimated fraction of the sites readers having read the article so far. */
  P?: number
  /** Predicted fraction of the sites readers that will read the article in the next hour. */
  DeltaP?: number
  /** Predicted fraction of the sites readers that will eventually read the article. */
  ExpectedP?: number
  /** Standard deviation of ExpectedP. */
  StdExpectedP?: number
}

/**
 * Site level readership estimates.
 */
export type DocumentSimilarwebReadership = {
  P?: number
  value?: number
}

/**
 * Traffic data for the domain and readership estimates.

**Note**: Will only be present if there is traffic data to return for the article.
 */
export type DocumentSimilarweb = {
  domain?: string
  /** Number of montly visits. */
  mvisit?: number
  unique_mobile_visits?: number
  unique_desktop_visits?: number
  /** Average number of pages viewed per visit. */
  page_per_visit?: number
  /** Fraction of visits that only viewed a single page before going to another site. */
  bounce_rate?: number
  /** Average time spent on the site per visit, in seconds. */
  time_on_site?: number
  category?: string
  category_rank?: number
  sanitize?: number
  /** Site level readership estimates. */
  readership?: DocumentSimilarwebReadership
  /** Article level readership estimates. */
  article_readership?: DocumentSimilarwebArticleReadership
}

export type DocumentMatchesTableItem = { [key: string]: any }

export type DocumentQuotesQuote = {
  matches?: boolean
  text?: string
}

/**
 * Text surrounding search matches.
 */
export type DocumentQuotes = {
  quote?: DocumentQuotesQuote
}

export type DocumentMatchesItem = {
  type?: string
  /** Text that was matched. If you use wildcards e.g. this will contain the actual word or phrase matched. */
  text?: string
  color?: number
  /** Offset of match in video or radio clip, if present, in seconds. */
  play_offset?: number
  quote?: {
    matches?: boolean
    text?: string
  }
}

/**
 * A manually written summary of the article.
 */
export type DocumentManualSummary = {
  text?: string
  matches?: boolean
  language?: string
}

/**
 * The articles header manually translated.
 */
export type DocumentTranslatedHeader = {
  text?: string
  matches?: boolean
  language?: string
}

export type DocumentShortBody = {
  text?: string
  matches?: boolean
  length?: number
  removed?: number
}

export type DocumentShortSummary = {
  text?: string
  matches?: boolean
  length?: number
  removed?: number
}

export type DocumentShortHeader = {
  text?: string
  matches?: boolean
  length?: number
  removed?: number
}

/**
 * The articles body text. Typically contains HTML.
 */
export type DocumentBody = {
  text?: string
  readmore?: boolean
  /** Whether matches should be marked in the body text, i.e. if @param/main.text is set to 2. */
  matches?: boolean
  /** Whether the field was truncated or not. */
  truncated?: boolean
}

/**
 * The articles lede. Does not contain HTML.
 */
export type DocumentSummary = {
  text?: string
  /** Whether matches should be marked in the summary, i.e. if @param/main.summary is set to 2. */
  matches?: boolean
  /** Edit distance between the @param/compare_text parameter and the combined summary and body text. */
  compare?: number
  /** Whether the field was truncated or not. */
  truncated?: boolean
}

/**
 * The articles header. Does not contain HTML.
 */
export type DocumentHeader = {
  text?: string
  /** Whether matches should be marked in the header, i.e. if @param/main.header is set to 2. */
  matches?: boolean
  /** Edit distance between the @param/compare_header parameter and the header text. */
  compare?: number
}

export type SearchMatch = {
  type?: string
  color?: number
  text?: string
}

export type MatchItem = {
  rowspan?: number
  colspan?: number
  type?: string
  color?: number
  active?: boolean
  length?: number
  removed?: number
  text?: string
}

export type MatchTableRow = {
  match_item?: MatchItem
}

export type MatchTable = {
  rows?: number
  cols?: number
  row?: MatchTableRow
}

export type SearchResponseSortGroups = {
  /** Id the group was filtered on. 0 if it's the group containing the articles not matching any group. */
  group?: number
  weight?: number
  /** Number of articles in group. */
  count?: number
  /** Name of the group. */
  name?: string
}

/**
 * A dictionary mapping entity ids to entity descriptions.
 */
export type SearchResponseWikidescriptions = { [key: string]: any }

/**
 * A dictionary mapping entity ids to entity names.
 */
export type SearchResponseWikinames = { [key: string]: any }

export type SearchResponseDebugLinesItem = {
  /** 1 if the line had an error. 0 otherwise. */
  state?: number
  /** What the query was interpreted as, if the line had any errors corrected. */
  query?: string
}

/**
 * Information about search term corrections. Whenever there are minor mistakes in the seach term, e.g. a missing closing quote, the parser attempts to correct it.
 */
export type SearchResponseDebug = {
  /** Number of lines. */
  count?: number
  lines?: SearchResponseDebugLinesItem[]
}

export type SearchResponse = {
  /** Number of documents in the result, that is the length of the @res/document list. */
  documents: number
  /** Articles matching search. */
  document: Document[]
  /** Information about search term corrections. Whenever there are minor mistakes in the seach term, e.g. a missing closing quote, the parser attempts to correct it. */
  debug?: SearchResponseDebug
  /** Unix timestamp of the first document matching the search also in the response. Matches are found going backwards in time, so it will also be the document published the latest. */
  first_timestamp: number
  /** Unix timestamp of the last document matching the search also in the response. Matches are found going backwards in time, so it will also be the document published the earliest. */
  last_timestamp: number
  /** String value used for pagination, representing where the current search ended. For use by the @param/context parameter in the request.

If the value is empty there are no more articles to fetch. */
  context: string
  /** Unix timestamp a new search can start from were it to get newer content. Thus, it can be passed for @param/oldest. Will never be larger than current time. */
  search_start?: number
  /** Estimate on the total number of documents matching the search in the range given by @res/range_start and @res/range_end. */
  range_count?: number
  /** Unix timestamp of the start of the range used for computing @res/range_count. */
  range_start?: number
  /** Unix timestamp of the end of the range used for computing @res/range_count. A @res/range_end of 0 means that the end of the range is now. */
  range_end?: number
  /** A dictionary mapping entity ids to entity names. */
  wikinames?: SearchResponseWikinames
  /** A dictionary mapping entity ids to entity descriptions. */
  wikidescriptions?: SearchResponseWikidescriptions
  search_matches?: SearchMatch[]
  matches_table?: MatchTable
  /** Id of watch process. */
  watch_id?: number
  count: number
  cacheage: number
  cputime: number
  /** Server having processed the request. */
  host: string
  compiledate: string
  /** Name of the current release of the search system. Not in use at the moment. Returns NOT ACTIVE for all requests. */
  branch: string
  notimeout: boolean
  sort_groups?: SearchResponseSortGroups
}

export type SearchError = {
  host: string
  compiledate: string
  errors: string
}

export type SearchResultSearchresult = SearchError | SearchResponse

export type SearchResult = {
  searchresult: SearchResultSearchresult
}

export type SearchGlueRequestBodyItemsItem = {
  list_two?: SearchGlueRequestBodyItemsItemListTwo
  list_one: SearchGlueRequestBodyItemsItemListOne
  glue_start: string
  glue_middle?: string
  glue_end: string
}

export type SearchGlueRequestBody = {
  params: Params
  items: SearchGlueRequestBodyItemsItem[]
}

export type SearchRequestBodyExpressionsSearchlineObjectExpressionsItemSearchlineFiltersItem =
  {
    type?: string
    id?: string
    name?: string
  }

export type SearchRequestBodyExpressionsSearchlineObjectExpressionsItemSearchline =
  {
    searchterm?: string
    filters?: SearchRequestBodyExpressionsSearchlineObjectExpressionsItemSearchlineFiltersItem[]
  }

export type SearchRequestBodyExpressionsSearchlineObjectExpressionsItem = {
  id?: number
  linemode?: string
  searchline?: SearchRequestBodyExpressionsSearchlineObjectExpressionsItemSearchline
}

export type SearchRequestBodyExpressionsSearchlineObject = {
  expressions?: SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[]
  params?: Params
}

export type SearchRequestBodyExpressionsSearchlineStringExpressionsItem = {
  linemode?: string
  searchline?: string
}

export type SearchRequestBodyExpressionsSearchlineString = {
  expressions?: SearchRequestBodyExpressionsSearchlineStringExpressionsItem[]
  params?: Params
}

export type SearchRequestBodySearchtermOnly = {
  searchterm?: string
  params?: Params
}

export type SearchGlueRequestBodyItemsItemListOne =
  | SearchRequestBodySearchtermOnly
  | SearchRequestBodyExpressionsSearchlineString
  | SearchRequestBodyExpressionsSearchlineObject

export type SearchGlueRequestBodyItemsItemListTwo =
  | SearchRequestBodySearchtermOnly
  | SearchRequestBodyExpressionsSearchlineString
  | SearchRequestBodyExpressionsSearchlineObject

/**
 * A suggestion item, the non-required field will be returned from the normal suggest-endpoints, but not by the getids endpoint.
 */
export type FilterSuggestion = {
  /** Filter type. Use this if you want to use the item some other place, like this `<type>:<id>`. */
  type: string
  /** Localized name of the filter type. */
  typeName: string
  /** Filter value.

If the `type` is `site`, some items have their `id` prefixed with `00`. These are `source`-ids and not `site`-ids.
You most likely don't have to worry about this, as most other systems automatically translates these for you. */
  id: string
  /** Localized name of the filter value. */
  name: string
  /** URL of the site if the `type` is `site`. */
  url: string
  /** Rank id of the site if the `type` is `site`. */
  rank: string
  /** Language code of the site if `type` is `site`. */
  meta: string
  lang: string
  /** Information about which of the words in the search term matched and where.

Returns a string with one digit for each word in the search term.
Each digit convey information about what the word in the search term matched.
0 means no match, 1 means match in name and 2 means match in the url.
Entries added to fill up the list return `n/a` */
  mo: string
  /** `mo`, but any word apart from the last in the search term must be a full match. 
Searching for `n york` will return `01` in `match` but `11` in `mo` for `New York`.

Omitted if the entry is only there to fill up the list. */
  match?: string
  /** Highlighting information about matches to the `name. Only added if there is a match. */
  hl?: string
  /** Highlighting information about matches to the `url`. Only added if there is a match.

On the same format as `hl`. */
  hlu?: string
  weight: number
  /** Access needed to view the item. */
  accessGroup?: number
}

export type FilterSuggestionMultiple = {
  seq: number
  col: number
  action: string
  typeName: string
  suggest: FilterSuggestion[]
}

export type SuggestionResponseMultiple = {
  lang?: FilterSuggestionMultiple
  media?: FilterSuggestionMultiple
  site?: FilterSuggestionMultiple
  geo?: FilterSuggestionMultiple
  timePeriod?: FilterSuggestionMultiple
  content?: FilterSuggestionMultiple
  rank?: FilterSuggestionMultiple
  /** Number of results returned for single mode. `0` for multiple mode. */
  hits: number
  /** Pagination cursor. */
  next_offset: string
  tokens: number
  /** The search term. */
  search: string
  'time(ms)': number
  warnings: string
}

export type SuggestionResponse = {
  /** List of information about matching filters. For multiple mode this field is replaced by lists with the filter types names. */
  results: FilterSuggestion[]
  /** Number of results returned for single mode. `0` for multiple mode. */
  hits: number
  /** Pagination cursor. */
  next_offset: string
  tokens: number
  /** The search term. */
  search: string
  'time(ms)': number
  warnings: string
}

import { createContext, useState, useContext } from 'react'

// Define the shape of the context
interface IdenticalArticlesContextProps {
  identicalArticlesActiveIndexes: Record<string, number>
  setActiveIdenticalArticleIndex: (articleId: string, index: number) => void
}

interface IdenticalArticlesProviderProps {
  children: React.ReactNode
}

// Create the context with default values
const IdenticalArticlesContext = createContext<IdenticalArticlesContextProps>({
  identicalArticlesActiveIndexes: {},
  setActiveIdenticalArticleIndex: () => {},
})

// Create a provider component
export const IdenticalArticlesProvider = ({
  children,
}: IdenticalArticlesProviderProps) => {
  const [identicalArticlesActiveIndexes, setIdenticalArticlesActiveIndexes] =
    useState<Record<string, number>>({})

  const setActiveIdenticalArticleIndex = (articleId: string, index: number) => {
    setIdenticalArticlesActiveIndexes((prevState) => ({
      ...prevState,
      [articleId]: index,
    }))
  }

  return (
    <IdenticalArticlesContext.Provider
      value={{
        identicalArticlesActiveIndexes,
        setActiveIdenticalArticleIndex,
      }}
    >
      {children}
    </IdenticalArticlesContext.Provider>
  )
}

// Create a custom hook to use the search context
export const useIdenticalArticlesContext = () =>
  useContext(IdenticalArticlesContext)

import { useOpointAuth } from '@opoint/authjs-react'
import axios, { AxiosRequestConfig } from 'axios'
const isVercel = import.meta.env.VITE_VERCEL as boolean
const API_BASE_URL = isVercel
  ? (import.meta.env.VITE_VERCEL_API_URL as string)
  : (import.meta.env.VITE_API_URL as string)
const ANALYTICS_BASE_URL = isVercel
  ? (import.meta.env.VITE_VERCEL_ANALYTICS_API_URL as string)
  : (import.meta.env.VITE_ANALYTICS_API_URL as string)

export const AXIOS_INSTANCE = axios.create({ baseURL: API_BASE_URL })
export const ANALYTICS_AXIOS_INSTANCE = axios.create({
  baseURL: ANALYTICS_BASE_URL,
})

type AxiosInstanceArgs = {
  isAnalytics?: boolean
}

export const useCustomAxiosInstance = <T>(
  args?: AxiosInstanceArgs,
): ((config: AxiosRequestConfig) => Promise<T>) => {
  const auth = useOpointAuth()

  return async (config: AxiosRequestConfig) => {
    const token = await auth?.getTokenString()
    const source = axios.CancelToken.source()

    const options = {
      ...config,
      headers: {
        ...config.headers,
        accept: 'application/json',
        'accept-language': 'en-GB',
        'content-type': 'application/json',
        authorization: `JWT ${token}`,
      },
      cancelToken: source.token,
    }

    const promise = (
      args?.isAnalytics
        ? ANALYTICS_AXIOS_INSTANCE<T>(options)
        : AXIOS_INSTANCE<T>(options)
    ).then(({ data }) => data)

    // @ts-expect-error - picked from https://orval.dev/reference/configuration/output
    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query')
    }

    return promise
  }
}

import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { User } from '../types'
import { useCustomAxiosInstance } from '../axios/instance'

export const getGetProfilesQueryKey = (userSearchTerm: string) => [
  `usersData-${userSearchTerm}`,
]

const useGetUsers = ({
  userSearchTerm,
  options = {},
}: {
  userSearchTerm: string
  options?: UseQueryOptions<User[]>
}) => {
  const apiClient = useCustomAxiosInstance<User[]>()
  const queryKey = getGetProfilesQueryKey(userSearchTerm)

  return useQuery<User[]>({
    queryKey,
    queryFn: () =>
      apiClient({ url: '/users/impersonation_targets/', method: 'get',
          params: { search: userSearchTerm, excludeSelf: 0 },
        }),
    refetchOnWindowFocus: false,
    ...options,
  })
}

export default useGetUsers

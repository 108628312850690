import { Document } from '../generated-types/types.schemas'

const getArticleId = (article: Document | undefined) => {
  if (!article) {
    return ''
  }

  const { id_site, id_article } = article

  return `${id_site}_${id_article}`
}

export default getArticleId

export default {
  success: '#33B577',
  danger: '#D64949',
  'light-rock': '#F2F1F2',
  'dark-sky': '#001F4A',
  grey: '#E5E5E5',
  'warm-grey': '#C4C2C6',
  'light-sea': '#7BB8D1',
  'light-berry': '#FF8186',
  'blue-sky': '#648CC3',
  'cloudy-sky': '#7F8FA4',
  snow: '#fff',
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button, Loader, useToast } from '@opoint/infomedia-storybook'

import { Save20Regular } from '@fluentui/react-icons'
import { useCallback, useEffect, useState } from 'react'
import * as ProfilePage from '../../../components/ProfilePage'
import {
  PREVIEW_BUTTON_PORTAL_ID,
  COMPARE_QUERIES_BUTTON_PORTAL_ID,
} from '../../../components/ProfilePage/Form'
import { FormState } from '../../../components/ProfilePage/types'
import {
  useProfilesRetrieve,
  useProfilesUpdate,
} from '../../../generated-types/profiles/profiles'
import useGetProfiles from '../../../hooks/useGetProfiles'
import useProfileId from '../../../hooks/useProfileId'

import useActiveUserIdParam from '../../../hooks/useActiveUserIdParam'
import useImpersonate from '../../../hooks/useImpersonate'
import { useEditorContext } from '../../../context/editorContext'
import InvalidEditorSyntaxConfirmationModal from '../../../components/ProfilePage/Form/InvalidEditorSyntaxConfirmationModal'
import DeleteProfileButton from './DeleteProfileButton'
import ErrorMessage from './ErrorMessage'
import { formatQueries, getDefaultValues } from './utilities'

const formId = 'update-profile-form'

const UpdateProfilePage = () => {
  const profileId = useProfileId()
  const { toast } = useToast()
  const [userId, setUserId] = useActiveUserIdParam()
  const [impersonated, setImpersonated] = useState(false)

  const { handleImpersonate, isLoading: isLoadingImpersonation } =
    useImpersonate()

  const { checkSyntaxValidity } = useEditorContext()
  const [
    showInvalidEditorSyntaxConfirmationModal,
    setShowInvalidEditorSyntaxConfirmationModal,
  ] = useState(false)

  const [formData, setFormData] = useState<FormState | null>(null)

  const handleActiveUserIdChange = useCallback(
    (id: number | undefined) => {
      handleImpersonate({
        userId: id,
        onSuccess: () => {
          setUserId(id ? id : null)
        },
      })
    },
    [handleImpersonate, setUserId],
  )

  useEffect(() => {
    if (!userId || impersonated) {
      return
    }

    handleActiveUserIdChange(userId)
    setImpersonated(true)
  }, [userId, handleActiveUserIdChange])

  const {
    data: profileData,
    refetch: handleProfileDataRefetch,
    isLoading: isLoadingProfileData,
    isFetching,
    isError,
    error,
  } = useProfilesRetrieve(
    profileId || 0,
    {
      with_query: true,
      with_history: true,
    },
    { query: { refetchOnWindowFocus: false } },
  )

  const isLoading =
    isFetching ||
    (isLoadingProfileData && !!profileId) ||
    isLoadingImpersonation

  const { data: profiles = [] } = useGetProfiles({
    userId: userId,
  })
  const isProfileParent = profiles.some(
    (profile) => profile.parent === profileId,
  )

  const defaultValues = getDefaultValues(profileData)

  const { isLoading: isSavingProfileName, mutate: updateProfile } =
    useProfilesUpdate()

  const maybeUpdateProfile = (data: FormState): void => {
    if (!profileData) {
      return
    }

    if (!profileData.items) {
      return
    }

    const isSyntaxValid = checkSyntaxValidity()

    if (!isSyntaxValid) {
      setShowInvalidEditorSyntaxConfirmationModal(true)
      setFormData(data)
      return
    }

    handleUpdateProfile(data)
  }

  const handleUpdateProfile = (data: FormState): void => {
    const updatedProfileData = {
      ...profileData,
      name: data.profileName,
      parent: data.hasParent ? data.parent : 0,
      folder: data.folder,
      items: formatQueries([
        ...data.queries.required,
        ...data.queries.exclude,
        ...data.queries.optional,
      ]),
      make_persistent: data.make_persistent,
      persistent_backfill_start: data.persistent_backfill_start,
      persistent_grouping: data.persistent_grouping,
      blessed: data.blessed,
      stored_search: data.stored_search,
      sm_rate: data.sm_rate,
    }

    if (!updatedProfileData.id) {
      toast({
        title: 'Profile ID is missing. Please try again.',
        variant: 'destructive',
      })
    }

    updateProfile(
      {
        idList: updatedProfileData.id ?? 0,
        data: updatedProfileData,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Profile was successfully updated.',
            variant: 'success',
          })
          void handleProfileDataRefetch()
        },
        onError: () =>
          toast({
            title: 'Something went wrong. Please try again.',
            variant: 'destructive',
          }),
      },
    )
  }

  return (
    <>
      <ProfilePage.Wrapper
        buttons={
          <>
            <DeleteProfileButton isProfileParent={isProfileParent} />
            <div id={PREVIEW_BUTTON_PORTAL_ID} />
            <div id={COMPARE_QUERIES_BUTTON_PORTAL_ID} />
            <Button
              className="text-white"
              disabled={isSavingProfileName}
              form={formId}
              type="submit"
              variant="success"
            >
              <Save20Regular />
              {isSavingProfileName ? 'Saving...' : 'Save'}
            </Button>
          </>
        }
      >
        <>
          {isError ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
            //@ts-ignore
            <ErrorMessage errorStatus={error?.status} />
          ) : isLoading ? (
            <div className="flex size-full items-center justify-center">
              <Loader />
            </div>
          ) : (
            defaultValues && (
              <ProfilePage.Form
                defaultValues={defaultValues}
                formId={formId}
                onSubmit={maybeUpdateProfile}
                profileData={profileData}
              />
            )
          )}
        </>
      </ProfilePage.Wrapper>
      <InvalidEditorSyntaxConfirmationModal
        isShown={showInvalidEditorSyntaxConfirmationModal}
        onConfirm={() => {
          if (formData) {
            handleUpdateProfile(formData)
          }
        }}
        onHide={() => {
          setShowInvalidEditorSyntaxConfirmationModal(false)
        }}
      />
    </>
  )
}

export default UpdateProfilePage

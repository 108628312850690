import { useToast } from '@opoint/infomedia-storybook'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOpointAuthState } from '@opoint/authjs-react'
import { useCustomAxiosInstance } from '../axios/instance'
import { getGetProfilesQueryKey } from './useGetProfiles'

const useDeleteProfile = (
  profileId: number | null | undefined,
  onSuccess?: () => void,
) => {
  const queryClient = useQueryClient()
  const apiClient = useCustomAxiosInstance()
  const { toast } = useToast()

  const authState = useOpointAuthState()

  const userId =
    authState?.user.impersonator?.user_id ?? authState?.user?.user_id

  const getProfilesQueryKey = getGetProfilesQueryKey(userId ?? 0)

  if (!profileId) {
    throw new Error('profileId is required')
  }

  return useMutation(
    () =>
      apiClient({
        url: `/profiles/${profileId}/`,
        method: 'delete',
        params: { force: 1 },
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Profile was successfully deleted.',
          variant: 'success',
        })

        void queryClient.invalidateQueries(getProfilesQueryKey)

        onSuccess?.()
      },
      onError: () =>
        toast({
          title: 'Something went wrong. Please try again.',
          variant: 'error',
        }),
    },
  )
}

export default useDeleteProfile

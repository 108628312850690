import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Loader,
} from '@opoint/infomedia-storybook'
import { useState } from 'react'
import { ProfileListWithChildren } from '../../types/profile'
import { useProfilesDependenciesRetrieve } from '../../generated-types/profiles/profiles'
import useDeleteProfile from '../../hooks/useDeleteProfile'

const ConfirmProfileDelete = ({
  profile,
  onProfileDeleted,
}: {
  profile: ProfileListWithChildren
  onProfileDeleted: (profileId: number) => void
}) => {
  const { data: dependencies, isLoading } = useProfilesDependenciesRetrieve(
    profile.id,
  )

  const { mutate: deleteProfile } = useDeleteProfile(profile.id, () => {
    onProfileDeleted(profile.id)
  })

  const isParent = dependencies?.parent

  const hasAlertDependencies =
    dependencies?.alerts && dependencies?.alerts.length > 0

  const hasFeedDependencies =
    dependencies?.feeds && dependencies?.feeds.length > 0

  const hasDependencies = hasAlertDependencies || hasFeedDependencies

  return (
    <div className="flex items-center justify-between border-b border-grey.6 py-4">
      <div>
        <span className="text-body font-bold">{profile.name}</span>
        {(isParent || hasDependencies) && (
          <div>
            <div className="my-2">
              {hasDependencies && <p>Profile is used in:</p>}
              {hasAlertDependencies && (
                <div>
                  <span className="font-bold">Alerts: </span>
                  <span>
                    {dependencies?.alerts.map(({ name }) => name).join(', ')}
                  </span>
                </div>
              )}
              {hasFeedDependencies && (
                <div>
                  <span className="font-bold">Feeds: </span>
                  <span>
                    {dependencies?.feeds.map(({ name }) => name).join(', ')}
                  </span>
                </div>
              )}
            </div>
            {isParent && (
              <p>
                Profile is a parent. If you delete it, child profiles will be
                moved one level up.
              </p>
            )}
          </div>
        )}
      </div>
      <Button
        disabled={isLoading}
        variant="outline"
        className="min-w-52"
        onClick={() => {
          deleteProfile()
        }}
      >
        {isLoading ? <Loader size="tiny" /> : 'Confirm deletion'}
      </Button>
    </div>
  )
}

type Props = {
  profilesToDelete: ProfileListWithChildren[]
  onProfileDeleted: (profileId: number) => void
}

const ConfirmProfileDeleteModal = ({
  profilesToDelete,
  onProfileDeleted,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleProfileIsDeleted = (profileId: number) => {
    onProfileDeleted(profileId)
    if (profilesToDelete.length === 1) {
      setIsModalOpen(false)
    }
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogTrigger asChild>
        <Button disabled={profilesToDelete.length === 0}>
          Delete profiles
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to delete listed profiles?
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="max-h-[60vh] overflow-auto">
          <div className="border-t border-grey.6">
            {profilesToDelete.map((profile) => (
              <ConfirmProfileDelete
                onProfileDeleted={handleProfileIsDeleted}
                profile={profile}
                key={profile.id}
              />
            ))}
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmProfileDeleteModal

import { useLocation } from 'react-router-dom'

/**
 * Custom hook to determine if the current page is the new profile page.
 * @returns boolean
 */
const useIsNewProfile = () => {
  const { pathname } = useLocation()

  return pathname === '/profiles/new'
}

export default useIsNewProfile

import { createContext, useState, useContext } from 'react'

// Define the shape of the context
interface SearchTermContextProps {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
}

interface SearchTermProviderProps {
  children: React.ReactNode
}

// Create the context with default values
const SearchTermContext = createContext<SearchTermContextProps>({
  searchTerm: '',
  setSearchTerm: () => {},
})

// Create a provider component
export const SearchTermProvider = ({ children }: SearchTermProviderProps) => {
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <SearchTermContext.Provider value={{ searchTerm, setSearchTerm }}>
      {children}
    </SearchTermContext.Provider>
  )
}

// Create a custom hook to use the search context
export const useSearchTermContext = () => useContext(SearchTermContext)

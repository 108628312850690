import { SocialMedia } from '../components/ActiveArticle/utils'

const FACEBOOK_URL = 'https://www.facebook.com/'
const FACEBOOK_HASHTAG_URL = 'https://www.facebook.com/hashtag/'

const INSTAGRAM_URL = 'https://www.instagram.com/'
const INSTAGRAM_HASHTAG_URL = 'https://www.instagram.com/explore/tags/'

const TWITTER_URL = 'https://twitter.com/'
const TWITTER_HASHTAG_URL = 'https://twitter.com/hashtag/'

const SOCIAL_URLS: Record<SocialMedia, { handle: string; hashtag: string }> = {
  [SocialMedia.Facebook]: {
    handle: FACEBOOK_URL,
    hashtag: FACEBOOK_HASHTAG_URL,
  },
  [SocialMedia.Instagram]: {
    handle: INSTAGRAM_URL,
    hashtag: INSTAGRAM_HASHTAG_URL,
  },
  [SocialMedia.Twitter]: {
    handle: TWITTER_URL,
    hashtag: TWITTER_HASHTAG_URL,
  },
}

/*
--- Regex for injecting links into SoMe posts ---

The <match> tags we get in the text are very inconsistent so we need many steps to break the string down into parts that will
    A) avoid breaking the HTML syntax
    B) allow us to gather the right handles and hashtags to link back to the SoMe source
*/

// Simply matches a valid url
const matchUrls = /(http(s)?:\/\/[\w.~:/?#[\]@!$&%'()*+,;=-]*)/gu

// Matches handles and hashtags corresponding to the known patterns on the SoMe platforms we support
// See an overview of patterns in the doc here: https://infomediacorp.atlassian.net/wiki/spaces/TD/pages/2771451905/SoMe+patterns+for+handles+and+hashtags

/**
 * *******************************************************************************************
 * !! ATTENTION !!
 * The below original regex's contain negative lookbehinds
 * which is not supported in Safari!
 * The un-commented version below versions are a bit more lax and will contain false-positives
 * untill we figure out a better way to parse these tags
 *
 * - Facebook: /(?<![&_\p{Alphabetic}\d])#(?!\d+[\s!#$%&\*]+)[_\p{Alphabetic}\d]+/gu
 * - Instagram: /((?<![!#$%&\*\w\d])@(?![\w\d]+@)[\w\d](?:\.?[\w\d]+(?:[\w\d.]?[\w\d]))*)|((?<![&$\*])#(?![\s!#$%&\*]+)[_\p{Alphabetic}\d]+)/gu
 * - Twitter: /((?<![!#$%&\*\w\d])@(?!\w+@)\w*)|((?<![&\p{Alphabetic}\d])#(?!\d+[\s!#$%&\*]+)[_\p{Alphabetic}\d]+)/gu
 *
 * *******************************************************************************************
 */

const matchSoMeSource = {
  Facebook: /#(?!\d+[\s!#$%&*]+)[_\p{Alphabetic}\d]+/gu,
  Instagram:
    /(@(?![\w\d]+@)[\w\d](?:\.?[\w\d]+(?:[\w\d.]?[\w\d]))*)|(#(?![\s!#$%&*]+)[_\p{Alphabetic}\d]+)/gu,
  Twitter: /(@(?!\w+@)\w*)|(#(?!\d+[\s!#$%&*]+)[_\p{Alphabetic}\d]+)/gu,
}

// Matches <match> tags that wrap around any part of one or several handles/hashtags
// Note 1: A <match> tag can start and end anywhere within the text of a handle/hashtag, potentially splitting it into two or three
// Note 2: Any part of a handle/hashtag that is left dangling outside the <match> tags is included here as well
const matchMatchTags =
  /([#@][^>\s]*<match[^>]*>[^<]*<\/match>\S*)|(<match[^>]*>[#@][^<]*<\/match>\S*)/gu

// Breaks results from matchMatchTags into 5 capture groups:
// 1) textBefore
// 2) matchStart
// 3) text
// 4) matchEnd
// 5) textAfter
const splitMatch =
  /(?<textBefore>^[^><]*)(?<matchStart><match[^>]*>)(?<text>[^><]*)(?<matchEnd><\/match>)(?<textAfter>[^><]*$)/gu

// Splits a strings on whitespace and special characters
// TODO: Might need improvement since there are a lot more characters that can split a handle/hashtag
const splitWords = /[\s!"$%&'()*+,-./:;<=>?[\]^`{|}~]/gu

const parseTextWithSoMeLinks = (text: string, socialSource: SocialMedia) => {
  const socialUrls = SOCIAL_URLS[socialSource]
  const socialRegex = matchSoMeSource[socialSource]
  const isFacebook = socialSource === SocialMedia.Facebook

  const matchAllSocial = new RegExp(
    matchMatchTags.source + '|' + socialRegex.source + '|' + matchUrls.source,
    'gu',
  )

  const finalWithSoMeLinks = text.replace(matchAllSocial, (match) => {
    if (match?.includes('https://') || match?.includes('http://')) {
      // --- Handle plain links ---

      return `<a href="${match}" class="text-blue-sky" target="_blank">${match}</a>`
    } else {
      // --- Handle SoMe handles and hashtags ---

      if (match?.includes('<match')) {
        // --- Handles and hashtags with <match> tags ---

        // If the handle/hashtag is also a search match in M360, the anchor must be the parent of <match>
        // To ensure that we don't break the HTML, we need to pick each match apart and put it back together in a valid order
        const split = Array.from(match.matchAll(splitMatch))

        if (split[0]) {
          // Now we split up the match so we can work with it
          const matchGroups = split[0].groups || {}

          const joinedText =
            matchGroups.textBefore + matchGroups.text + matchGroups.textAfter
          const textSplit = matchGroups.text.split(splitWords)

          // If no before- or after text was found, we don't want to bother with these
          const foundFirst = !matchGroups.textBefore.length
          const foundLast = !matchGroups.textAfter.length

          const newText = joinedText.replace(socialRegex, (subMatch) => {
            // Now we can inject the <match> tags in a way where they don't conflict with our anchor tags
            // Note that this will sometimes mean turning one <match> tag into several
            let subMatchWithMatchTag

            if (!foundFirst) {
              let textBeforeIndex = subMatch.indexOf(matchGroups.textBefore)

              if (textBeforeIndex >= 0) {
                textBeforeIndex += matchGroups.textBefore.length

                subMatchWithMatchTag = `${subMatch.substring(
                  0,
                  textBeforeIndex,
                )}${matchGroups.matchStart}${subMatch.substring(
                  textBeforeIndex,
                  subMatch.length,
                )}${matchGroups.matchEnd}`
              }
              foundFirst
            } else if (!foundLast) {
              if (subMatch === textSplit[textSplit.length - 1]) {
                let textAfterIndex = subMatch.lastIndexOf(matchGroups.textAfter)

                if (textAfterIndex >= 0) {
                  textAfterIndex += matchGroups.textAfter.length

                  subMatchWithMatchTag = `${
                    matchGroups.matchStart
                  }${subMatch.substring(0, textAfterIndex)}${
                    matchGroups.matchEnd
                  }${subMatch.substring(textAfterIndex, subMatch.length)}`
                }
              }
            }

            if (!subMatchWithMatchTag) {
              // The default fallback for uncomplicated submatches
              subMatchWithMatchTag = `${matchGroups.matchStart}${subMatch}${matchGroups.matchEnd}`
            }

            const root =
              !isFacebook && subMatch?.includes('@')
                ? socialUrls.handle
                : socialUrls.hashtag

            // subMatch is sliced to get rid of @/# in the href
            return `<a href="${
              root + subMatch.slice(1)
            }" target="_blank" class="text-blue-sky">${subMatchWithMatchTag}</a>`
          })

          return newText
        } else {
          return match
        }
      } else {
        // --- Handles and hashtags without <match> tags ---

        const root =
          !isFacebook && match?.includes('@')
            ? socialUrls.handle
            : socialUrls.hashtag

        // match is sliced to get rid of @/# in the href
        return `<a href="${
          root + match.slice(1)
        }" target="_blank" class="text-blue-sky">${match}</a>`
      }
    }
  })

  return finalWithSoMeLinks
}

export default parseTextWithSoMeLinks

import { useSearchParams } from 'react-router-dom'

/**
 * Hook to get the active user id from the URL search params.
 * @returns {[number | null, (nextUserId: number) => void]}
 **/
const useActiveUserIdParam = (): [
  userId: number,
  setUserId: (nextUserId: number | null) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setUserId = (nextUserId: number | null) => {
    setSearchParams(nextUserId ? { user_id: nextUserId.toString() } : {})
  }

  const userIdString = searchParams.get('user_id')
  const userId: number = Number(userIdString)

  return [userId, setUserId]
}

export default useActiveUserIdParam

import {
  ArticleIconType,
  ArticlePreviewRoot,
  ArticlePreviewSourceDate,
  Button,
  Pagination,
  PaginationControl,
  PaginationNext,
  PaginationPrevious,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@opoint/infomedia-storybook'
import { useEffect, useRef, useState } from 'react'
import { Document } from '../../generated-types/types.schemas'
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard'
import { MATCH_CLASSNAME } from '../../utilities/parseTextWithMatches'
import useIdenticalArticles from '../../hooks/useIdenticalArticles'
import RegularPreview from './RegularPreview'
import SocialPreview from './SocialPreview'
import { SocialMedia, getSocialMediaType } from './utils'

const ACTIVE_HIGHLIGHT_CLASSNAME = 'underline'

const ActiveArticle = ({ article }: { article: Document | undefined }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, copy] = useCopyToClipboard('Site ID')
  const wrapperRef = useRef<HTMLDivElement>(null)
  const activeHighlightRef = useRef<HTMLSpanElement | null>(null)

  const [showHighlightButtons, setShowHighlightButtons] = useState(false)

  const {
    hasIdenticalArticles,
    identicalArticles,
    activeArticleIndex,
    activeArticle,
    setNextActiveArticle,
    setPrevActiveArticle,
  } = useIdenticalArticles(article)

  useEffect(() => {
    if (!wrapperRef.current) {
      return
    }

    const highlights =
      wrapperRef.current.getElementsByClassName(MATCH_CLASSNAME)

    setShowHighlightButtons(highlights.length >= 1)
  }, [])

  if (!article) {
    return null
  }

  const { mediatype, first_source, unix_timestamp } = activeArticle

  const isSocial =
    activeArticle.mediatype?.text === 'SOCIAL' &&
    ((first_source?.sitename as SocialMedia) === SocialMedia.Facebook ||
      (first_source?.sitename as SocialMedia) === SocialMedia.Instagram ||
      (first_source?.sitename as SocialMedia) === SocialMedia.Twitter)

  const copySiteIdToClipboard = async () => {
    await copy(`site:${activeArticle.id_site}`)
  }

  const handleScrollToHighlight = (direction: 'next' | 'prev') => {
    if (!wrapperRef.current) {
      return
    }

    const highlights =
      wrapperRef.current.getElementsByClassName(MATCH_CLASSNAME)

    if (!highlights.length) {
      return
    }

    const isNext = direction === 'next'
    const isPrev = direction === 'prev'

    if (!activeHighlightRef.current) {
      const initIndex = isNext ? 0 : highlights.length - 1
      activeHighlightRef.current = highlights[initIndex] as HTMLSpanElement
    } else {
      activeHighlightRef.current.classList.remove(ACTIVE_HIGHLIGHT_CLASSNAME)

      const activeHighlightIndex = Array.from(highlights).findIndex(
        (highlight) => highlight === activeHighlightRef.current,
      )

      // If we are at the end of the list, go to the beginning
      if (isNext && activeHighlightIndex === highlights.length - 1) {
        activeHighlightRef.current = highlights[0] as HTMLSpanElement
      }
      // If we are at the beginning of the list, go to the end
      else if (isPrev && activeHighlightIndex === 0) {
        activeHighlightRef.current = highlights[
          highlights.length - 1
        ] as HTMLSpanElement
      }
      // Otherwise, go to the next or previous highlight
      else if (isNext) {
        activeHighlightRef.current = highlights[
          activeHighlightIndex + 1
        ] as HTMLSpanElement
      } else {
        activeHighlightRef.current = highlights[
          activeHighlightIndex - 1
        ] as HTMLSpanElement
      }
    }

    activeHighlightRef.current.classList.add(ACTIVE_HIGHLIGHT_CLASSNAME)
    activeHighlightRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <ArticlePreviewRoot className="relative" ref={wrapperRef}>
      <Tooltip>
        <TooltipTrigger asChild>
          <button
            type="button"
            className="m-0 p-0"
            onClick={() => void copySiteIdToClipboard()}
          >
            <ArticlePreviewSourceDate
              mediaType={mediatype?.text as ArticleIconType}
              socialMediaType={getSocialMediaType(first_source.sitename)}
              sourceName={first_source.name}
              unixTimestamp={unix_timestamp}
            />
          </button>
        </TooltipTrigger>
        <TooltipContent side="top">
          <span>Copy Site ID</span>
        </TooltipContent>
      </Tooltip>
      {isSocial ? (
        <SocialPreview article={activeArticle} />
      ) : (
        <RegularPreview article={activeArticle} />
      )}
      {hasIdenticalArticles && (
        <div className="mt-6 flex justify-center">
          <Pagination>
            <PaginationPrevious onClick={setPrevActiveArticle} />
            <PaginationControl>
              Similar {activeArticleIndex + 1} of {identicalArticles.length}
            </PaginationControl>
            <PaginationNext onClick={setNextActiveArticle} />
          </Pagination>
        </div>
      )}
      {showHighlightButtons && (
        <div className="fixed bottom-0 right-4 p-2">
          <Button
            variant="outline"
            type="button"
            onClick={() => {
              handleScrollToHighlight('prev')
            }}
          >
            Previous highlight
          </Button>
          <Button
            variant="outline"
            type="button"
            className="ms-2"
            onClick={() => {
              handleScrollToHighlight('next')
            }}
          >
            Next highlight
          </Button>
        </div>
      )}
    </ArticlePreviewRoot>
  )
}

export default ActiveArticle

import {
  LinemodeEnum,
  ProfileItem,
  SearchRequestBodyExpressionsSearchlineObjectExpressionsItem,
} from '../../../generated-types/types.schemas'
import { Profile } from '../../../types'
import parseFiltersToString from '../../../utilities/parseFiltersToString'

/**
 * Check if a profile (targetId) is a descendant of another profile (baseId).
 *
 * @param parentMap - Mapping of child ID to its parent ID for faster lookups.
 * @param memo - A memoization map to cache results.
 * @param baseId - ID of the profile we are comparing against.
 * @param targetId - ID of the profile we want to check.
 * @returns true if `targetId` profile is a descendant of `baseId`, false otherwise.
 */
const isDescendant = (
  parentMap: Map<number, number>,
  memo: Map<number, boolean>,
  baseId: number,
  targetId: number,
): boolean => {
  if (memo.has(targetId)) {
    return memo.get(targetId) ?? false
  }

  const parent = parentMap.get(targetId)

  if (parent === 0 || parent === undefined) {
    memo.set(targetId, false)

    return false
  }

  if (parent === baseId) {
    memo.set(targetId, true)

    return true
  }

  const result = isDescendant(parentMap, memo, baseId, parent)
  memo.set(targetId, result)

  return result
}

/**
 * Returns all profiles that are not descendants of the profile with the given id
 * and removes the profile with the given id from the result.
 *
 * @param profiles - List of profiles.
 * @param profileId - ID of the profile to filter out along with its descendants.
 * @returns Filtered list of profiles.
 */
export const filterProfileDescendants = (
  profiles: Profile[],
  profileId: number,
): Profile[] => {
  // Preprocessed profiles in a child-to-parent map
  const parentMap: Map<number, number> = new Map(
    profiles.map((p) => [p.id, p.parent]),
  )
  // Memoization map to store results of descendant checks
  const memo: Map<number, boolean> = new Map()

  return profiles.filter(
    (profile) =>
      profile.id !== profileId &&
      !isDescendant(parentMap, memo, profileId, profile.id),
  )
}

/**
 * Returns a search expression for a given topic builder list ID.
 *
 * @param listId - topic builder list ID.
 * @returns List of expressions.
 */
export const getListIdExpressions = (
  listId: number,
): SearchRequestBodyExpressionsSearchlineObjectExpressionsItem[] => [
  {
    linemode: LinemodeEnum.R,
    searchline: {
      filters: [
        {
          id: `${listId}`,
          name: `list:${listId}`,
          type: 'list',
        },
      ],
    },
  },
]

/**
 * Transforms searchline object to have grouped filters in filters array and
 * the inline filters together with searchterm items converted to one searchterm.
 * @param searchline - The searchline object.
 * @returns The transformed searchline object.
 */
export const transformSearchlineForCodeEditor = ({
  filters,
  searchterm = '',
}: ProfileItem['searchline']): ProfileItem['searchline'] => {
  const firstSearchtermIndex = filters.findIndex(
    (item) => item.type === 'searchterm',
  )

  // If there is no searchterm, return the filters as-is.
  if (firstSearchtermIndex === -1) {
    return {
      filters,
      searchterm: searchterm || '',
    }
  }

  // Filters before first searchterm are grouped filters.
  const groupedFilters = filters.slice(0, firstSearchtermIndex)

  // Filters after first searchterm are merged with searchterms and made into string.
  const filterSearchterm = parseFiltersToString(
    filters.slice(firstSearchtermIndex),
  )

  // If search term exists in filters prefer that instead
  return {
    filters: groupedFilters,
    searchterm: filterSearchterm,
  }
}

/**
 * For each profile item, take filters array, generate string from it and save into searchterm.
 * @param items - The profile items.
 * @returns The profile items with searchterm generated from filters.
 */
export const parseFiltersIntoSearchtermInProfileItems = (
  items: ProfileItem[],
) =>
  items.map((item) => ({
    ...item,
    searchline: transformSearchlineForCodeEditor(item.searchline),
  }))

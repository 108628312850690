import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'

import {
  Toaster,
  ToastProvider,
  TooltipProvider,
} from '@opoint/infomedia-storybook'
import PagesWrapper from './components/PagesWrapper'
import { AuthWrapper } from './components/Auth/AuthWrapper'
import { SearchTermProvider } from './context/searchTermContext'
import { EditorContextProvider } from './context/editorContext'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  return (
    <BrowserRouter>
      <AuthWrapper>
        <QueryClientProvider client={queryClient}>
          <TooltipProvider>
            <ToastProvider>
              <Toaster />
              <SearchTermProvider>
                <EditorContextProvider>
                  <PagesWrapper />
                </EditorContextProvider>
              </SearchTermProvider>
            </ToastProvider>
          </TooltipProvider>
        </QueryClientProvider>
      </AuthWrapper>
    </BrowserRouter>
  )
}

export default App

import { capitalize } from 'lodash-es'
import { cn } from '@opoint/infomedia-storybook'
import { TabType } from './Tabs'

const CompareStateBadge = ({ type }: { type: TabType }) => {
  if (type === 'results') {
    return null
  }

  return (
    <>
      <div
        className={cn('absolute left-0 top-0 h-full w-1', {
          'bg-success': type === 'added',
          'bg-danger': type === 'removed',
        })}
      />
      <div
        className={cn('mb-4 p-1 rounded inline-block text-white text-label-m', {
          'bg-success': type === 'added',
          'bg-danger': type === 'removed',
        })}
      >
        {capitalize(type)}
      </div>
    </>
  )
}

export default CompareStateBadge

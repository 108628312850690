import { Report } from '../../../types/report'

type Props = {
  reports: Report[]
}

const Reports = ({ reports }: Props) => {
  if (!reports.length) {
    return <div className="flex w-full justify-center">No reports found</div>
  }

  return (
    <div className="m-6 rounded-md border border-b-0 border-grey.6">
      {reports.map(({ id, name }) => (
        <div
          key={id}
          className="block cursor-pointer border-b border-grey.6 p-4 hover:bg-sky.8"
        >
          {name}
        </div>
      ))}
    </div>
  )
}

export default Reports

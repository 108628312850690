import {
  ArticlePreviewBody,
  ArticlePreviewExcerpt,
  ArticlePreviewImage,
} from '@opoint/infomedia-storybook'
import { Link20Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { Document } from '../../generated-types/types.schemas'
import parseTextWithMatches from '../../utilities/parseTextWithMatches'
import parseTextWithSoMeLinks from '../../utilities/parseTextWithSoMeLinks'
import getArticleId from '../../utilities/getArticleId'
import { SocialMedia, getSocialMediaIcon } from './utils'

const SocialPreview = ({ article }: { article: Document }) => {
  const {
    summary,
    body,
    id_article,
    id_site,
    first_source,
    header,
    orig_url,
    social_meta,
    articleimages,
  } = article

  const authorImage = social_meta?.author?.profile_image
  const [authorImageLoaded, setAuthorImageLoaded] = useState(true)

  const onAuthorImageFailedToLoad = () => {
    setAuthorImageLoaded(false)
  }

  const isFacebook = first_source.sitename === SocialMedia.Facebook
  const isInstagram = first_source.sitename === SocialMedia.Instagram

  const articleImage = articleimages?.articleimage?.[0]?.url
  const [articleImageLoaded, setArticleImageLoaded] = useState(true)

  const onArticleImageFailedToLoad = () => {
    setArticleImageLoaded(false)
  }

  const hasBody = body?.text && body.text !== '<p></p>'
  const articleId = `id:${getArticleId(article)}`

  const source = first_source.sitename as SocialMedia

  return (
    <div className="mt-4 rounded-md border border-sky.7">
      <div className="p-4 pb-0">
        <a href={orig_url} target="_blank" rel="noreferrer">
          <div className="mb-2 flex flex-row items-center">
            {authorImage && authorImageLoaded ? (
              <img
                className="flex size-12 items-center justify-center rounded-full"
                src={authorImage}
                crossOrigin="anonymous"
                onError={onAuthorImageFailedToLoad}
              />
            ) : (
              <div className="flex size-12 items-center justify-center rounded-full bg-grey.7">
                {getSocialMediaIcon(source)}
              </div>
            )}
            <span className="ms-3">{parseTextWithMatches(header?.text)}</span>
          </div>
        </a>
        <ArticlePreviewExcerpt>
          {parseTextWithMatches(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            parseTextWithSoMeLinks(summary?.text || '', source),
          )}
        </ArticlePreviewExcerpt>
      </div>
      {isInstagram && articleImageLoaded && (
        <img
          src={articleImage}
          crossOrigin="anonymous"
          onError={onArticleImageFailedToLoad}
        />
      )}
      <div className="p-4 pt-0">
        {hasBody ? (
          <ArticlePreviewBody
            articleId={id_article}
            siteId={id_site}
            textParser={parseTextWithMatches}
            keepOpen
            bodyText={parseTextWithSoMeLinks(body?.text || '', source)}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
          </ArticlePreviewBody>
        ) : (
          <div className="text-xs text-grey.4">{articleId}</div>
        )}
      </div>
      {isFacebook &&
        social_meta?.attachments?.links?.map((link) => (
          <a href={link.url} target="_blank" rel="noreferrer" key={link.title}>
            {link.image && <ArticlePreviewImage imageUrl={link.image} />}
            <div className="bg-grey.7 p-4 text-sm">
              {link.title && (
                <div className="mb-3">
                  {parseTextWithMatches(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    parseTextWithSoMeLinks(link?.title, source),
                  )}
                </div>
              )}
              {link.description && (
                <div className="my-3">
                  {parseTextWithMatches(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    parseTextWithSoMeLinks(link?.description, source),
                  )}
                </div>
              )}
              <p className="mt-3">
                <Link20Regular className="mr-2" />
                {link?.url?.replace('https://', '').split('/')[0]}
              </p>
            </div>
          </a>
        ))}
    </div>
  )
}

export default SocialPreview

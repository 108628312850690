/**
 * Formats a string of comma-separated key-value pairs into an object.
 * @param value The string to format.
 * @returns The formatted object.
 */
const formatStringToObject = (value: string): Record<string, boolean> => {
  if (!value) {
    return {}
  }

  const pairs = value.split(',')

  const result: Record<string, boolean> = {}

  pairs.forEach((pair) => {
    const [key, value] = pair.split('=')
    result[key] = value === 'true'
  })

  return result
}

export default formatStringToObject

import {
  ChevronLeft16Regular,
  ChevronRight16Regular,
} from '@fluentui/react-icons'
import * as React from 'react'
import { DayPicker } from 'react-day-picker'

import { cn } from '@opoint/infomedia-storybook'
import styles from './index.module.scss'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

const Calendar = (props: CalendarProps) => (
  <DayPicker
    classNames={{
      root: cn(styles.root, 'bg-white p-5 rounded'),
      months: 'flex',
      month: styles.month,
      caption: 'pb-2',
      caption_start: styles.caption_start,
      caption_end: styles.caption_end,
      caption_label: 'mx-4 font-bold',
      nav_button: cn(styles.nav_button, 'bg-white border-grey'),
      head_cell: 'text-sky.cloudy text-center pb-1',
      day: cn(styles.day),
      day_selected: 'text-white bg-dark-sky',
      day_range_start: cn(
        styles.day_range_start,
        'border-r-dark-sky !bg-dark-sky',
      ),
      day_range_middle: 'border-l-dark-sky border-r-dark-sky !bg-dark-sky',
      day_range_end: cn(styles.day_range_end, 'border-l-dark-sky !bg-dark-sky'),
    }}
    components={{
      IconLeft: () => <ChevronLeft16Regular />,
      IconRight: () => <ChevronRight16Regular />,
    }}
    numberOfMonths={2}
    {...props}
  />
)

export default Calendar

import { useOpointAuthState } from '@opoint/authjs-react'
import { useEffect } from 'react'

import AuthPages from '../../pages/AuthPages'
import LoadingPage from '../../pages/LoadingPage'

const PagesWrapper = () => {
  const authState = useOpointAuthState()

  useEffect(() => {
    if (!authState?.user) {
      return
    }
  }, [authState?.user])

  if (!authState) {
    return <LoadingPage />
  }

  return <AuthPages />
}

export default PagesWrapper

import { useOpointAuthState } from '@opoint/authjs-react'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

export type Setting = {
  name: string
  value: string | number | boolean | Record<string, boolean | string | number>
  choices?: {
    name: string
    value: string | number | boolean
  }[]
}

import { useCustomAxiosInstance } from '../axios/instance'

export const getGetSettingsQueryKey = (userId: number) => [`${userId}-settings`]

const useGetSettings = (options?: UseQueryOptions<Setting[]>) => {
  const apiClient = useCustomAxiosInstance<Setting[]>()
  const authState = useOpointAuthState()

  const userId =
    authState?.user.impersonator?.user_id ?? authState?.user?.user_id

  if (!userId) {
    throw new Error('User ID is required')
  }

  const queryKey = getGetSettingsQueryKey(userId)

  return useQuery<Setting[]>({
    queryKey,
    queryFn: () => apiClient({ url: '/settings/', method: 'get' }),
    refetchOnWindowFocus: false,
    ...options,
  })
}

export default useGetSettings

/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * Opoint API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
} from '@tanstack/react-query'
import type {
  SearchBody,
  SearchGlueRequestBody,
  SearchResult,
} from '../types.schemas'
import { useCustomAxiosInstance } from '../../axios/instance'

/**
 * Perform a search in the archive. Make sure to add the trailing slash to the URL, it is required.
 * @summary Search
 */
export const useSearchHook = () => {
  const search = useCustomAxiosInstance<SearchResult>()

  return (searchBody: SearchBody) => {
    return search({
      url: `/search/?mask=true`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchBody,
    })
  }
}

export const useSearchMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchHook>>>,
    TError,
    { data: SearchBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchHook>>>,
  TError,
  { data: SearchBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const search = useSearchHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchHook>>>,
    { data: SearchBody }
  > = (props) => {
    const { data } = props ?? {}

    return search(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchHook>>>
>
export type SearchMutationBody = SearchBody
export type SearchMutationError = unknown

/**
 * @summary Search
 */
export const useSearch = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchHook>>>,
    TError,
    { data: SearchBody },
    TContext
  >
}) => {
  const mutationOptions = useSearchMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Perform a search in the archive. Result is concatenated as glue_start + list_one + glue_middle + list_two + glue_end.
 * @summary Glue search
 */
export const useGlueSearchHook = () => {
  const glueSearch = useCustomAxiosInstance<SearchResult>()

  return (searchGlueRequestBody: SearchGlueRequestBody) => {
    return glueSearch({
      url: `/search/glue_search/?mask=true`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchGlueRequestBody,
    })
  }
}

export const useGlueSearchMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGlueSearchHook>>>,
    TError,
    { data: SearchGlueRequestBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useGlueSearchHook>>>,
  TError,
  { data: SearchGlueRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const glueSearch = useGlueSearchHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useGlueSearchHook>>>,
    { data: SearchGlueRequestBody }
  > = (props) => {
    const { data } = props ?? {}

    return glueSearch(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GlueSearchMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGlueSearchHook>>>
>
export type GlueSearchMutationBody = SearchGlueRequestBody
export type GlueSearchMutationError = unknown

/**
 * @summary Glue search
 */
export const useGlueSearch = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGlueSearchHook>>>,
    TError,
    { data: SearchGlueRequestBody },
    TContext
  >
}) => {
  const mutationOptions = useGlueSearchMutationOptions(options)

  return useMutation(mutationOptions)
}

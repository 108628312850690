import ArticleTimePeriodSelection from '../ArticleTimePeriodSelection'
import { TimePeriodOption } from '../ArticleTimePeriodSelection/types'
import SettingsMenu from './SettingsMenu'

type ActionBarProps = {
  isArticlesLoading: boolean
  //Time period props
  activeTimePeriod: TimePeriodOption
  onActiveTimePeriodChange: (newValue: TimePeriodOption) => void
}

const ActionBar = ({
  isArticlesLoading,
  activeTimePeriod,
  onActiveTimePeriodChange,
}: ActionBarProps) => (
  <div className="flex items-center justify-between border-b border-grey.6 bg-grey.8 p-3">
    <ArticleTimePeriodSelection
      activeTimePeriod={activeTimePeriod}
      isLoading={isArticlesLoading}
      onActiveTimePeriodChange={onActiveTimePeriodChange}
    />
    <SettingsMenu />
  </div>
)

export default ActionBar

import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { Profile } from '../types'

import { useCustomAxiosInstance } from '../axios/instance'

export const getGetProfilesQueryKey = (userId: number) => [
  `users-${userId}-profiles`,
]

const useGetProfiles = ({
  userId,
  options,
}: {
  userId: number | undefined
  options?: UseQueryOptions<Profile[]>
}) => {
  const apiClient = useCustomAxiosInstance<Profile[]>()

  const queryKey = getGetProfilesQueryKey(userId || 0)

  return useQuery<Profile[]>({
    queryKey,
    queryFn: () => apiClient({ url: `/profiles/`, method: 'get' }),
    refetchOnWindowFocus: false,
    ...options,
  })
}

export default useGetProfiles

/**
 * Converts a given date in milliseconds to a Unix timestamp in seconds.
 *
 * @param {number} date - The date to convert, represented in milliseconds.
 * @returns {number} The Unix timestamp equivalent of the input date in seconds.
 *
 * @example
 * // Returns 1626873600
 * const timestamp = getTimestampInSeconds(1626877200000);
 */
export const getTimestampInSeconds = (date: number): number => {
  return Math.floor(date / 1000)
}

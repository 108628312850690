import { QueryType } from '../../types/profile'

/**
 * Gets the query with the IQL prefix if the queryType is IQL.
 * @param {string} query - The query string.
 * @param {QueryType} queryType - The query type.
 * @returns The query with the IQL prefix if the queryType is IQL.
 */
export const getQueryWithPrefix = (query: string, queryType: QueryType) => {
  if (queryType === QueryType.IQL) {
    return `IQL: ${query}`
  }

  return query
}

/**
 * Gets the query without the IQL prefix.
 * @param {string} query - The query string.
 * @returns The query without the IQL prefix.
 */
export const getQueryWithoutPrefix = (query: string) => {
  return query.replace('IQL: ', '')
}

/**
 * Gets the query type based on the query string.
 * @param {string} query - The query string.
 * @returns The query type.
 */
export const getQueryType = (query: string): QueryType => {
  if (query.startsWith('IQL:')) {
    return QueryType.IQL
  }

  return QueryType.OQL
}

import { useOpointAuthState } from '@opoint/authjs-react'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useCustomAxiosInstance } from '../axios/instance'

type Trash = {
  type: number
  name: string
  id: number
  profileId: number
  globalTrash: boolean
}

export const getGetTagsQueryKey = (userId: number) => [`${userId}-trashes`]

const useGetTrashes = (options?: UseQueryOptions<Trash[]>) => {
  const apiClient = useCustomAxiosInstance<Trash[]>()
  const authState = useOpointAuthState()

  const userId =
    authState?.user.impersonator?.user_id ?? authState?.user?.user_id

  if (!userId) {
    throw new Error('User ID is required')
  }

  const queryKey = getGetTagsQueryKey(userId)

  return useQuery<Trash[]>({
    queryKey,
    queryFn: () => apiClient({ url: '/trash/', method: 'get' }),
    refetchOnWindowFocus: false,
    ...options,
  })
}

export default useGetTrashes

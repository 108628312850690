import {
  Button,
  SingleFilterDropdown,
  SingleFilterDropdownContent,
  SingleFilterDropdownItem,
  SingleFilterDropdownTrigger,
  SingleFilterDropdownOption,
} from '@opoint/infomedia-storybook'
import { format } from 'date-fns'
import { useState } from 'react'
import { DateRange } from 'react-day-picker'

import { Calendar20Regular } from '@fluentui/react-icons'
import Calendar from '../Calendar'

import colors from '../../constants/colors'
import { OptionLabel, optionLabels, optionValues } from './constants'
import styles from './index.module.scss'
import { TimePeriodOption } from './types'

const getDefaultRange = (timePeriod: TimePeriodOption): DateRange => {
  if (timePeriod.label !== 'Custom') {
    return { from: undefined }
  }

  const from = timePeriod.startDate ? new Date(timePeriod.startDate) : undefined
  const to = timePeriod.endDate ? new Date(timePeriod.endDate) : undefined

  return { from, to }
}

type Props = {
  isLoading?: boolean
  activeTimePeriod: TimePeriodOption
  onActiveTimePeriodChange: (newValue: TimePeriodOption) => void
}

const ArticleTimePeriodSelection = ({
  activeTimePeriod,
  onActiveTimePeriodChange,
  isLoading,
}: Props): JSX.Element => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [range, setRange] = useState<DateRange | undefined>(
    getDefaultRange(activeTimePeriod),
  )

  const handleOnChange = (value: SingleFilterDropdownOption): void => {
    if (value.label === 'Custom') {
      setIsDatePickerOpen(true)
    } else {
      onActiveTimePeriodChange({
        label: value.label as OptionLabel,
        ...optionValues[value.label as OptionLabel],
      })
    }
  }

  const handleConfirm = () => {
    if (range?.from && range.to) {
      const endDate = new Date(range.to)
      endDate.setHours(23, 59, 59)
      onActiveTimePeriodChange({
        label: 'Custom',
        startDate: range.from.getTime(),
        endDate: endDate.getTime(),
      })
    }

    setIsDatePickerOpen(false)
  }

  const formatOptionLabel = (option: OptionLabel) => {
    if (option !== 'Custom') {
      return option
    }

    if (!range?.from || !range.to) {
      return option
    }

    return `${format(range.from, 'dd.MM.yy')} - ${format(range.to, 'dd.MM.yy')}`
  }

  return (
    <div className="relative z-[1]">
      <SingleFilterDropdown
        onChange={handleOnChange}
        value={{
          label: formatOptionLabel(activeTimePeriod.label),
          value: activeTimePeriod.label,
        }}
        disabled={isLoading}
      >
        <SingleFilterDropdownTrigger
          title="Period"
          label={formatOptionLabel(activeTimePeriod.label)}
          icon={<Calendar20Regular />}
        />
        <SingleFilterDropdownContent>
          {[...optionLabels].map((label, index) => (
            <SingleFilterDropdownItem
              value={{ label, value: label }}
              key={index}
            />
          ))}
        </SingleFilterDropdownContent>
      </SingleFilterDropdown>
      {isDatePickerOpen && (
        <div
          className={styles.calendarWrapper}
          style={{ backgroundColor: colors.snow }}
        >
          <Calendar
            mode="range"
            numberOfMonths={2}
            onSelect={setRange}
            selected={range}
            toDate={new Date()}
          />
          <div className="mb-3 flex gap-2">
            <Button
              onClick={() => {
                setIsDatePickerOpen(false)
              }}
              variant="outline"
            >
              Close
            </Button>
            <Button
              disabled={!range?.from || !range.to}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ArticleTimePeriodSelection

import { Auth } from '@opoint/authjs-react'
import { isVercel } from './constants'

type Params = {
  headers: {
    accept: string
    'accept-language': string
    'content-type': string
    authorization?: string
  }
  responseType: 'json'
}

export const config: {
  auth?: Auth
} = {
  auth: undefined,
}

export const getRequestHeaders = async () => {
  const params: Params = {
    headers: {
      accept: 'application/json',
      'accept-language': 'en-GB',
      'content-type': 'application/json',
    },
    responseType: 'json',
  }

  if (config?.auth) {
    params.headers.authorization = `JWT ${await config.auth.getTokenString()}`
  }

  return params
}

const getCurrentDomain = () => {
  const [tld] = window.location.hostname.split('.').splice(-1)
  return tld
}

export const getAuthUrl = () => {
  if (window.location.hostname.includes('infomedia')) {
    const tld = getCurrentDomain()
    let authUrl = import.meta.env.VITE_AUTH_URL as string
    // replace default domain to match with current deployment domain
    authUrl = authUrl?.replace('dk', tld)
    return isVercel ? (import.meta.env.VITE_VERCEL_AUTH_URL as string) : authUrl
  }

  return import.meta.env.VITE_AUTH_OPOINT_URL as string
}

export const getAppUrl = () => {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ':' + window.location.port : ''
  }`
}

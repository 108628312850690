import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
  Loader,
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from '@opoint/infomedia-storybook'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'

import { DismissSquare20Regular, Edit20Regular } from '@fluentui/react-icons'
import { lineModeName } from '../../constants'
import { useProfilesHistoryRetrieve } from '../../generated-types/profiles/profiles'
import { ProfileHistoryItemsResponse } from '../../generated-types/types.schemas'
import useDeleteProfileHistory from '../../hooks/useDeleteProfileHistory'
import useProfileId from '../../hooks/useProfileId'
import { getQueries } from '../../pages/AuthPages/UpdateProfilePage/utilities'
import CodeEditor from '../CodeEditor'
import { FormState } from '../ProfilePage/types'

type RevisionsModalProps = {
  onResetFormValue: (newValue: FormState['queries']) => void
  revisions?: number
}

const formateTimestemp = (historyTimestamp: number) =>
  format(historyTimestamp * 1000, 'd.MM.yy HH:mm')

const RevisionsModal = ({
  onResetFormValue,
  revisions,
}: RevisionsModalProps) => {
  const [selectedHistory, setSelectedHistory] =
    useState<ProfileHistoryItemsResponse>()

  const profileId = useProfileId()

  const { data: profileHistoryData } = useProfilesHistoryRetrieve(
    profileId || 0,
  )

  const { mutate: deleteProfileHistory } = useDeleteProfileHistory()

  // Sorted in ascending order
  const sortedHistory = useMemo(() => {
    profileHistoryData?.results
    if (!profileHistoryData) {
      return []
    }

    return profileHistoryData.results.sort((a, b) => a.timestamp - b.timestamp)
  }, [profileHistoryData])

  const lastSortedHistoryItemIndex = sortedHistory.length - 1

  useEffect(() => {
    if (!sortedHistory) {
      return
    }

    setSelectedHistory(sortedHistory[lastSortedHistoryItemIndex] ?? null)
  }, [sortedHistory, lastSortedHistoryItemIndex])

  const handleSelectHistory = (value: string) => {
    const selectedHistory = sortedHistory.find(
      (item) => `${item.timestamp}` === value,
    )

    setSelectedHistory(selectedHistory)
  }

  const { id_list, items, timestamp: historyTimestamp } = selectedHistory || {}

  const selectedHistoryDate =
    historyTimestamp && format(historyTimestamp * 1000, 'd.MM.yy HH:mm')

  const { required, optional, exclude } = getQueries({
    items: items || [],
    profileId: id_list || 0,
  })

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="text" type="button" className="p-0">
          {revisions?.toString()}
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Revisions</DialogTitle>
        </DialogHeader>

        <DialogBody className="max-h-[75vh] overflow-y-auto">
          {!profileHistoryData ? (
            <div className="flex h-full items-center justify-center">
              <Loader />
            </div>
          ) : (
            <div>
              <>
                <div className="relative z-10 mb-2 max-w-xs">
                  <Select
                    onChange={handleSelectHistory}
                    value={`${selectedHistory?.timestamp}`}
                  >
                    <SelectTrigger
                      placeholder=""
                      displayValue={() =>
                        formateTimestemp(selectedHistory?.timestamp || 0)
                      }
                    />
                    <SelectContent>
                      {sortedHistory.map((option) => (
                        <SelectItem
                          key={option.timestamp}
                          value={`${option.timestamp}`}
                        >
                          {formateTimestemp(option.timestamp)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex items-center">
                  <div className="me-2">
                    <DialogClose>
                      <Button
                        onClick={() => {
                          // Because we're changing a major part of the form, we use the reset function instead of setValue.
                          // https://github.com/orgs/react-hook-form/discussions/7671
                          onResetFormValue({
                            required,
                            optional,
                            exclude,
                          })
                          // onClose?.()
                        }}
                        type="button"
                        variant="outline"
                      >
                        <Edit20Regular />
                        Edit from this version
                      </Button>
                    </DialogClose>
                  </div>
                  <div className="me-2">
                    <Button
                      onClick={() => {
                        deleteProfileHistory(historyTimestamp, {
                          onSuccess: () => {
                            if (!profileHistoryData) {
                              return
                            }

                            if (profileHistoryData.results.length > 1) {
                              return
                            }
                          },
                        })
                      }}
                      type="button"
                      variant="outline"
                    >
                      <DismissSquare20Regular />
                      Delete
                    </Button>
                  </div>
                  {selectedHistoryDate && <div>{selectedHistoryDate}</div>}
                </div>
                {[...required, ...optional, ...exclude].map(
                  ({ id, searchline, type, linemode }) => (
                    <div className="pb-5" key={id}>
                      <div className="flex justify-between p-2">
                        <span className="font-bold">
                          {lineModeName[linemode]}
                        </span>
                        <span className="font-bold">{type}</span>
                      </div>
                      <CodeEditor
                        queryType={type}
                        value={searchline.searchterm}
                        readOnly
                        filters={searchline.filters}
                      />
                    </div>
                  ),
                )}
              </>
            </div>
          )}
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default RevisionsModal

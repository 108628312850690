import DOMPurify from 'dompurify'
import parse, { DOMNode } from 'html-react-parser'

import { cn } from '@opoint/infomedia-storybook'
import styles from './index.module.scss'

export const MATCH_CLASSNAME = 'content-match'

const parseTextWithMatches = (text?: string, testId?: string) => {
  if (!text) {
    return <></>
  }

  let trimmedText = (text || '').replace(/(&lt;([^&gt;]+)&gt;)/gi, '') //Trim the html entities tags in the text
  trimmedText = (text || '').replace(/(&lt;p)|(&lt;)|(p&gt;)/gi, '') // Trim stray '&lt;' and '&lt;p'

  let sanitizedText = DOMPurify.sanitize(trimmedText, {
    ALLOWED_TAGS: [
      'p',
      'ul',
      'li',
      'strong',
      'match',
      'ol',
      'a',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'em',
    ],
    ADD_TAGS: ['h'],
    ALLOWED_ATTR: ['href', 'target', 'class'],
    ADD_ATTR: ['color', 'ent'],
  })

  // Fix non-breaking spaces
  sanitizedText = sanitizedText.replace(/&amp;nbsp;/g, '&nbsp;')

  return parse(sanitizedText, {
    replace: (domNode: DOMNode) => {
      if (
        'name' in domNode &&
        'type' in domNode &&
        domNode.name === 'match' &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        domNode.type === 'tag'
      ) {
        const [node] = domNode.children // we only support `<match> with one text content element`

        if (!node) {
          return null
        }

        if ('data' in node) {
          // Matches with ent attribute are entities. We don't want to highlight them. but if they have a color attribute, we want to highlight them
          if (
            domNode.attribs.color === '0' ||
            (domNode.attribs.ent &&
              (!domNode.attribs.color || domNode.attribs.color === '0'))
          ) {
            return node.data
          }
          return (
            <span
              className={cn(
                styles.match,
                'text-white bg-blue-sky',
                MATCH_CLASSNAME,
              )}
              data-testid={testId}
            >
              {node.data}
            </span>
          )
        }

        return node
      }

      return domNode
    },
  })
}

export default parseTextWithMatches

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Input,
  RadioGroup,
  RadioInput,
} from '@opoint/infomedia-storybook'
import { useState } from 'react'

import { ProfileHistoryItemsResponse } from '../../../generated-types/types.schemas'
import ProfileHistorySelect from '../../ProfileHistorySelect'

import { PUBLISHED_VALUE } from './CompareQueriesModal'

type CompareModeHistory = 'profileHistory'
type CompareModeTopicBuilder = 'topicBuilder'
type CompareModePersistedProfile = 'persistedProfile'
type CompareModeMirroredList = 'mirroredList'
type CompareMode =
  | CompareModeHistory
  | CompareModeTopicBuilder
  | CompareModePersistedProfile
  | CompareModeMirroredList

type CompareHistory = {
  mode: CompareModeHistory
  history?: ProfileHistoryItemsResponse
  published?: boolean
}

type CompareTopicBuilder = {
  mode: CompareModeTopicBuilder
  topicBuilderListId: number
}

type ComparePersistentProfile = {
  mode: CompareModePersistedProfile
}

type CompareMirroredList = {
  mode: CompareModeMirroredList
}

export type CompareTarget =
  | CompareHistory
  | CompareTopicBuilder
  | ComparePersistentProfile
  | CompareMirroredList

type Props = {
  onChange: (compareType: CompareTarget) => void
  targetLabel: string
  isMirroredListIdAvailable: boolean
}

const CompareOptionsModal = ({
  onChange,
  targetLabel,
  isMirroredListIdAvailable,
}: Props) => {
  const [activeOption, setActiveOption] =
    useState<CompareMode>('profileHistory')
  const [activeProfileHistory, setActiveProfileHistory] = useState<
    number | ProfileHistoryItemsResponse
  >(PUBLISHED_VALUE)
  const [topicBuilderListId, setTopicBuilderListId] = useState<string>('')

  const isUpdateDisabled =
    activeOption === 'topicBuilder' && topicBuilderListId === ''

  const handleUpdate = () => {
    if (activeOption === 'profileHistory') {
      if (activeProfileHistory === PUBLISHED_VALUE) {
        onChange({
          mode: 'profileHistory',
          published: true,
        })
      } else {
        onChange({
          mode: 'profileHistory',
          history: activeProfileHistory as ProfileHistoryItemsResponse,
        })
      }
    } else if (activeOption === 'topicBuilder') {
      onChange({
        mode: 'topicBuilder',
        topicBuilderListId: parseInt(topicBuilderListId),
      })
    } else if (activeOption === 'persistedProfile') {
      onChange({
        mode: 'persistedProfile',
      })
    } else {
      onChange({
        mode: 'mirroredList',
      })
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button className="p-0 text-left" type="button" variant="text">
          {targetLabel}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Show changes compared to</AlertDialogTitle>
        </AlertDialogHeader>

        <div>
          <RadioGroup
            onValueChange={(option: CompareMode) => {
              setActiveOption(option)
            }}
            value={activeOption}
            className="w-full flex-col"
          >
            <RadioInput
              id="1"
              value="profileHistory"
              label="Previous profile versions"
            />
            {activeOption === 'profileHistory' && (
              <ProfileHistorySelect
                extraOptions={[{ label: 'Published', value: PUBLISHED_VALUE }]}
                onChange={(newValue) => {
                  setActiveProfileHistory(newValue)
                }}
                value={activeProfileHistory}
              />
            )}
            <RadioInput id="2" value="topicBuilder" label="Topic builder ID" />
            {activeOption === 'topicBuilder' && (
              <Input
                onChange={(e) => {
                  setTopicBuilderListId(e.currentTarget.value)
                }}
                placeholder="Enter topic builder list id"
                type="number"
                value={topicBuilderListId}
              />
            )}
            {isMirroredListIdAvailable && (
              <>
                <RadioInput
                  id="3"
                  value="mirroredList"
                  label="Search Expression vs. Mirrored TB list"
                />
                <RadioInput
                  id="4"
                  value="persistedProfile"
                  label="Persistent Profile Results vs. Mirrored TB list"
                />
              </>
            )}
          </RadioGroup>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel>Close</AlertDialogCancel>
          <AlertDialogAction disabled={isUpdateDisabled} onClick={handleUpdate}>
            Done
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default CompareOptionsModal

const parseProfileId = (profileIdString: string | undefined) => {
  if (!profileIdString) {
    return null
  }

  const parsedProfileId = parseInt(profileIdString, 10)

  if (isNaN(parsedProfileId)) {
    return null
  }

  return parsedProfileId
}

export default parseProfileId

import { useMemo } from 'react'

import useGetSettings from './useGetSettings'

/**
 * Custom hook to retrieve the value of a specific setting.
 * @param settingName - The name of the setting.
 * @returns An object containing the setting value and loading state.
 *
 * @example
 * // Setting object
 * { name: 'TRANSLATE_AUTO', value: 0 }
 *
 * // Component.tsx
 * const { setting, isLoading } = useSettingValue('TRANSLATE_AUTO') => { name: 'TRANSLATE_AUTO', value: 0 }
 */
const useSettingValue = <T>(
  settingName: string,
  isUISetting = false,
): { value: T | undefined; isLoading: boolean } => {
  const { data: settings, isLoading } = useGetSettings()

  return useMemo(() => {
    if (isUISetting) {
      const uiSetting = settings?.find(
        (setting) => setting.name === `APP_UI_SETTINGS`,
      )

      return {
        value: uiSetting?.value?.[settingName],
        isLoading,
      }
    }

    const setting = settings?.find((setting) => setting.name === settingName)

    return {
      value: setting?.value as T | undefined,
      isLoading,
    }
  }, [settingName, isLoading, settings, isUISetting])
}

export default useSettingValue

import { useOpointAuthState } from '@opoint/authjs-react'
import { useMemo } from 'react'
import { utils } from '@opoint/codemirror-extensions'
import { useProfilesDependenciesRetrieve } from '../generated-types/profiles/profiles'
import useGetTags from './useGetTags'
import useGetProfiles from './useGetProfiles'
import useProfileId from './useProfileId'
import useIsNewProfile from './useIsNewProfile'

const useCustomFilters = () => {
  const authState = useOpointAuthState()

  const isNewProfile = useIsNewProfile()
  const profileId = useProfileId()

  const { filterToString } = utils

  if (!isNewProfile && !profileId) {
    throw new Error('profileId cannot be null')
  }

  const {
    data: profiles,
    isLoading: isLoadingProfiles,
    isError: isErrorProfiles,
  } = useGetProfiles({
    userId: authState?.user.impersonator?.user_id ?? authState?.user.user_id,
  })
  const {
    data: tags,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useGetTags()

  const {
    data: deps,
    isLoading: isLoadingDeps,
    isError: isErrorDeps,
  } = useProfilesDependenciesRetrieve(profileId ?? 0)

  const profile = useMemo(
    () => profiles?.find((p) => p.id === profileId),
    [profiles, profileId],
  )

  const out = useMemo(() => {
    const nonCyclicProfiles = profiles?.filter(
      (p) =>
        profile === undefined ||
        (!deps?.profiles.includes(p.id) &&
          p.id !== profile.parent &&
          p.id !== profile.id),
    )

    return {
      filters: ['profile', 'basket', 'tag', 'list'],
      aliases: {
        basket: 'tag',
      },
      labels: {
        profile: 'Profile',
        basket: 'Basket',
        tag: 'Tag',
        list: 'TBList',
      },
      suggestions(type: string, value: string) {
        switch (type) {
          case 'profile':
            return (
              nonCyclicProfiles?.map((p) => ({
                id: p.id.toString(),
                name: p.name,
                nl: true,
              })) ?? []
            )
          case 'basket':
            return (
              tags?.map((t) => ({
                id: t.id.toString(),
                name: t.name,
                nl: true,
              })) ?? []
            )
          case 'list':
            // Returning id entered, like M360
            if (value === undefined || !value.match(/\d+/)) {
              return []
            } else {
              return [
                {
                  id: value,
                  nl: true,
                },
              ]
            }
          default:
            return []
        }
      },
      mappings(toMap: { type: string; id: string }[]) {
        const mappings = {}
        for (const filter of toMap) {
          const fString = filterToString({
            ...filter,
            nl: true,
            include: true,
          })
          switch (filter.type) {
            case 'profile': {
              const p = nonCyclicProfiles?.find(
                (p) => p.id.toString() === filter.id,
              )
              mappings[fString] =
                p === undefined
                  ? {
                      nl: true,
                    }
                  : {
                      name: p.name,
                      nl: true,
                    }
              break
            }
            case 'basket':
              {
                const t = tags?.find((t) => t.id.toString() === filter.id)
                mappings[fString] =
                  t === undefined
                    ? {
                        nl: true,
                      }
                    : {
                        name: t.name,
                        nl: true,
                      }
              }
              break
          }
        }

        return mappings
      },
    }
  }, [profiles, profile, deps?.profiles, tags, filterToString])

  // Errors are not terminal, so can still return default output afterwards
  if (isErrorProfiles) {
    console.error('Failed to load profiles')
  }
  if (isErrorTags) {
    console.error('Failed to load tags')
  }
  if (isErrorDeps) {
    console.error('Failed to load profile dependencies')
  }

  // Return null to indicate data not finished loading
  if (isLoadingDeps || isLoadingProfiles || isLoadingTags) {
    return null
  }

  return out
}

export default useCustomFilters

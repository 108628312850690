import { cn } from '@opoint/infomedia-storybook'
import image from './search.svg'

type Props = {
  text: string
}

const ArticleListPlaceholder = ({ text }: Props) => (
  <div className="flex flex-col items-center justify-center">
    <img alt="" className="max-w-[212px]" src={image} />
    <p className={cn('mt-2 text-sky.cloudy text-lg')}>{text}</p>
  </div>
)

export default ArticleListPlaceholder

import { QueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { ProfileHistory } from '../types'

import { useCustomAxiosInstance } from '../axios/instance'
import useGetProfile from './useGetProfile'

export const getProfileHistoryQueryKey = (
  profileId: number | null,
  lastSet: string,
) => [`profileHistory-${profileId}-${lastSet}`]

type CustomQueryOptions = QueryOptions<ProfileHistory, AxiosError> & {
  onSuccess?: (data: ProfileHistory) => void
}

const useGetProfileHistory = ({
  profileId,
  options = {},
}: {
  profileId: number | null
  options?: CustomQueryOptions
}) => {
  const profile = useGetProfile({ profileId })
  const apiClient = useCustomAxiosInstance<ProfileHistory>()
  const queryKey = getProfileHistoryQueryKey(
    profileId,
    profile.data?.last_set || '',
  )

  return useQuery<ProfileHistory, AxiosError>({
    queryKey,
    queryFn: () =>
      apiClient({
        url: `/profiles/${profileId}/history/`,
        method: 'get',
        params: {
          with_query: true,
        },
      }).catch((error: AxiosError) => Promise.reject(error.response)),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!profileId,
    ...options,
  })
}

export default useGetProfileHistory

import { Calendar24Regular } from '@fluentui/react-icons'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Loader,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@opoint/infomedia-storybook'
import { useState } from 'react'
import { DateRange } from 'react-day-picker'
import { format, getUnixTime } from 'date-fns'
import Calendar from '../../Calendar'
import useProfileId from '../../../hooks/useProfileId'
import useProfileBackfill from '../../../hooks/useProfileBackfill'

type Props = {
  isShown: boolean
  onHide: () => void
  oldestArticleTimestamp?: number
}

const RerunQueryModal = ({ isShown, onHide }: Props) => {
  const [dateRange, setDateRange] = useState<DateRange | undefined>()

  const profileId = useProfileId()
  const { mutate: handleBackfill, isLoading } = useProfileBackfill(
    profileId,
    onHide,
  )

  const handleRerun = () => {
    const from = getUnixTime(dateRange?.from as Date)
    const to = getUnixTime(dateRange?.to as Date)

    handleBackfill({ startTime: from, endTime: to })
  }

  const isPeriodSelected = dateRange?.from && dateRange?.to
  const periodLabel = isPeriodSelected
    ? format(dateRange?.from as Date, 'MMM d, yyyy') +
      ' - ' +
      format(dateRange?.to as Date, 'MMM d, yyyy')
    : 'Pick a period'

  return (
    <AlertDialog
      open={isShown}
      onOpenChange={() => {
        onHide()
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-center">
            Re-run query
          </AlertDialogTitle>
        </AlertDialogHeader>
        <p className="text-label-l">
          Choose the period that you want the query to re-run
        </p>
        <p className="mb-1 mt-5 text-label-l">Period</p>
        <Popover modal={true}>
          <PopoverTrigger asChild>
            <Button variant="outline" className="justify-start">
              <Calendar24Regular />
              {periodLabel}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="pointer-events-auto max-w-full">
            <Calendar
              mode="range"
              onSelect={setDateRange}
              selected={dateRange}
              toDate={new Date()}
            />
          </PopoverContent>
        </Popover>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button type="button" variant="outline">
              Cancel
            </Button>
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              onClick={handleRerun}
              type="button"
              disabled={!isPeriodSelected}
            >
              {isLoading ? <Loader size="small" /> : 'Re-run'}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default RerunQueryModal

import { DocumentScreenshotsItem } from '../../generated-types/types.schemas'
import { SCREENSHOT_TYPES } from './constants'

export const getScreenshotThumbnail = (
  screenshots: DocumentScreenshotsItem[] | undefined,
) => {
  if (!screenshots) {
    return null
  }
  return screenshots.find(
    ({ id_type: idType }) => idType === SCREENSHOT_TYPES.THUMBNAIL,
  )
}

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Loader,
} from '@opoint/infomedia-storybook'
import { Delete20Regular } from '@fluentui/react-icons'
import { useNavigate } from 'react-router-dom'
import useDeleteProfile from '../../../hooks/useDeleteProfile'
import useProfileId from '../../../hooks/useProfileId'
import useImpersonate from '../../../hooks/useImpersonate'

const DeleteProfileButton = ({
  isProfileParent,
}: {
  isProfileParent: boolean
}) => {
  const profileId = useProfileId()
  const navigate = useNavigate()
  const { handleImpersonate } = useImpersonate()

  const { mutate: deleteProfile, isLoading: isDeleting } = useDeleteProfile(
    profileId,
    () => {
      handleImpersonate({
        userId: undefined,
        onSuccess: () => {
          navigate('/')
        },
      })
    },
  )

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button className="me-2" type="button" variant="outline">
          <Delete20Regular />
          Delete
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you want to delete this profile?
          </AlertDialogTitle>

          {isProfileParent && (
            <div className="mb-4 text-center">
              Profile is a parent. If you delete it, child profiles will be
              moved one level up.
            </div>
          )}
          {isDeleting && (
            <div className="mb-4 flex justify-center">
              <Loader />
            </div>
          )}
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button type="button" variant="outline">
              Cancel
            </Button>
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              onClick={() => {
                deleteProfile()
              }}
              type="button"
            >
              Delete
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default DeleteProfileButton

import useGetProfiles from '../../../hooks/useGetProfiles'
import useFilterByKey from '../../../hooks/useFilterByKey'

export type ProfileCountProps = {
  searchTerm: string
  userId: number
}

export const ProfileCount = ({ searchTerm, userId }: ProfileCountProps) => {
  const { data: profiles = [] } = useGetProfiles({
    userId,
    options: {
      keepPreviousData: true,
    },
  })

  const filteredProfiles = useFilterByKey({
    array: profiles,
    searchTerm,
    key: 'name',
  })

  if (!filteredProfiles.length) {
    return null
  }

  return <>{`(${filteredProfiles.length})`}</>
}

import { Navigate, Route, Routes } from 'react-router-dom'

import Navigation from '../../components/Navigation'
import SideMenu from '../../components/SideMenu'

import CreateProfilePage from './CreateProfilePage'
import MainPage from './MainPage'
import UpdateProfilePage from './UpdateProfilePage'

const AuthPages = () => {
  return (
    <div className="flex h-screen gap-[10px] bg-grey.6">
      <SideMenu />

      <div className="flex grow flex-col">
        <Navigation />
        <div className="h-[calc(100vh-60px)] w-full bg-white md:overflow-hidden">
          <Routes>
            <Route element={<MainPage />} path="/" />

            <Route
              element={<UpdateProfilePage />}
              path="/profiles/:profileId"
            />
            <Route element={<CreateProfilePage />} path="/profiles/new" />

            <Route element={<Navigate to="/" replace />} path="*" />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default AuthPages

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { groupBy } from 'lodash-es'

import { Profile, ProfileWithChildren } from '../../../types'
import { ProfileList, ProfileListWithChildren } from '../../../types/profile'
import removeDuplicateNumbers from '../../../utilities/removeDuplicateNumbers'

/**
 * Assigns children profiles recursively to a profile object.
 * @param profile - The profile object.
 * @param parentDepth - The depth of the parent profile (default: 0).
 * @param groupedProfiles - A record containing profiles grouped by parent ID.
 * @returns The profile object with children profiles and depth assigned.
 */
const assignChildren = (
  profile: Profile | ProfileList,
  parentDepth = 0,
  groupedProfiles: Record<number, Profile[]> | Record<number, ProfileList[]>,
): ProfileWithChildren | ProfileListWithChildren => {
  const children = groupedProfiles[profile.id]

  if (!children) {
    return {
      ...profile,
      depth: parentDepth,
    }
  }

  const childrenProfiles = children.map((child) =>
    assignChildren(child, parentDepth + 1, groupedProfiles),
  )
  return {
    ...profile,
    depth: parentDepth,
    childrenProfiles,
  }
}

/**
 * Retrieves the IDs of parent profiles that are missing from the `profiles` data.
 * @param profiles - An array of profile objects.
 * @returns An array of missing parent profile IDs.
 */
const getMissingParentsIds = (profiles: Profile[] | ProfileList[]) => {
  const profileIds = profiles.map((profile) => profile.id)
  const parentIds = profiles.map((profile) => profile.parent)
  const missingParentsIds = parentIds.filter(
    (parentId) => !profileIds.includes(parentId),
  )

  return removeDuplicateNumbers(missingParentsIds)
}

/**
 * Gets profiles with their nested children profiles.
 * @param profiles - An array of profile objects.
 * @returns An array of profile objects with their nested children profiles and depth of nesting.
 */
export const getProfilesWithChildren = (
  profiles: ProfileList[],
): ProfileListWithChildren[] => {
  const groupedProfiles = groupBy(profiles, 'parent')

  const missingParentsIds = getMissingParentsIds(profiles)
  return profiles
    .filter(
      (profile) =>
        profile.parent === 0 || missingParentsIds.includes(profile.parent),
    )
    .map((profile) => {
      const defaultDepth = profile.isFolder ? 0 : 1
      return assignChildren(profile, defaultDepth, groupedProfiles)
    })
}

export const getTotalProfilesCount = (
  profiles: ProfileListWithChildren[],
): number => {
  let count = profiles.length

  for (const profile of profiles) {
    if (profile.childrenProfiles) {
      count += getTotalProfilesCount(profile.childrenProfiles)
    }
  }

  return count
}

import { useOpointUser } from '@opoint/authjs-react'
import { Button } from '@opoint/infomedia-storybook'
import { useNavigate } from 'react-router-dom'
import { ChevronLeft20Regular } from '@fluentui/react-icons'
import React, { ReactElement } from 'react'

type Props = {
  children: ReactElement
  buttons?: React.ReactNode | React.ReactNode[]
}

const Wrapper = ({ children, buttons }: Props) => {
  const navigate = useNavigate()

  const user = useOpointUser()

  const handleGoBack = () => {
    navigate('/?user_id=' + user?.user_id)
  }

  return (
    <>
      <div className="sticky z-10 flex items-center justify-between border-b border-grey.6 p-2">
        <div className="flex items-center">
          <Button onClick={handleGoBack} type="button" variant="outline">
            <ChevronLeft20Regular />
            Back
          </Button>
          <span className="ms-4 font-bold">{user?.username}</span>
        </div>
        {buttons && <div className="flex">{buttons}</div>}
      </div>
      <div className="h-[calc(100vh-117px)]">{children}</div>
    </>
  )
}

export default Wrapper

import { instance } from '../../axios/oldInstance'
import {
  FilterSuggestion,
  SuggestionResponse,
  SuggestionResponseMultiple,
} from '../../generated-types/types.schemas'

export const getSuggestionIds = (
  locale: string,
  ids: string,
  signal?: AbortSignal,
) => {
  return instance<FilterSuggestion[]>({
    url: `/suggest/${locale}/getids/${ids}`,
    method: 'get',
    signal,
  })
}

/**
 * This mode returns the result as a single list, with the overall top results from the selected filters.
 * @summary Suggestion server single mode
 */
export const getSuggestionsSingleMode = (
  locale: string,
  resultsCount: number,
  offsets: string,
  accessGroup: string,
  selectedFilters: string,
  searchTerm: string,
  signal?: AbortSignal,
) => {
  return instance<SuggestionResponse>({
    url: `/suggest/${locale}/single/${resultsCount}/${offsets}/0/${accessGroup}/${selectedFilters}/${searchTerm}`,
    method: 'get',
    signal,
  })
}

/**
 * This mode returns the result with all the requested filters as separate lists.
 * @summary Suggestion server multiple mode
 */
export const getSuggestionsMultipleMode = (
  locale: string,
  resultsCount: number,
  offsets: string,
  accessGroup: string,
  selectedFilters: string,
  searchTerm: string,
  signal?: AbortSignal,
) => {
  return instance<SuggestionResponseMultiple>({
    url: `/suggest/${locale}/multiple/${resultsCount}/${offsets}/0/${accessGroup}/${selectedFilters}/${searchTerm}`,
    method: 'get',
    signal,
  })
}

import { useQuery } from '@tanstack/react-query'

import { useCustomAxiosInstance } from '../axios/instance'
import { Report } from '../types/report'

export const getGetReportsQueryKey = (userId: number) => [
  `users-${userId}-reports`,
]

const useGetReports = ({ userId }: { userId: number | undefined }) => {
  const apiClient = useCustomAxiosInstance<Report[]>({ isAnalytics: true })

  if (!userId) {
    throw new Error('userId is required')
  }

  const queryKey = getGetReportsQueryKey(userId)

  return useQuery<Report[]>({
    queryKey,
    queryFn: () =>
      apiClient({
        url: `/api/v1/reports`,
        method: 'get',
        params: { active: true },
      }),
    refetchOnWindowFocus: false,
  })
}

export default useGetReports

import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { Folder } from '../types/folder'
import { useCustomAxiosInstance } from '../axios/instance'

export const getGetFoldersQueryKey = (userId: number) => [
  `users-${userId}-folders`,
]

const useGetFolders = ({
  userId,
  options,
}: {
  userId: number | undefined
  options?: UseQueryOptions<Folder[]>
}) => {
  const apiClient = useCustomAxiosInstance<Folder[]>()

  if (!userId) {
    throw new Error('userId is required')
  }

  const queryKey = getGetFoldersQueryKey(userId)

  return useQuery<Folder[]>({
    queryKey,
    queryFn: () => apiClient({ url: `/folders/`, method: 'get' }),
    refetchOnWindowFocus: false,
    ...options,
  })
}

export default useGetFolders

import { startOfDay, subDays, subMonths, subYears } from 'date-fns'

import { DateRange } from './types'

export const optionLabels = [
  'Today',
  '7 days',
  '30 days',
  '3 months',
  '6 months',
  '1 year',
  'Custom',
] as const

export type OptionLabel = (typeof optionLabels)[number]

const now = new Date()

export const optionValues: Record<OptionLabel, DateRange> = {
  Today: {
    startDate: startOfDay(now).getTime(),
    endDate: now.getTime(),
  },
  '7 days': {
    startDate: startOfDay(subDays(now, 7)).getTime(),
    endDate: now.getTime(),
  },
  '30 days': {
    startDate: startOfDay(subDays(now, 30)).getTime(),
    endDate: now.getTime(),
  },
  '3 months': {
    startDate: startOfDay(subMonths(now, 3)).getTime(),
    endDate: now.getTime(),
  },
  '6 months': {
    startDate: startOfDay(subMonths(now, 6)).getTime(),
    endDate: now.getTime(),
  },
  '1 year': {
    startDate: startOfDay(subYears(now, 1)).getTime(),
    endDate: now.getTime(),
  },
  Custom: {
    startDate: null,
    endDate: null,
  },
}

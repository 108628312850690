import { ChevronRight20Regular, Settings20Regular } from '@fluentui/react-icons'
import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  cn,
} from '@opoint/infomedia-storybook'
import {
  Tree,
  getBackendOptions,
  MultiBackend,
  NodeModel,
  DropOptions,
} from '@minoru/react-dnd-treeview'
import { DndProvider } from 'react-dnd'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { ProfileListWithChildren } from '../../types/profile'
import { useProfilesSortCreate } from '../../generated-types/profiles/profiles'
import { getGetProfilesQueryKey } from '../../hooks/useGetProfiles'
import ConfirmProfileDeleteModal from './ConfirmProfileDeleteModal'

type TreeNode = NodeModel<ProfileListWithChildren>

const makeTreeData = (profiles: ProfileListWithChildren[]): TreeNode[] => {
  const treeData = profiles.reduce((acc, profile) => {
    const hasChildren =
      profile.childrenProfiles && profile.childrenProfiles.length > 0

    if (hasChildren) {
      return [
        ...acc,
        {
          id: profile.id,
          parent: profile.parent,
          droppable: true,
          text: profile.name,
          data: profile,
        },
        ...makeTreeData(profile.childrenProfiles as ProfileListWithChildren[]),
      ]
    }

    return [
      ...acc,
      {
        id: profile.id,
        parent: profile.parent,
        droppable: true,
        text: profile.name,
        data: profile,
      },
    ]
  }, [])

  return treeData
}

type Props = {
  profiles: ProfileListWithChildren[]
  userId: number
  title: string
}

const ManageProfilesModal = ({ profiles, userId, title }: Props) => {
  const queryClient = useQueryClient()
  const [treeData, setTreeData] = useState<TreeNode[]>(makeTreeData(profiles))
  const { mutate: sortProfiles } = useProfilesSortCreate({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries(getGetProfilesQueryKey(userId))
      },
    },
  })

  const [deleteModeEnabled, setDeleteModeEnabled] = useState(false)
  const [profilesToDelete, setProfilesToDelete] = useState<
    ProfileListWithChildren[]
  >([])

  const toggleProfileToDelete = (profile: ProfileListWithChildren) => {
    if (profilesToDelete.some(({ id }) => id === profile.id)) {
      setProfilesToDelete((prev) => prev.filter(({ id }) => id !== profile.id))
    } else {
      setProfilesToDelete((prev) => [...prev, profile])
    }
  }

  const handleProfileDeleted = (profileId: number) => {
    setTreeData((prev) => prev.filter((node) => node.id !== profileId))
    setProfilesToDelete((prev) => prev.filter(({ id }) => id !== profileId))
  }

  const handleDrop = (
    newTreeData: TreeNode[],
    { dragSource, dropTarget, relativeIndex }: DropOptions,
  ) => {
    const oldIndex = treeData.findIndex((node) => node.id === dragSource?.id)
    const newIndex = newTreeData.findIndex((node) => node.id === dragSource?.id)

    const shouldDecrementRelativeIndex =
      oldIndex < newIndex &&
      treeData[oldIndex].parent === newTreeData[newIndex].parent

    const position =
      relativeIndex && shouldDecrementRelativeIndex
        ? relativeIndex - 1
        : relativeIndex

    const profileId = dragSource?.id && (dragSource?.id as number)

    if (profileId) {
      sortProfiles({
        data: {
          parent: dropTarget?.id ? (dropTarget?.id as number) : 0,
          position,
          profileId,
        },
      })
    }

    setTreeData(newTreeData)
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          className="me-2 h-8 w-7 p-0"
          size="icon"
          variant="outline"
          title="Manage"
        >
          <Settings20Regular />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Manage {title}</DialogTitle>
        </DialogHeader>
        <DialogBody className="max-h-[60vh] overflow-auto">
          <div className="rounded border-x border-t border-grey.6">
            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
              <Tree
                tree={treeData}
                rootId={0}
                onDrop={handleDrop}
                sort={false}
                insertDroppableFirst={false}
                classes={{
                  draggingSource: 'bg-grey.8',
                  dropTarget: 'bg-sky.8',
                }}
                canDrop={(_, { dragSource, dropTargetId }) => {
                  if (dragSource?.parent === dropTargetId) {
                    return true
                  }
                }}
                dropTargetOffset={5}
                placeholderRender={() => <div className="h-1 bg-sky.1" />}
                render={(
                  node: TreeNode,
                  { depth, isOpen, onToggle, hasChild },
                ) => (
                  <div
                    className="flex cursor-grab items-center border-b border-grey.6 py-2"
                    style={{ paddingLeft: depth * 20 + (hasChild ? 10 : 18) }}
                  >
                    {hasChild && (
                      <span onClick={onToggle} className="cursor-pointer">
                        <ChevronRight20Regular
                          className={cn('mr-2 transition-transform', {
                            'transform rotate-90': isOpen,
                          })}
                        />
                      </span>
                    )}
                    {deleteModeEnabled && (
                      <Checkbox
                        className="me-2"
                        onCheckedChange={() => {
                          toggleProfileToDelete(
                            node.data as ProfileListWithChildren,
                          )
                        }}
                        checked={profilesToDelete.some(
                          (profile) => profile.id === node.data?.id,
                        )}
                      />
                    )}
                    <span className="text-body">{node.text}</span>
                  </div>
                )}
              />
            </DndProvider>
          </div>
        </DialogBody>
        <DialogFooter>
          {deleteModeEnabled ? (
            <div>
              <Button
                variant="outline"
                className="me-2"
                onClick={() => {
                  setDeleteModeEnabled(false)
                }}
              >
                Cancel
              </Button>
              <ConfirmProfileDeleteModal
                onProfileDeleted={handleProfileDeleted}
                profilesToDelete={profilesToDelete}
              />
            </div>
          ) : (
            <Button
              variant="outline"
              onClick={() => {
                setDeleteModeEnabled(true)
              }}
            >
              Enable delete mode
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ManageProfilesModal

/**
 * Find the start and end indexes of all matches in a string. Also check for overlapping matches and merge them.
 * @param text The text to search
 * @returns An array of objects containing the start and end indexes of each match
 */

export const removeMatchTagsWithEntAndColorZeroAttribute = (text: string) => {
  const matchTagWithEntAndColorZeroAttributePattern =
    /<match\b((?=[^>]*\bent='[^']*')(?=[^>]*\bcolor='0')|(?=[^>]*\bcolor='0'))[^>]*>[^<]*<\/match>/g
  return (text = text.replace(matchTagWithEntAndColorZeroAttributePattern, ''))
}

export const findStartEndIndexesOfMatches = (
  text: string,
  numberOfCharsAround = 50,
) => {
  const startTag = '<match' // we leave out the closing >, because there might be attributes
  const endTag = '</match>'
  const endTagLength = endTag.length

  let startIndex = text.indexOf(startTag, 0)
  let endIndex = text.indexOf(endTag, 0)

  const matches: { startIndex: number; endIndex: number }[] = []

  while (startIndex !== -1 && endIndex !== -1) {
    const lastItem = matches[matches.length - 1]

    // If the space between the last match and the current match is smaller than the charsAroundCount, we merge them
    if (lastItem && startIndex - lastItem.endIndex < numberOfCharsAround * 2) {
      lastItem.endIndex = endIndex + endTagLength
    } else {
      matches.push({
        startIndex,
        endIndex: endIndex + endTagLength,
      })
    }

    startIndex = text.indexOf(startTag, endIndex)
    endIndex = text.indexOf(endTag, endIndex + 1)
  }

  return matches
}

export const keepTextAroundMatches = ({
  text,
  numberOfCharsAround = 50,
  edge = '...',
}: {
  text: string
  numberOfCharsAround?: number
  edge?: string
}): string => {
  const textWithoutParagraphs = text.replace(/<p>|<\/p>/g, '')
  const matches = findStartEndIndexesOfMatches(
    textWithoutParagraphs,
    numberOfCharsAround,
  )

  if (matches.length === 0) {
    return ''
  }

  const textLength = textWithoutParagraphs.length

  return matches.reduce((result, { startIndex, endIndex }) => {
    const start = Math.max(0, startIndex - numberOfCharsAround)
    const end = Math.min(textLength, endIndex + numberOfCharsAround)

    const matchingText = removeMatchTagsWithEntAndColorZeroAttribute(
      textWithoutParagraphs.substring(start, end),
    )

    // Matches without <match> tags are not shown
    if (!matchingText.includes('<match')) {
      return result
    }

    return (
      result +
      '<p>' +
      (start > 0 ? edge : '') +
      matchingText +
      (end < textLength ? edge : '') +
      '</p>'
    )
  }, '')
}

import { LinemodeEnum } from '../generated-types/types.schemas'
import { QueryType } from '../types/profile'

export const lineModeName: Record<LinemodeEnum, string> = {
  [LinemodeEnum.R]: 'Required',
  [LinemodeEnum.O]: 'Optional',
  [LinemodeEnum.E]: 'Exclude',
}

export const queryLanguageNames: Record<QueryType, string> = {
  [QueryType.IQL]: 'Infomedia',
  [QueryType.OQL]: 'Opoint',
}

import { useMemo } from 'react'
import { ProfileDetail } from '../generated-types/types.schemas'

const MAX_IMPACT_VALUE = 60
const MIN_IMPACT_VALUE = 5

/**
 * Returns the impact of the profile's search on the user's experience.
 * @param profileData The profile data to check.
 * @returns An object with the impact of the profile's search on the user's experience.
 **/
const useProfileSearchImpact = (
  profileData?: ProfileDetail,
): {
  shouldWarnUser: boolean
  shouldBlockSearch: boolean
} => {
  return useMemo(() => {
    const impactNumber = profileData?.est_search_impact || 0

    const shouldBlockSearch = impactNumber > MAX_IMPACT_VALUE
    const shouldWarnUser =
      impactNumber > MIN_IMPACT_VALUE && impactNumber < MAX_IMPACT_VALUE

    return {
      shouldWarnUser,
      shouldBlockSearch,
    }
  }, [profileData?.est_search_impact])
}

export default useProfileSearchImpact
